import Paper from "@mui/material/Paper";
import React from "react";

import { theme } from "../../theme/theme";

type PrototypeProps = {
  backgroundColor: string;
  isSticky?: boolean;
};

export function PrototypeScreen({
  children,
  backgroundColor,
  isSticky = true,
}: React.PropsWithChildren<PrototypeProps>) {
  return (
    <Paper
      elevation={12}
      sx={{
        position: isSticky ? "sticky" : "inherit",
        top: 100,
        bgcolor: "background.paper",
        height: 600,
        width: theme.shape.screenWidth,
        borderRadius: 0.15,
        overflowY: "hidden",
        backgroundColor: backgroundColor,
      }}
    >
      {children}
    </Paper>
  );
}
