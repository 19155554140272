import { Box, Step, StepLabel } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import React from "react";

import { theme } from "../../common/theme/theme";

type HeaderProps = {
  activeStep: number;
};

const ProgramCreationHeader: React.FC<HeaderProps> = ({ activeStep }) => {
  const steps = ["Sponsor", "Program", "Communications"];

  return (
    <Box>
      <Stepper
        sx={{
          marginY: 2,
          marginX: 0,
          "& .MuiStepConnector-line": {
            borderTopWidth: 3,
            borderRadius: 1,
          },
          "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
            borderColor: theme.palette.primary.main,
          },
          "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
            borderColor: theme.palette.primary.main,
          },
        }}
        activeStep={activeStep}
      >
        {steps.map((label) => {
          return (
            <Step
              key={label}
              sx={{
                "& .MuiStepLabel-root .Mui-completed .MuiSvgIcon-root": {
                  color: theme.palette.success.light,
                },
                "& .MuiStepLabel-root .Mui-active .MuiSvgIcon-root": {
                  color: theme.palette.primary.light,
                },
                "& .MuiStepLabel-root .MuiStepIcon-text": {
                  fill: theme.palette.primary.dark,
                },
                "& .MuiStepLabel-label": {
                  fontSize: 20,
                  fontWeight: 600,
                },
              }}
            >
              <StepLabel
                sx={{
                  "& .Mui-completed .MuiSvgIcon-root": {
                    background: theme.palette.success.dark,
                    borderRadius: "100%",
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export { ProgramCreationHeader };
