import {
  Box,
  Button,
  Container,
  Grid,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { object, string } from "yup";

import { useAppSelector } from "../../app/hooks";
import AppSelect from "../../common/components/AppSelect";
import { LoadingIndicator } from "../../common/components/LoadingIndicator/LoadingIndicator";
import {
  ClientTemplate,
  Group,
} from "../../common/resources/clientManagement.types";
import {
  ReportType,
  ReportTypeResponse,
} from "../../common/resources/reporting.types";
import {
  selectClientsList,
  selectGroups,
} from "../../slices/clientManagement/clientManagementSlice";
import {
  ReportTypeStatus,
  selectReportTypes,
  selectReportTypesStatus,
  setReportForm,
} from "../../slices/report/reportSlice";
import useFetchReportTypes from "./hooks/useFetchReportTypes";

const CreateReport: React.FC = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const validationSchema = object().shape({
    reportType: string().required(),
    clientName: string().when("reportType", {
      is: (value: ReportType) =>
        [
          ReportType.Utilization,
          ReportType.Registration,
          ReportType.Client,
        ].includes(value),
      then: () => string().required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    groupName: string().when("reportType", {
      is: (value: ReportType) =>
        [
          ReportType.Utilization,
          ReportType.Registration,
          ReportType.Client,
        ].includes(value),
      then: () => string().required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    month: string().required(),
  });

  const formik = useFormik({
    initialValues: {
      reportType: "",
      clientName: "",
      groupName: "",
      month: "",
    },
    validationSchema,
    onSubmit: (values: any) => {
      // TO-DO: send this data to BE endpoint
      // const selectedType = reportTypes
      //     .find((type: ReportTypeResponse) => type.value === values.reportType)
      // const selectedClient = clientList
      //     .find((client: ClientTemplate) => client.clientName === values.clientName);
      // const selectedGroup = groups
      //     .find((group: GroupTemplate) => group.groupName === values.groupName);

      dispatch(
        setReportForm({
          reportType: values.reportType,
          clientName: values.clientName,
          groupName: values.groupName,
          month: values.month,
        })
      );
    },
  });

  useFetchReportTypes();

  const reportTypesStatus = useAppSelector(selectReportTypesStatus);

  const reportTypes = useAppSelector(selectReportTypes);
  const groups = useAppSelector(selectGroups);
  const clientList = useAppSelector(selectClientsList);

  const handleReportChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    formik.resetForm({
      values: {
        ...formik.values,
        reportType: value,
      },
    });
  };

  const setDisableCondition = (): boolean =>
    [
      ReportType.Invoices,
      ReportType.Utilization_Collection,
      ReportType.State,
      ReportType.Missed_Call,
    ].includes(formik.values.reportType as ReportType);

  return (
    <>
      <LoadingIndicator
        isLoading={
          reportTypesStatus === ReportTypeStatus.loading ||
          reportTypesStatus === ReportTypeStatus.idle
        }
      />

      {reportTypesStatus === ReportTypeStatus.success && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            onSubmit={formik.handleSubmit}
            component="form"
            sx={{ margin: 12, textAlign: "left" }}
          >
            <Typography variant="h5">
              {formatMessage({ id: "reporting.create.title" })}
            </Typography>
            <Box
              width={400}
              minWidth={400}
              marginBottom={2}
              marginTop={2}
            >
              <AppSelect
                fullWidth
                name="reportType"
                onChange={handleReportChange}
                value={formik.values.reportType}
                id="report-type-select"
                displayEmpty
                error={
                  formik.touched.reportType && Boolean(formik.errors.reportType)
                }
                onBlur={formik.handleBlur}
                label={formatMessage({ id: "reporting.create.reportType" })}
                items={reportTypes.map((report: ReportTypeResponse) => ({
                  label: report.value,
                  value: report.value, // TO-DO: Define if we want to send id or name to BE
                }))}
              ></AppSelect>
            </Box>
            <Box
              width={400}
              minWidth={400}
              marginBottom={2}
            >
              <AppSelect
                fullWidth
                name="clientName"
                onChange={formik.handleChange}
                value={formik.values.clientName}
                id="client-select"
                disabled={setDisableCondition()}
                displayEmpty
                error={
                  formik.touched.clientName && Boolean(formik.errors.clientName)
                }
                onBlur={formik.handleBlur}
                label={formatMessage({ id: "reporting.create.client" })}
                items={clientList.map((client: ClientTemplate) => ({
                  label: client.clientName,
                  value: client.clientName, // TO-DO: Define if we want to send id or name to BE
                }))}
              ></AppSelect>
            </Box>
            <Box
              width={400}
              minWidth={400}
              marginBottom={2}
            >
              <AppSelect
                fullWidth
                name="groupName"
                onChange={formik.handleChange}
                value={formik.values.groupName}
                id="group-select"
                disabled={setDisableCondition()}
                displayEmpty
                error={
                  formik.touched.groupName && Boolean(formik.errors.groupName)
                }
                onBlur={formik.handleBlur}
                label={formatMessage({ id: "reporting.create.group" })}
                items={groups.map((group: Group) => ({
                  label: group.groupName ?? "",
                  value: group.groupName ?? "", // TO-DO: Define if we want to send id or name to BE
                }))}
              ></AppSelect>
            </Box>
            <Box
              width={400}
              minWidth={400}
            >
              <DesktopDatePicker
                label={formatMessage({ id: "reporting.create.date" })}
                value={formik.values.month}
                onChange={(value) => formik.setFieldValue("month", value)}
                views={["month", "year"]}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    name: "month",
                    error: formik.touched.month && Boolean(formik.errors.month),
                    onBlur: formik.handleBlur,
                    sx: { marginBottom: 4 },
                  },
                }}
              />
            </Box>
            <Button
              variant="contained"
              type="submit"
            >
              {formatMessage({ id: "reporting.create.submit" })}
            </Button>
          </Box>
        </Grid>
      )}

      {reportTypesStatus === ReportTypeStatus.failed && (
        <Container sx={{ mt: 20, textAlign: "left" }}>
          <Typography
            aria-live="assertive"
            id="loading-title"
          >
            {formatMessage({ id: "reporting.create.errorFetch" })}
          </Typography>
        </Container>
      )}
    </>
  );
};

export default CreateReport;
