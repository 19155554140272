import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Chip,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppDispatch } from "../../app/store";
import CustomSnackbar from "../../common/components/CustomSnackBar/CustomSnackBar";
import { DataGridWrapper } from "../../common/components/DataGrid/DataGrid";
import { SkeletonLoadingOverlay } from "../../common/components/SkeletonDataGrid/SkeletonDataGrid";
import { Group } from "../../common/resources/clientManagement.types";
import useFeatureControl from "../../common/utils/featureControl";
import { useFetchAllGroupsByClientQuery } from "../../slices/clientManagement/clientManagement.service";
import {
  resetGroupState,
  selectClient,
  selectGroupsList,
  selectGroupStatus,
  selectSnackBarOpen,
  setGroup,
  setSnackBarOpen,
} from "../../slices/clientManagement/clientManagementSlice";
import { Login } from "../Login/Login";
import { GroupCreateUpdate } from "./GroupCreateUpdate";

const GroupView: React.FC = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const groupsList = useAppSelector(selectGroupsList);
  const client = useAppSelector(selectClient);
  const status = useAppSelector(selectGroupStatus);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string | undefined>();
  const [filteredRows, setFilteredRows] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { clientID, groupID } = useParams();
  const featureControl = useFeatureControl();

  useFetchAllGroupsByClientQuery({
    clientID: clientID || "",
  });

  useEffect(() => {
    setFilteredRows(groupsList);
    setIsLoading(false);
  }, [groupsList]);

  useEffect(() => {
    if (status.fetch === "loading") {
      setIsLoading(true);
    }
  }, [status]);

  const snackBarOpen = useAppSelector(selectSnackBarOpen);

  const { formatMessage } = useIntl();

  const handleSearchChange = (searchString: string) => {
    if (searchString) {
      setFilteredRows(
        groupsList.filter(
          (group) =>
            group.groupName
              ?.toLowerCase()
              .startsWith(searchString.toLowerCase())
        )
      );
    } else {
      setFilteredRows(groupsList);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "groupID",
      headerName: "GroupID",
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      display: "flex",
      align: "center",
    },
    {
      field: "groupName",
      headerName: "Group Name",
      minWidth: 250,
      flex: 2,
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (rowSelection) => {
        return (
          <Button
            sx={{
              "&.MuiButton-root:hover": {
                bgcolor: "transparent",
              },
            }}
            onClick={() => {
              dispatch(setGroup(rowSelection.row));
              navigate(`/planView/${clientID}/${rowSelection.row.groupID}`);
            }}
          >
            {rowSelection.row.groupName}
          </Button>
        );
      },
    },
    {
      field: "clientGroupID",
      headerName: "Client Group ID",
      minWidth: 200,
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
    },
    {
      field: "startDate",
      headerName: "Start Date",
      minWidth: 150,
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
    },
    {
      field: "active",
      headerName: "Status",
      minWidth: 150,
      sortable: false,
      headerAlign: "center",
      display: "flex",
      align: "center",
      renderCell: (params) => {
        const { row } = params;
        return row.active ? (
          <Chip
            color="success"
            label="Active"
          />
        ) : (
          <Chip
            color="error"
            label="Inactive"
          />
        );
      },
      valueGetter: (value, row) => {
        return row;
      },
    },
    {
      field: "totalPlans",
      headerName: "Total Plans",
      minWidth: 150,
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
    },
    {
      field: "primaryMemberCount",
      headerName: "Active Primary Members",
      minWidth: 200,
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      headerAlign: "center",
      display: "flex",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const { row } = params;
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setSelected(row.groupID);
              setModalOpen(true);
            }}
          />
        );
      },
      valueGetter: (value, row) => {
        return row;
      },
    },
  ];

  return (
    <Box sx={{ textAlign: "left", px: 5 }}>
      <CustomSnackbar
        open={Boolean(snackBarOpen.open)}
        autoHideDuration={5000}
        onClose={() =>
          dispatch(setSnackBarOpen({ ...snackBarOpen, open: false }))
        }
        title={
          snackBarOpen.title ? formatMessage({ id: snackBarOpen.title }) : ""
        }
        subTitle={
          snackBarOpen.subTitle
            ? formatMessage({ id: snackBarOpen.subTitle })
            : ""
        }
        severity={snackBarOpen.severity}
      />
      <Breadcrumbs sx={{ p: 2 }}>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/clientManagement`);
          }}
        >
          {formatMessage({ id: "clientManagement.newGroup.ClientManagement" })}
        </Link>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/groups/${clientID}`);
          }}
        >
          {formatMessage({ id: "clientManagement.newGroup.Client" })}
          {` ${client.clientName}`}
        </Link>
      </Breadcrumbs>
      <Card sx={{ py: 3, px: 2 }}>
        <Grid container>
          <Grid item>
            <Typography
              sx={{ fontWeight: 700 }}
              variant="h5"
            >
              {`${client.clientName} `}
            </Typography>
          </Grid>
          <Grid
            item
            xs
          >
            <Grid
              container
              direction="row-reverse"
            >
              <Grid item>
                {featureControl ? null : (
                  <Button
                    sx={{ width: 150, height: 45 }}
                    variant="contained"
                    onClick={() => setModalOpen(true)}
                  >
                    <AddIcon />
                    {formatMessage({
                      id: "clientManagement.groupView.createGroupButton",
                    })}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <p>{formatMessage({ id: "clientManagement.groupView.groupDesc" })}</p>
        <Grid sx={{ m: 1 }}>
          <Button
            sx={{ textDecoration: "underline" }}
            variant="text"
            onClick={() => {
              navigate(`/groups/${clientID}`);
            }}
          >
            {formatMessage({ id: "clientManagement.groupView.groups" })}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              dispatch(resetGroupState());
              navigate(`/planView/${clientID}/${groupID}`);
            }}
          >
            {formatMessage({ id: "clientManagement.planView.plans" })}
          </Button>
          <TextField
            onChange={(e) => handleSearchChange(e.target.value)}
            sx={{ borderRadius: 1, marginX: 2, width: 490 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            label={formatMessage({ id: "clientManagement.groupView.groups" })}
            placeholder={formatMessage({
              id: "clientManagement.groupView.placeholder",
            })}
          />
        </Grid>
        <DataGridWrapper
          sortFieldName="groupID"
          sortDirection="desc"
          pageSize={10}
          pageNumber={0}
          columns={columns}
          columnVisibilityModel={
            featureControl
              ? {
                  actions: false,
                }
              : {}
          }
          slots={{
            loadingOverlay: SkeletonLoadingOverlay,
            noRowsOverlay: () => (
              <Stack
                sx={{ fontWeight: 700, fontSize: "16px" }}
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                {formatMessage({ id: "clientManagement.groupView.noGroups" })}
              </Stack>
            ),
          }}
          loading={isLoading}
          rows={filteredRows ?? []}
          rowIdField="groupID"
          disableRowSelectionOnClick
          disableColumnMenu
        />
        <GroupCreateUpdate
          groupId={selected}
          isOpen={modalOpen}
          setSelected={setSelected}
          setModalOpen={setModalOpen}
        />
      </Card>
    </Box>
  );
};

export default withAuthenticationRequired(GroupView, {
  onRedirecting: () => <Login />,
});
