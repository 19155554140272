import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Product } from "../../common/resources/benefits.types";
import {
  selectBenefits,
  selectDisplaySelected,
  updateProductBenefit,
} from "../../slices/benefits/benefitSlice";

export const ProductForm: React.FC = () => {
  const selected = useAppSelector(selectDisplaySelected) as Product;
  const benefits = useAppSelector(selectBenefits);
  const dispatch = useAppDispatch();
  const [benefitsSelectorOpen, setBenefitsSelectorOpen] = useState(true);

  return (
    <div>
      <List>
        <ListSubheader
          disableSticky
          key={`list-subheader-benefits`}
          style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "start",
            alignItems: "center",
          }}
          component="div"
          id="nested-list-subheader"
          onClick={() => {
            setBenefitsSelectorOpen(!benefitsSelectorOpen);
          }}
        >
          <ListItemText primary="Benefits" />
          {benefitsSelectorOpen ? <ExpandLess /> : <ExpandMore />}
        </ListSubheader>
        <Collapse
          in={benefitsSelectorOpen}
          unmountOnExit
        >
          <List
            component="div"
            disablePadding
          >
            {benefits.map((elem, index) => {
              return (
                <ListItemButton
                  key={`checklist-item-${index}`}
                  onClick={() => {
                    dispatch(
                      updateProductBenefit({
                        benefitId: elem.id,
                        productId: selected.id,
                      })
                    );
                  }}
                  sx={{ pl: 4 }}
                >
                  <Checkbox
                    checked={selected.benefits.includes(elem.id)}
                    disabled={!elem.active}
                  ></Checkbox>
                  <ListItemText primary={elem.name} />
                </ListItemButton>
              );
            })}
          </List>
        </Collapse>
      </List>
    </div>
  );
};
