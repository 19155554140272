import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import {
  BenefitTemplate,
  CardTemplate,
  Product,
  TemplateStyle,
} from "../../common/resources/benefits.types";
import { benefitsApi } from "./benefits.service";

export type BenefitState = {
  benefits: BenefitTemplate[];
  products: Product[];
  productGroup?: string[];
  selected?: BenefitTemplate | Product;
  status: "idle" | "loading" | "failed";
};

const initialState: BenefitState = {
  benefits: [],
  products: [],
  productGroup: undefined,
  selected: undefined,
  status: "idle",
};

export const castStringToId = (str: string): string => {
  const id = str.toLowerCase().replace(/\W/g, "");
  return id;
};

const alphaByName = (
  a: Product | BenefitTemplate,
  b: Product | BenefitTemplate
) => {
  const aName = a?.name.toLowerCase();
  const bName = b?.name.toLowerCase();
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

const scrollInNewContent = (selected: BenefitTemplate) => {
  if (selected?.customData) {
    const lastContentIndex = selected.customData?.content.length - 1;
    const lastContentElement = document.getElementById(
      `benefit-input-${lastContentIndex}`
    );
    lastContentElement?.scrollIntoView({ behavior: "smooth" });
  }
};

const scrollToNewCard = (selected: BenefitTemplate) => {
  if (selected?.customData) {
    const lastCardIndex = selected.customData?.card.length - 1;
    const lastCardElement = document.getElementById(
      `card-input-${lastCardIndex}`
    );
    lastCardElement?.scrollIntoView({ behavior: "smooth" });
  }
};

export const benefitSlice: Slice<BenefitState> = createSlice({
  name: "benefit",
  initialState,
  reducers: {
    setDisplayElement: (state, action: PayloadAction<BenefitTemplate>) => {
      state.selected = action.payload;
      window.scrollTo(0, 0);
    },
    updateProductBenefit: (
      state,
      action: PayloadAction<{ benefitId: string; productId: string }>
    ) => {
      const product = state.products.find(
        (elem) => elem.id === action.payload.productId
      );
      if (product) {
        product.dirty = true;
        const index = product.benefits.indexOf(action.payload.benefitId);
        if (index >= 0) {
          product.benefits.splice(index, 1);
        } else {
          product.benefits.push(action.payload.benefitId);
        }

        if (state.selected?.id === product.id) {
          state.selected = product;
        }
      }
    },
    updateProductGroup: (state, action: PayloadAction<Product>) => {
      const productGroup = state.productGroup ? state.productGroup : [];
      if (action.payload.eligibilityId) {
        const pId = productGroup.indexOf(action.payload.eligibilityId);
        if (pId >= 0) {
          productGroup.splice(pId, 1);
        } else {
          productGroup.push(action.payload.eligibilityId);
        }
        state.productGroup = productGroup;
      }
    },
    updateBenefit: (state, action: PayloadAction<BenefitTemplate>) => {
      const ind = state.benefits?.findIndex((elem) => {
        if (elem.id) {
          return elem.id === action.payload.id;
        }
        return elem.name === action.payload.name;
      });
      action.payload.dirty = true;
      if (ind < 0) {
        state.benefits.push(action.payload);
      } else {
        state.benefits[ind] = action.payload;
      }

      const oldBenefit = { ...state.selected } as BenefitTemplate;
      const newBenefit = JSON.parse(JSON.stringify(action.payload));
      if (oldBenefit.template !== newBenefit.template) {
        // Update the hero cards to be false on template change if existing
        if (newBenefit.customData.card) {
          for (const card of newBenefit.customData.card) {
            card.hero = false;
          }
        }
      }
      if (state.selected?.id === newBenefit.id) {
        state.selected = newBenefit;
        if (
          JSON.stringify(oldBenefit.customData.content.length) !==
          JSON.stringify(newBenefit.customData.content.length)
        ) {
          setTimeout(() => {
            scrollInNewContent(newBenefit as BenefitTemplate);
          }, 5);
        } else if (
          JSON.stringify(oldBenefit.customData.card.length) !==
          JSON.stringify(newBenefit.customData.card.length)
        ) {
          setTimeout(() => {
            scrollToNewCard(newBenefit as BenefitTemplate);
          }, 5);
        }
      }
    },
    addProduct: (state, action: PayloadAction<string>) => {
      const product: any = {
        name: action.payload,
        eligibilityId: castStringToId(action.payload),
        benefits: [],
      };
      state.products.push(product);
      state.selected = product;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(benefitsApi.endpoints.fetchBenefits.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        benefitsApi.endpoints.fetchBenefits.matchFulfilled,
        (state, action) => {
          state.status = "idle";
          state.benefits = action.payload.data.map((elem: BenefitTemplate) => {
            elem.customData.content = elem.customData.content ?? [];
            elem.customData.card = elem.customData.card ?? [];
            return elem;
          });
        }
      )
      .addMatcher(
        benefitsApi.endpoints.fetchBenefits.matchRejected,
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(benefitsApi.endpoints.updateBenefit.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        benefitsApi.endpoints.updateBenefit.matchFulfilled,
        (state, action) => {
          const benefit = state.benefits?.find(
            (elem) => elem.id === action.payload.data
          );
          if (benefit) {
            benefit.dirty = false;
            if (state.selected && state.selected?.id === action.payload.data) {
              const selected = state.selected as BenefitTemplate;
              selected.dirty = false;
              state.selected = selected;
            }
          }
          state.status = "idle";
        }
      )
      .addMatcher(
        benefitsApi.endpoints.updateBenefit.matchRejected,
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(benefitsApi.endpoints.createBenefit.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        benefitsApi.endpoints.createBenefit.matchFulfilled,
        (state, action) => {
          state.status = "idle";
          const { name, label, active, template, ctaLabel, customData } =
            action.meta.arg.originalArgs.newBenefit;
          const benefit: any = {
            id: action.payload.data,
            name,
            label,
            active,
            template,
            ctaLabel,
            customData: {
              content: customData.content ?? [],
              card: customData.card ?? [],
            },
          };
          state.benefits.push(benefit);
          state.selected = benefit;
        }
      )
      .addMatcher(
        benefitsApi.endpoints.createBenefit.matchRejected,
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(
        benefitsApi.endpoints.uploadBenefitImage.matchPending,
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        benefitsApi.endpoints.uploadBenefitImage.matchFulfilled,
        (state, action) => {
          state.status = "idle";
          const selected: BenefitTemplate = state.selected as BenefitTemplate;

          if (action.meta.arg.originalArgs.field === "summaryImage") {
            selected.summaryImage = action.payload.data;
          } else if (action.meta.arg.originalArgs.field === "logo") {
            selected.customData.logo = action.payload.data;
          } else if (action.meta.arg.originalArgs.field.includes("card")) {
            const index = parseInt(
              action.meta.arg.originalArgs.field.split("-")[1]
            );
            selected.customData.card[index].src = action.payload.data;
          } else if (action.meta.arg.originalArgs.field.includes("button")) {
            const bodyIndex = parseInt(
              action.meta.arg.originalArgs.field.split("-")[1]
            );
            const buttonIndex = parseInt(
              action.meta.arg.originalArgs.field.split("-")[2]
            );
            const buttons = selected.customData.content[bodyIndex].buttons;
            if (buttons) {
              buttons[buttonIndex].value = action.payload.data;
              selected.customData.content[bodyIndex].buttons = buttons;
            }
          }
          selected.dirty = true;
          const index = state.benefits.findIndex(
            (elem) => elem.id === action.meta.arg.originalArgs.benefit.id
          );
          state.selected = selected;
          if (index) {
            state.benefits[index] = selected;
          }
        }
      )
      .addMatcher(
        benefitsApi.endpoints.uploadBenefitImage.matchRejected,
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(benefitsApi.endpoints.updateProduct.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        benefitsApi.endpoints.updateProduct.matchFulfilled,
        (state, action) => {
          const product = state.products?.find(
            (elem) => elem.id === action.meta.arg.originalArgs.id
          );
          if (product) {
            product.dirty = false;
            if (
              state.selected &&
              state.selected?.id === action.meta.arg.originalArgs.id
            ) {
              const selected = state.selected as Product;
              selected.dirty = false;
              state.selected = selected;
            }
          }
          state.status = "idle";
        }
      )
      .addMatcher(
        benefitsApi.endpoints.updateProduct.matchRejected,
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(benefitsApi.endpoints.createProduct.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        benefitsApi.endpoints.createProduct.matchFulfilled,
        (state, action) => {
          state.status = "idle";
          const { name, eligibilityId } = action.meta.arg.originalArgs;
          const product: Product = {
            id: action.payload.data,
            name,
            benefits: [],
            eligibilityId,
          };
          state.products.push(product);
          state.selected = product;
        }
      )
      .addMatcher(
        benefitsApi.endpoints.createProduct.matchRejected,
        (state) => {
          state.status = "failed";
        }
      )
      .addMatcher(benefitsApi.endpoints.fetchProducts.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        benefitsApi.endpoints.fetchProducts.matchFulfilled,
        (state, action) => {
          state.status = "idle";
          state.products = action.payload.data.map((elem: any) => {
            if (!elem.benefits) {
              elem.benefits = [];
            }
            return elem;
          });
        }
      )
      .addMatcher(
        benefitsApi.endpoints.fetchProducts.matchRejected,
        (state) => {
          state.status = "failed";
        }
      )

      .addMatcher(
        benefitsApi.endpoints.fetchBenefitsForProducts.matchPending,
        (state) => {
          state.status = "loading";
        }
      )
      .addMatcher(
        benefitsApi.endpoints.fetchBenefitsForProducts.matchFulfilled,
        (state, action) => {
          state.status = "idle";
          const productIndex = state.products.findIndex(
            (elem) => elem.id === action.meta.arg.originalArgs.product.id
          );
          if (productIndex >= 0) {
            if (!action.payload.data) {
              state.products[productIndex].benefits = [];
            } else {
              state.products[productIndex].benefits = action.payload.data;
            }
            if (action.meta.arg.originalArgs.updateSelected) {
              state.selected = state.products[productIndex];
              state.productGroup = [
                action.meta.arg.originalArgs.product.eligibilityId,
              ];
            }
          }
        }
      )
      .addMatcher(
        benefitsApi.endpoints.fetchBenefitsForProducts.matchRejected,
        (state) => {
          state.status = "failed";
        }
      );
  },
});

export const selectBenefits = (state: RootState) => {
  if (state.benefit.benefits && state.benefit.benefits.length > 0) {
    return [...state.benefit.benefits].sort(alphaByName);
  }
  return [];
};
export const selectProducts = (state: RootState) => {
  if (state.benefit.products && state.benefit.products.length > 0) {
    return [...state.benefit.products].sort(alphaByName);
  }
  return [];
};
export const selectProductGroup = (state: RootState) =>
  state.benefit.productGroup;
export const selectBenefitStatus = (state: RootState) => state.benefit.status;
export const selectDisplaySelected = (state: RootState) =>
  state.benefit.selected;

export const selectBenefitsForProduct = (
  state: RootState
): BenefitTemplate[] => {
  const selected = selectDisplaySelected(state) as any;
  if (selected?.benefits) {
    return state.benefit.benefits.filter((elem: BenefitTemplate) =>
      selected.benefits.includes(elem.id)
    );
  }
  return [];
};
export const selectBenefitsForProducts = (
  state: RootState,
  productGroup: string[]
): BenefitTemplate[] => {
  const benefits: string[] = [];
  const products = state.benefit.products
    .filter((elem: Product) => productGroup.includes(elem.eligibilityId))
    .map((elem: Product) => elem.id);
  for (const productId of products) {
    const product = state.benefit.products.find(
      (elem: Product) => elem.id === productId
    );
    if (product && product.benefits && product.benefits.length > 0) {
      for (const benefit of product.benefits) {
        if (!benefits.includes(benefit)) {
          benefits.push(benefit);
        }
      }
    }
  }
  return state.benefit.benefits.filter(
    (elem: BenefitTemplate) => benefits.includes(elem.id) && elem.active
  );
};
export const selectHeroCardsForProducts = (
  state: RootState,
  products: string[]
): CardTemplate[] => {
  const benefits = selectBenefitsForProducts(state, products);
  const cards: CardTemplate[] = [];
  if (benefits?.length > 0) {
    const heroBenefits = benefits?.filter(
      (elem) => elem.template === TemplateStyle.Hero_Template
    );
    for (const benefit of heroBenefits) {
      const heroCards: CardTemplate[] | undefined =
        benefit.customData.card?.filter((elem) => elem.hero);
      if (heroCards && heroCards?.length > 0) {
        cards.push(...heroCards);
      }
    }
  }
  return cards;
};
export const hasDirtyBenefitsData = (state: RootState): boolean => {
  const productsDirty =
    state.benefit.products.filter((elem: Product) => elem.dirty)?.length > 0;
  const benefitsDirty =
    state.benefit.benefits.filter((elem: BenefitTemplate) => elem.dirty)
      ?.length > 0;
  return productsDirty || benefitsDirty;
};

export const {
  setDisplayElement,
  updateProductBenefit,
  updateProductGroup,
  updateBenefit,
  addProduct,
} = benefitSlice.actions;
export default benefitSlice.reducer;
