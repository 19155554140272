import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button } from "../../common/components/Button/Button";
import CustomSnackbar from "../../common/components/CustomSnackBar/CustomSnackBar";
import { MemberTemplate } from "../../common/resources/memberActions.types";
import {
  useLazyFetchMemberQuery,
  useLazyMergeMemberQuery,
} from "../../slices/memberMerge/memberMerge.service";
import {
  deleteMembers,
  membersList,
  memberToKeep,
  removeFromMembersList,
  resetSnackBarMessage,
  selectSnackBarMessage,
  selectUnmatchedFieldData,
  setSnackBarMessageBlock,
  setSnackBarNoMemberID,
} from "../../slices/memberMerge/memberMergeSlice";
import { Login } from "../Login/Login";
import MemberCard from "./MemberCard";

export const decideBackgroundColor = (
  field: keyof MemberTemplate,
  value: any,
  keepMember: MemberTemplate
): string => {
  if (keepMember && field) {
    if (keepMember[field] === value) {
      return "lightgreen";
    }
  }
  return "";
};

const MemberMerge: React.FC = () => {
  const { formatMessage } = useIntl();

  const members = useAppSelector(membersList);
  const memberSnackBar = useAppSelector(selectSnackBarMessage);
  const keepMember = useAppSelector(memberToKeep);
  const deleteArray = useAppSelector(deleteMembers);
  const unmatchedFields = useAppSelector(selectUnmatchedFieldData);

  const dispatch = useAppDispatch();

  const [memberID, setMemberID] = useState<string>("");
  const [verifyMerge, setVerifyMerge] = useState(false);
  const [mergeMember, { isFetching: isFetchingMergeMember }] =
    useLazyMergeMemberQuery();
  const [fetchMember, { isFetching: isFetchingMember }] =
    useLazyFetchMemberQuery();

  const fetchMemberInfo = () => {
    if (!memberID) {
      return dispatch(setSnackBarNoMemberID());
    }
    fetchMember({
      memberID: parseInt(memberID),
    });
    setMemberID("");
  };

  const submitMemberID = (keyPressed: string) => {
    if (keyPressed === "Enter") {
      fetchMemberInfo();
    }
  };

  const checkMerge = () => {
    unmatchedFields.map((member) => {
      for (const key of Object.keys(member)) {
        const k = key as keyof MemberTemplate;
        if (k === "firstName" || k === "lastName" || k === "dateOfBirth") {
          return dispatch(setSnackBarMessageBlock());
        }
      }
      return setVerifyMerge(true);
    });
  };

  const mergeMembers = () => {
    mergeMember({
      keepMember: keepMember?.memberID,
      deleteMembers: deleteArray,
    });
    setMemberID("");
    setVerifyMerge(false);
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Box sx={{ position: "absolute", left: "49%", top: "50%" }}>
        {isFetchingMember || isFetchingMergeMember ? <CircularProgress /> : ""}
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography
          variant="h5"
          component="h1"
        >
          {formatMessage({ id: "memberActions.header" })}
        </Typography>
        <List sx={{ padding: "2px" }}>
          <ListItem sx={{ display: "flex", justifyContent: "center" }}>
            {formatMessage({ id: "memberActions.description" })}
          </ListItem>
          <ListItem sx={{ display: "flex", justifyContent: "center" }}>
            {formatMessage({ id: "memberActions.description2" })}
          </ListItem>
          <ListItem sx={{ display: "flex", justifyContent: "center" }}>
            {formatMessage({ id: "memberActions.description3" })}
          </ListItem>
        </List>
        <FormControl>
          <TextField
            required
            type="tel"
            sx={{ marginTop: 0, marginBottom: 2, borderRadius: 1 }}
            value={memberID ?? ""}
            label={formatMessage({ id: "memberActions.input.actions" })}
            onKeyDown={(e) => submitMemberID(e.key)}
            onChange={(event) => {
              setMemberID(
                !Number.isNaN(Number(event.target.value))
                  ? event.target.value
                  : memberID
              );
            }}
          />
        </FormControl>
      </Box>
      <Box>
        <Button
          type="primary"
          onClick={fetchMemberInfo}
        >
          {formatMessage({ id: "memberActions.buttons.get" })}
        </Button>
      </Box>
      <Box
        component={"span"}
        sx={{
          display: "inline-flex",
          flexWrap: "wrap",
          padding: 0,
          margin: 0,
        }}
      >
        {members.map((member) => (
          <Box key={`member-card-${member.memberID}`}>
            <Chip
              sx={{
                marginRight: 1,
                marginTop: 2,
                background: "#FDFDFF",
                color: "#524BA3",
              }}
              label={`${member.memberID}`}
              onDelete={() => dispatch(removeFromMembersList(member.memberID))}
            />
            <MemberCard member={member} />
          </Box>
        ))}
      </Box>
      {members.length !== 0 && (
        <Box style={{ marginBottom: "10px", marginTop: "10px" }}>
          <Button
            type="primary"
            onClick={checkMerge}
          >
            {formatMessage({ id: "memberActions.buttons.put" })}
          </Button>
        </Box>
      )}
      <Dialog
        open={verifyMerge}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {formatMessage({ id: "memberActions.dialog.title" })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ color: "red" }}
            id="alert-dialog-description"
          >
            {unmatchedFields.length
              ? formatMessage({ id: "memberActions.dialog.doNotMatch" })
              : formatMessage({ id: "memberActions.dialog.doMatch" })}
          </DialogContentText>
          <List>
            {unmatchedFields.length ? (
              Object.keys(unmatchedFields[0]).map((f) => {
                return <ListItem key={f}>{f.toUpperCase()}</ListItem>;
              })
            ) : (
              <ListItem></ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions sx={{ textAlign: "center", alignItems: "center" }}>
          <Button
            type="text"
            onClick={mergeMembers}
          >
            {formatMessage({ id: "memberActions.buttons.confirm" })}
          </Button>
          <Button
            type="text"
            onClick={() => setVerifyMerge(false)}
          >
            {formatMessage({ id: "memberActions.buttons.cancel" })}
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={Boolean(memberSnackBar.message)}
        autoHideDuration={5000}
        onClose={() => dispatch(resetSnackBarMessage())}
        severity={memberSnackBar.severity}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        title={
          memberSnackBar.message
            ? formatMessage({ id: memberSnackBar.message })
            : ""
        }
        subTitle=""
      />
    </Box>
  );
};

export default withAuthenticationRequired(MemberMerge, {
  onRedirecting: () => <Login />,
});
