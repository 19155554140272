import { Paper, styled } from "@mui/material";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "10vh",
  border: 2,
  borderRadius: theme.shape.borderRadius,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: theme.palette.secondary.main,
  color: theme.palette.text.secondary,
}));
