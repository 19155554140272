import { Box } from "@mui/material";
import {
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
  GridColDef,
  GridInputRowSelectionModel,
  GridRowsProp,
  GridSlotsComponent,
  GridValidRowModel,
} from "@mui/x-data-grid";

import { GridColumnVisibilityModel } from "../../resources/clientManagement.types";

export type DataGridProps = {
  pageSize: number;
  pageNumber: number;
  sortFieldName?: string;
  sortDirection?: "asc" | "desc" | null | undefined;
  rowIdField?: string;
  columns: GridColDef[];
  rows: GridRowsProp;
  slots?: Partial<GridSlotsComponent>;
  rowSelectionModel?: GridInputRowSelectionModel;
  columnVisibilityModel?: GridColumnVisibilityModel;
} & MuiDataGridProps;

export const DataGridWrapper: React.FC<DataGridProps> = ({
  pageSize,
  pageNumber,
  sortFieldName,
  sortDirection,
  checkboxSelection,
  loading,
  columns,
  rows,
  rowIdField = "id",
  onRowSelectionModelChange,
  disableRowSelectionOnClick,
  disableColumnMenu,
  slots,
  rowSelectionModel,
  columnVisibilityModel,
}) => {
  return (
    <Box minHeight={200}>
      <MuiDataGrid
        initialState={{
          ...(sortFieldName && {
            sorting: {
              sortModel: [{ field: sortFieldName, sort: sortDirection }],
            },
          }),
          ...(pageSize && {
            pagination: {
              paginationModel: { pageSize, page: pageNumber },
            },
          }),
        }}
        checkboxSelection={checkboxSelection}
        loading={loading}
        columnBufferPx={2}
        columns={columns}
        rows={rows || []}
        getRowId={(row: GridValidRowModel) => row[rowIdField]}
        onRowSelectionModelChange={onRowSelectionModelChange}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        disableColumnMenu={disableColumnMenu}
        pageSizeOptions={[pageSize]}
        slots={slots}
        rowSelectionModel={rowSelectionModel}
        columnVisibilityModel={columnVisibilityModel}
        autoHeight
      />
    </Box>
  );
};
