import {
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import { useState } from "react";

import { InsuranceRecord } from "../../common/resources/exasAdmin.types";
import { useLazyFetchInsuranceByMemberIdQuery } from "../../slices/exactScience/exactScience.service";

export const InsuranceLookup: React.FC = () => {
  const [vcaMemberID, setVcaMemberID] = useState<string>("");
  const [trigger, result] = useLazyFetchInsuranceByMemberIdQuery();

  const buildInsuraceDisplay = (index: number, elem: InsuranceRecord) => {
    return (
      <Box key={`insurance-${index}`}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          width="100%"
        >
          <Typography
            variant="h6"
            textAlign="left"
          >
            Company Name
          </Typography>
          <Typography>{elem.company_name}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          width="100%"
        >
          <Typography
            variant="h6"
            textAlign="left"
          >
            Plan Name
          </Typography>
          <Typography>{elem.plan_name}</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          width="100%"
        >
          <Typography
            variant="h6"
            textAlign="left"
          >
            Coverage Type
          </Typography>
          <Typography>{elem.coverage_type}</Typography>
        </Box>
      </Box>
    );
  };

  const getBoxContents = () => {
    if (result.isFetching || result.isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
        >
          <CircularProgress variant="indeterminate" />
        </Box>
      );
    }
    return result.currentData?.map((elem, index) => {
      if (!elem || !elem.company_name || elem.company_name === '""') {
        return (
          <Box key={`insurance-${index}`}>
            <Typography variant="h6">Self Pay</Typography>
          </Box>
        );
      }
      return buildInsuraceDisplay(index, elem);
    });
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ mt: 6 }}
    >
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Typography variant="h6">Insurance Lookup By Member Id</Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            width="100%"
          >
            <TextField
              label="VCA Member Id"
              value={vcaMemberID}
              onChange={(event) => setVcaMemberID(event.target.value)}
            />
            <Button
              onClick={() => {
                trigger(vcaMemberID);
              }}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
          <Box>{getBoxContents()}</Box>
        </Stack>
      </Card>
    </Container>
  );
};
