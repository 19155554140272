import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../../app/store";
import {
  ClientOfferingCode,
  ClientsResponse,
  CreateClientInput,
  GetAllConsultationsResponse,
  Group,
  NewProductOffering,
  Payer,
  ProductOfferingResponse,
  SelectedProductOfferings,
  VCAResponse,
  VCResponse,
} from "../../common/resources/clientManagement.types";

export const clientManagementApi = createApi({
  reducerPath: "clientManagementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BENEFITS_URL,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const state = getState() as RootState;
      if (state.user.accessToken) {
        headers.append("Authorization", `Bearer ${state.user.accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Client", "ProductOfferings", "Groups"],
  endpoints: (builder) => ({
    assignPO: builder.mutation<
      VCResponse,
      {
        groupID: string | null;
        productOfferings: SelectedProductOfferings[];
      }
    >({
      query: (body) => ({
        url: `/clientManagement/api/po/assign`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Groups"],
    }),

    createPO: builder.mutation<VCResponse, NewProductOffering>({
      query: (body) => ({
        url: `/clientManagement/api/po/create`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ProductOfferings"],
    }),

    createSOC: builder.query<VCAResponse, ClientOfferingCode>({
      query: (body) => ({
        url: `/clientManagement/api/po/soc/create`,
        method: "POST",
        body: body,
      }),
    }),

    createClient: builder.mutation<VCResponse, CreateClientInput>({
      query: (body) => ({
        body: {
          name: body.name,
          startDate: body.startDate,
          endDate: body.endDate,
          onlyMemberIdRequired: body.onlyMemberIdRequired,
          generateUtilizationReports: body.generateUtilizationReports,
        },
        url: `/clientManagement/api/client/create`,
        method: "POST",
      }),
      invalidatesTags: ["Client"],
    }),

    updateClient: builder.mutation<VCResponse, CreateClientInput>({
      query: (body) => ({
        body: {
          clientID: body.id,
          name: body.name,
          active: true,
          onlyMemberIdRequired: body.onlyMemberIdRequired,
          generateUtilizationReports: body.generateUtilizationReports,
        },
        url: `/clientManagement/api/client/update`,
        method: "PUT",
      }),
      invalidatesTags: ["Client"],
    }),

    createNewGroup: builder.mutation<VCAResponse, Group>({
      query: (body) => ({
        url: `/clientManagement/api/group/create`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Groups"],
    }),

    fetchAllClients: builder.query<ClientsResponse, void>({
      query: () => ({
        url: `/clientManagement/api/client/all`,
        method: "GET",
      }),
      providesTags: ["Client"],
    }),

    fetchProductOffering: builder.query<
      ProductOfferingResponse,
      { clientId: string }
    >({
      query: (body) => ({
        url: `/productActions/api/product/${body.clientId}/productoffering`,
        method: "GET",
      }),
      providesTags: ["ProductOfferings"],
    }),

    fetchAllConsultTypes: builder.query<GetAllConsultationsResponse, void>({
      query: () => ({
        url: `/clientManagement/api/po/consultTypes`,
        method: "GET",
      }),
    }),

    fetchAllCoupons: builder.query<VCAResponse, void>({
      query: () => ({
        url: `/clientManagement/api/coupon/all`,
        method: "GET",
      }),
    }),

    fetchAllGroupsByClient: builder.query<Group[], { clientID: string }>({
      query: (body) => ({
        url: `/clientManagement/api/client/${body.clientID}/groups`,
        method: "GET",
      }),
      providesTags: ["Groups"],
      transformResponse: (response: VCAResponse) => {
        const newResponse: Group[] = response.data.Data.map((group: any) => {
          const {
            name,
            demoOnly,
            customGroupID,
            emailFulfillment,
            hardCopyFulfillment,
            payerId,
          } = group;
          delete Object.assign(group, {
            groupName: name,
          })["name"];
          delete Object.assign(group, {
            isDemo: demoOnly,
          })["demoOnly"];
          delete Object.assign(group, {
            clientGroupID: customGroupID,
          })["customGroupID"];
          delete Object.assign(group, {
            groupEmailFul: emailFulfillment,
          })["emailFulfillment"];
          delete Object.assign(group, {
            groupHardCopyFul: hardCopyFulfillment,
          })["hardCopyFulfillment"];
          delete Object.assign(group, {
            payerID: payerId,
          })["payerId"];
          return group;
        });
        return newResponse;
      },
    }),

    fetchAllPayers: builder.query<Payer[], void>({
      query: () => ({
        url: `/clientManagement/api/payer/all`,
        method: "GET",
      }),
      transformResponse: (response: VCAResponse) => {
        const newResponse: any = Object.keys(response.data.Data).map(
          (key: any) => {
            const lowerCaseObject: any = {};
            Object.keys(response.data.Data[key]).forEach((property: string) => {
              if (property !== "NM109PayerID") {
                lowerCaseObject[
                  property.charAt(0).toLowerCase() + property.slice(1)
                ] = response.data.Data[key][property];
              } else {
                lowerCaseObject[property] = response.data.Data[key][property];
              }
            });
            return lowerCaseObject;
          }
        );
        return newResponse;
      },
    }),

    updateGroup: builder.query<Group, { updateGroup: Group }>({
      query: (body) => ({
        url: `/clientManagement/api/group/${body.updateGroup.groupID}/update`,
        method: "PUT",
        body: { ...body.updateGroup },
      }),
      transformResponse: (response: VCAResponse) => {
        const newResponse: any = {
          ...response.data.Data,
        };
        delete Object.assign(newResponse, {
          groupName: newResponse["name"],
        })["name"];
        delete Object.assign(newResponse, {
          isDemo: newResponse["demoOnly"],
        })["demoOnly"];
        delete Object.assign(newResponse, {
          clientGroupID: newResponse["customGroupID"],
        })["customGroupID"];
        delete Object.assign(newResponse, {
          groupEmailFul: newResponse["emailFulfillment"],
        })["emailFulfillment"];
        delete Object.assign(newResponse, {
          groupHardCopyFul: newResponse["hardCopyFulfillment"],
        })["hardCopyFulfillment"];
        delete Object.assign(newResponse, {
          payerID: newResponse["payerId"],
        })["payerId"];
        return newResponse;
      },
    }),

    updatePO: builder.mutation<
      VCAResponse,
      {
        ClientID: string;
        BillingFee: string;
        CouponID: number | null;
        SetToDependent: boolean | null;
        StartDate: Date;
        ConsultationFees: object[];
        ClientProductOfferingID: string;
      }
    >({
      query: (body) => ({
        url: `/clientManagement/api/po/update`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["ProductOfferings"],
    }),
  }),
});

export const {
  useFetchAllClientsQuery,
  useFetchProductOfferingQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useLazyUpdateGroupQuery,
  useAssignPOMutation,
  useCreatePOMutation,
  useLazyCreateSOCQuery,
  useCreateNewGroupMutation,
  useFetchAllConsultTypesQuery,
  useFetchAllGroupsByClientQuery,
  useLazyFetchAllConsultTypesQuery,
  useLazyFetchAllPayersQuery,
  useLazyFetchAllGroupsByClientQuery,
  useUpdatePOMutation,
  useFetchAllCouponsQuery,

} = clientManagementApi;
