import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useIntl } from "react-intl";

export const ConfirmCloseModal: React.FC<{
  isOpen: boolean;
  closeModalCancel: () => void;
  closeModalConfirm: () => void;
}> = ({ isOpen, closeModalCancel, closeModalConfirm }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog open={isOpen}>
      <DialogTitle id="alert-dialog-title">
        {formatMessage({ id: "bulkOrders.programCreation.cancelModal.title" })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {formatMessage({ id: "bulkOrders.programCreation.cancelModal.text" })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={closeModalCancel}
        >
          {formatMessage({ id: "ui.button.cancel" })}
        </Button>
        <Button
          variant="contained"
          onClick={closeModalConfirm}
        >
          {formatMessage({ id: "ui.button.confirm" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
