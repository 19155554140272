import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { allReportTypes } from "../../../common/resources/reporting.mock";
import { useFetchReportTypesQuery } from "../../../slices/report/report.service";
import { setReportTypes } from "../../../slices/report/reportSlice";

function useFetchReportTypes() {
  const { data, isLoading, isError } = useFetchReportTypesQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoading && !isError && data) {
      dispatch(setReportTypes({ reportTypes: data }));
    } else if (isError) {
      // TO-DO: Change to implement real endpooint call
      dispatch(setReportTypes({ reportTypes: allReportTypes }));
    }
  }, [data, isLoading, isError, dispatch]);
}

export default useFetchReportTypes;
