import { Add, Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import React from "react";
import { useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { BenefitInputCard } from "../../common/components/BenefitDisplay/BenefitInputCard";
import { CardInputCard } from "../../common/components/BenefitDisplay/CardInputCard";
import { DropZone } from "../../common/components/DropZone/DropZone";
import { FocusedHelperInput } from "../../common/components/HelperInput/HelperInput";
import {
  BenefitTemplate,
  TemplateStyle,
} from "../../common/resources/benefits.types";
import { theme } from "../../common/theme/theme";
import {
  selectDisplaySelected,
  updateBenefit,
} from "../../slices/benefits/benefitSlice";

export const BenefitForm: React.FC = () => {
  const { formatMessage } = useIntl();

  const selected = useAppSelector(selectDisplaySelected) as BenefitTemplate;
  const dispatch = useAppDispatch();

  return (
    <Box key="drawer-anchor">
      <Box
        component="form"
        sx={{
          minWidth: "30vw",
          marginTop: 5,
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl fullWidth>
          <ListItem sx={{ marginBottom: 4, width: "100%" }}>
            <Checkbox
              checked={selected.active ?? false}
              onChange={(value) => {
                dispatch(
                  updateBenefit({
                    ...selected,
                    active: value.currentTarget.checked,
                  })
                );
              }}
            />
            <Box flex={"column"}>
              <ListItemText
                primary={formatMessage({ id: "benefits.form.helperText" })}
              />
              <FormHelperText>
                {` ${formatMessage({ id: "benefits.form.listHelperText" })} `}
              </FormHelperText>
            </Box>
          </ListItem>
          <FocusedHelperInput
            label={formatMessage({ id: "benefits.form.nameLabel" })}
            value={selected.name ?? ""}
            onChange={(value) => {
              dispatch(updateBenefit({ ...selected, name: value }));
            }}
            helperText={formatMessage({ id: "benefits.form.nameInputHelper" })}
          />
          <DesktopDatePicker
            label={formatMessage({ id: "benefits.form.datePickerHelper" })}
            value={selected.goLive ? new Date(selected.goLive) : new Date()}
            onChange={(newValue) => {
              if (newValue) {
                dispatch(
                  updateBenefit({
                    ...selected,
                    goLive: newValue.toISOString(),
                  })
                );
              }
            }}
            slotProps={{
              textField: { sx: { marginBottom: 4, width: "100%" } },
            }}
          />
          <FocusedHelperInput
            label={formatMessage({ id: "benefits.form.labelLabel" })}
            value={selected.label ?? ""}
            onChange={(value) => {
              dispatch(updateBenefit({ ...selected, label: value }));
            }}
            helperText={formatMessage({ id: "benefits.form.labelHelper" })}
          />
          <FocusedHelperInput
            label={formatMessage({ id: "benefits.form.chiLabel" })}
            value={selected.chiClient ?? ""}
            onChange={(value) => {
              dispatch(updateBenefit({ ...selected, chiClient: value }));
            }}
            helperText={formatMessage({ id: "benefits.form.chiClientHelper" })}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
            }}
          >
            <IconButton
              onClick={() => {
                const newBenefit = { ...selected };
                newBenefit.summaryImage = "";
                dispatch(updateBenefit(newBenefit));
              }}
              size="small"
              sx={{ height: "25pt" }}
            >
              <Close color="error" />
            </IconButton>
            <Box sx={{ width: "100%" }}>
              <DropZone
                fileFormat={["png", "svg"]}
                field="summaryImage"
                selected={selected}
                key={`${selected.id}-summary-drop`}
              >
                <FocusedHelperInput
                  label={formatMessage({ id: "benefits.form.logoTile" })}
                  value={selected.summaryImage ?? ""}
                  helperText={formatMessage({ id: "benefits.form.logoHelper" })}
                />
              </DropZone>
            </Box>
          </Box>
          <FocusedHelperInput
            label={formatMessage({ id: "benefits.form.ctaLabel" })}
            value={selected.ctaLabel}
            onChange={(value) => {
              dispatch(updateBenefit({ ...selected, ctaLabel: value }));
            }}
            helperText={formatMessage({ id: "benefits.form.ctaHelper" })}
          />
          <FormControl fullWidth>
            <InputLabel id="template-select-select-label">
              {formatMessage({ id: "benefits.form.templateType" })}
            </InputLabel>
            <Select
              fullWidth
              sx={{
                backgroundColor: theme.palette.background.paper,
                textAlign: "start",
                marginBottom: 4,
              }}
              onChange={(event) => {
                dispatch(
                  updateBenefit({
                    ...selected,
                    template: event.target.value as TemplateStyle,
                  })
                );
              }}
              labelId="template-select-label"
              id="templete-select"
              value={selected.template}
              label={formatMessage({ id: "benefits.form.templateType" })}
            >
              {Object.entries(TemplateStyle).map((elem, index) => {
                return (
                  <MenuItem
                    key={`menu-item-${index}`}
                    value={elem[1]}
                  >
                    {elem[0].replace("_", " ")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Divider />
          {(selected.template === TemplateStyle.Card_Template ||
            selected.template === TemplateStyle.Hero_Template) && (
            <Box
              sx={{
                minWidth: "30vw",
              }}
            >
              <FormControl fullWidth>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ marginBottom: 2 }}>
                    {formatMessage({ id: "benefits.form.cardData" })}
                  </Typography>
                </Box>
                {selected.customData.card.length === 0 && (
                  <Box sx={{ paddingBottom: 2 }}>
                    <Typography
                      variant="body1"
                      color="GrayText"
                    >
                      {formatMessage({ id: "benefits.form.noCardPrefix" })}
                      <Add />
                      {formatMessage({ id: "benefits.form.noCardSuffix" })}
                    </Typography>
                  </Box>
                )}
                {selected.customData.card.map((elem, cardIndex) => {
                  return (
                    <CardInputCard
                      key={`benefit-input-${cardIndex}`}
                      selected={selected}
                      card={elem}
                      index={cardIndex}
                    />
                  );
                })}
              </FormControl>
              <Divider />
            </Box>
          )}
          <Box
            sx={{
              minWidth: "30vw",
              marginTop: 0,
            }}
          >
            <FormControl fullWidth>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ marginBottom: 2 }}>
                  {formatMessage({ id: "benefits.form.contentData" })}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                }}
              >
                <IconButton
                  onClick={() => {
                    const newBenefit = { ...selected };
                    newBenefit.customData = { ...newBenefit.customData };
                    newBenefit.customData.logo = "";
                    dispatch(updateBenefit(newBenefit));
                  }}
                  size="small"
                  sx={{ height: "25pt" }}
                >
                  <Close color="error" />
                </IconButton>
                <Box sx={{ width: "100%" }}>
                  <DropZone
                    fileFormat={["png", "svg"]}
                    field="logo"
                    selected={selected}
                    key={`${selected.id}-logo-drop`}
                  >
                    <FocusedHelperInput
                      label={formatMessage({
                        id: "benefits.form.pageHeaderLogo",
                      })}
                      value={selected.customData.logo ?? ""}
                      helperText={formatMessage({
                        id: "benefits.form.urlLogoDropZone",
                      })}
                    />
                  </DropZone>
                </Box>
              </Box>
              {selected.customData.content.length === 0 && (
                <Box sx={{ paddingBottom: 2 }}>
                  <Typography
                    variant="body1"
                    color="GrayText"
                  >
                    {formatMessage({ id: "benefits.form.noContentPrefix" })}
                    <Add />
                    {formatMessage({ id: "benefits.form.noContentSuffix" })}
                  </Typography>
                </Box>
              )}
              {selected.customData.content.map((elem, index) => {
                return (
                  <BenefitInputCard
                    key={`benefit-input-${index}`}
                    selected={selected}
                    content={elem}
                    index={index}
                  />
                );
              })}
            </FormControl>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};
