import { LinearProgress } from "@mui/material";
import { lazy, Suspense } from "react";

type DataPlotProps = {
  dayReport: any[] | undefined;
};
const DataPlot: React.FC<DataPlotProps> = ({ dayReport }) => {
  const Plot = lazy(() => import("react-plotly.js"));
  return (
    <Suspense
      fallback={
        <LinearProgress
          sx={{ width: "100%" }}
          variant="query"
        />
      }
    >
      <Plot
        data={[
          {
            x: dayReport?.map((elem: any) => elem.Date) ?? [],
            y: dayReport?.map((elem: any) => elem.Count) ?? [],
            type: "scatter",
            mode: "lines+markers",
            marker: { color: "red" },
          },
        ]}
        layout={{
          width: window.innerWidth - 60,
          height: 400,
          title: "Cologuard Orders Placed With Recuro",
        }}
      />
    </Suspense>
  );
};

export default DataPlot;
