import { ArrowForwardIos } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { parseISO } from "date-fns";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { boolean, object, string } from "yup";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppDispatch } from "../../app/store";
import CustomSnackbar from "../../common/components/CustomSnackBar/CustomSnackBar";
import {
  ClientTemplate,
  CreateDefaultGroup,
  Group,
  groupForm,
} from "../../common/resources/clientManagement.types";
import {
  useCreateNewGroupMutation,
  useLazyUpdateGroupQuery,
} from "../../slices/clientManagement/clientManagement.service";
import {
  resetGroupStatus,
  selectClient,
  selectGroupById,
  selectGroupStatus,
  selectPayers,
  selectSnackBarOpen,
  setSnackBarOpen,
} from "../../slices/clientManagement/clientManagementSlice";
import { StyledSwitch } from "../Clients/ClientView.style";

export type GroupCreateUpdateProps = {
  isOpen: boolean;
  groupId?: string;
  setSelected: (arg0?: string) => void;
  setModalOpen: (arg0: boolean) => void;
};

export const GroupCreateUpdate: React.FC<GroupCreateUpdateProps> = ({
  isOpen,
  groupId,
  setSelected,
  setModalOpen,
}) => {
  const { formatMessage } = useIntl();
  const status = useAppSelector(selectGroupStatus);
  const payers = useAppSelector(selectPayers);
  const dispatch: AppDispatch = useAppDispatch();
  const [createNewGroup] = useCreateNewGroupMutation();
  const [updateNewGroup] = useLazyUpdateGroupQuery();
  const snackBarOpen = useAppSelector(selectSnackBarOpen);
  const [open, setOpen] = useState(false);
  const group: Group | undefined = useAppSelector((state) => {
    return selectGroupById(state, groupId);
  });
  const [editableGroup, setEditableGroup] = useState<Group | undefined>({
    ...group,
  } as Group);
  const client: ClientTemplate = useAppSelector(selectClient);

  const defaultGroup = {
    ...CreateDefaultGroup(client.clientID),
    groupID: "",
    groupName: "",
    clientGroupID: "",
    payerID: "",
  };

  useEffect(() => {
    if (group === undefined) {
      setEditableGroup(defaultGroup);
    } else {
      setEditableGroup({
        ...group,
        startDate: parseISO(group.startDate?.toString()),
        endDate: group.endDate ? parseISO(group.endDate.toString()) : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  useEffect(() => {
    if (status.update === "success" || status.create === "success") {
      setEditableGroup(defaultGroup);
      setModalOpen(false);
      setOpen(false);
      formik.resetForm();
      dispatch(resetGroupStatus());
      setSelected(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.create, status.update, setModalOpen]);

  const checkErrorsForModal = () => {
    if (Object.keys(formik.errors).length === 0) {
      formik.handleSubmit();
    }
  };

  const groupSchema = object({
    groupName: string()
      .matches(
        /^[a-zA-Z0-9.,()&' -]+$/,
        formatMessage({ id: "clientManagement.newGroup.groupNameVal" })
      )
      .required(formatMessage({ id: "clientManagement.newGroup.groupReq" })),
    isDemo: boolean().required(),
    clientGroupID: string()
      .matches(
        /^[a-zA-Z0-9.,()&' -]+$/,
        formatMessage({ id: "clientManagement.newGroup.clientGroupIDVal" })
      )
      .required(formatMessage({ id: "clientManagement.newGroup.groupIdReq" })),
    payerID: string().nullable(),
    groupEmailFul: boolean().required(),
    groupHardCopyFul: boolean().required(),
    startDate: string()
      .nullable()
      .required(formatMessage({ id: "clientManagement.newGroup.startDate" }))
      .test(
        "valid-date",
        formatMessage({ id: "clientManagement.newGroup.validDate" }),
        (value: string) => {
          const date = new Date(value || "");
          if (isNaN(date.getTime())) {
            return false;
          }
          if (String(date.getFullYear()).length < 4) {
            return false;
          }
          return true;
        }
      ),
    endDate: string()
      .nullable()
      .test(
        "valid-date",
        formatMessage({ id: "clientManagement.newGroup.validEndDate" }),
        (value: string | undefined | null) => {
          const date = new Date(value || "");
          if (value === null || value === undefined || value === "") {
            return true;
          }
          if (String(date.getFullYear()).length < 4) {
            return false;
          }
          return true;
        }
      ),
  });

  const formik = useFormik({
    initialValues: editableGroup!,
    enableReinitialize: true,
    validationSchema: groupSchema,
    onSubmit: async (values: Group) => {
      if (values.groupID === "" || values.groupID === undefined) {
        createNewGroup(groupForm(client, values));
      } else {
        updateNewGroup({
          updateGroup: {
            ...groupForm(client, values),
          },
        });
      }
    },
  });

  return (
    <Dialog open={isOpen}>
      <CustomSnackbar
        open={Boolean(snackBarOpen.open)}
        autoHideDuration={5000}
        onClose={() =>
          dispatch(setSnackBarOpen({ ...snackBarOpen, open: false }))
        }
        title={
          snackBarOpen.title ? formatMessage({ id: snackBarOpen.title }) : ""
        }
        subTitle={
          snackBarOpen.subTitle
            ? formatMessage({ id: snackBarOpen.subTitle })
            : ""
        }
        severity={snackBarOpen.severity}
      />
      <Card sx={{ p: 2 }}>
        <Typography
          component="h1"
          variant="h4"
          sx={{ color: "#242459" }}
        >
          {group?.clientName}
        </Typography>
        <p>{formatMessage({ id: "clientManagement.newGroup.inputGroup" })}</p>
        <Grid
          spacing={2}
          container
          component="form"
        >
          <Grid
            item
            xs={12}
          >
            <TextField
              id="outlined-basic"
              fullWidth
              InputProps={{ readOnly: true }}
              label={formatMessage({
                id: "clientManagement.newGroup.clientLabel",
              })}
              value={client.clientName}
              disabled
              sx={{ background: "#EDEDED", borderRadius: 1 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              id="groupName"
              name="groupName"
              fullWidth
              required
              label={formatMessage({
                id: "clientManagement.newGroup.groupNameLabel",
              })}
              value={formik.values.groupName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.groupName && Boolean(formik.errors.groupName)
              }
              helperText={formik.touched.groupName && formik.errors.groupName}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              id="clientGroupID"
              name="clientGroupID"
              fullWidth
              required
              label={formatMessage({
                id: "clientManagement.newGroup.groupIDLabel",
              })}
              value={formik.values.clientGroupID}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.clientGroupID &&
                Boolean(formik.errors.clientGroupID)
              }
              helperText={
                formik.touched.clientGroupID && formik.errors.clientGroupID
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Select
              id="payerID"
              name="payerID"
              displayEmpty
              fullWidth
              value={formik.values.payerID || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              renderValue={(selected) => {
                if (!selected || selected.length === 0) {
                  return formatMessage({
                    id: "clientManagement.payers.selectPayer",
                  });
                }
                const selectedPayer = payers.find(
                  (payer) => payer.payerID.toString() === selected.toString()
                );
                return (
                  selectedPayer?.name.toLowerCase() ||
                  formatMessage({ id: "clientManagement.payers.noPayer" })
                );
              }}
            >
              <MenuItem value="">
                {formatMessage({ id: "clientManagement.payers.noPayer" })}
              </MenuItem>
              {payers.map((payer) => (
                <MenuItem
                  key={payer.payerID}
                  value={payer.payerID}
                >
                  {payer.name.toLocaleLowerCase()} - {payer.NM109PayerID}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <DesktopDatePicker
              label="Start Date"
              {...(group ? {} : { disablePast: true })}
              format="MM/dd/yyyy"
              value={new Date(formik.values.startDate)}
              onChange={(value) => {
                formik.setFieldValue("startDate", value);
              }}
              slotProps={{
                textField: {
                  id: "startDate",
                  name: "startDate",
                  fullWidth: true,
                  required: true,
                  label: formatMessage({
                    id: "clientManagement.newGroup.startDateLabel",
                  }),
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.startDate &&
                    Boolean(formik.errors.startDate),
                  helperText:
                    formik.touched.startDate && formik.errors.startDate
                      ? (formik.errors.startDate as string)
                      : "",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex" }}
          >
            <DesktopDatePicker
              label="End Date"
              format="MM/dd/yyyy"
              value={
                formik.values.endDate ? new Date(formik.values.endDate) : null
              }
              onChange={(value) => {
                formik.setFieldValue("endDate", value);
              }}
              slotProps={{
                textField: {
                  id: "endDate",
                  name: "endDate",
                  fullWidth: true,
                  required: false,
                  label: formatMessage({
                    id: "clientManagement.newGroup.endDateLabel",
                  }),
                  onBlur: formik.handleBlur,
                  error:
                    formik.touched.endDate && Boolean(formik.errors.endDate),
                  helperText:
                    formik.touched.endDate && formik.errors.endDate
                      ? (formik.errors.endDate as string)
                      : "",
                },
              }}
            />
            {formik.values.endDate ? (
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <CloseIcon
                  onClick={() => formik.setFieldValue("endDate", null)}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              id="demo"
              name="isDemo"
              control={
                <StyledSwitch
                  sx={{ marginLeft: 7.8 }}
                  checked={formik.values.isDemo}
                />
              }
              label={formatMessage({
                id: "clientManagement.newGroup.demoGroupLabel",
              })}
              labelPlacement="start"
              value={formik.values.isDemo}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              id="emailFul"
              name="groupEmailFul"
              control={
                <StyledSwitch
                  sx={{ marginLeft: 4.5 }}
                  checked={formik.values.groupEmailFul}
                />
              }
              label={formatMessage({
                id: "clientManagement.newGroup.emailLabel",
              })}
              labelPlacement="start"
              value={formik.values.groupEmailFul}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              id="hardFul"
              name="groupHardCopyFul"
              control={
                <StyledSwitch checked={formik.values.groupHardCopyFul} />
              }
              label={formatMessage({
                id: "clientManagement.newGroup.hardCopyLabel",
              })}
              labelPlacement="start"
              value={formik.values.groupHardCopyFul}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "flex-end", display: "flex" }}
          >
            <Button
              sx={{
                marginRight: "10px",
              }}
              variant="outlined"
              onClick={() => {
                setModalOpen(false);
                setSelected(undefined);
                formik.resetForm();
              }}
            >
              {formatMessage({ id: "clientManagement.newGroup.cancel" })}
            </Button>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIos />}
              onClick={() => {
                if (formik.values.endDate) {
                  if (Object.keys(formik.errors).length === 0) {
                    setOpen(true);
                  }
                } else {
                  checkErrorsForModal();
                }
              }}
            >
              {formatMessage({ id: "clientManagement.newGroup.save" })}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            formik.values.endDate = null;
          }}
        >
          <Card sx={{ p: 2 }}>
            <DialogTitle
              sx={{
                color: "#242459",
                justifyContent: "flex-start",
                display: "flex",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              {formatMessage({
                id: "clientManagement.newGroup.confirmChanges",
              })}
            </DialogTitle>
            <DialogContent>
              <Typography maxWidth="22rem">
                {formatMessage({ id: "clientManagement.newGroup.endDateWarn" })}
                {group?.groupName}
                {` ${formatMessage({
                  id: "clientManagement.newGroup.effective",
                })} `}
                {formik.values.endDate
                  ? new Date(formik.values.endDate).toLocaleDateString("en-US")
                  : ""}
                .
              </Typography>
              <Typography sx={{ paddingTop: 2 }}>
                {formatMessage({ id: "clientManagement.newGroup.unsure" })}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-end", display: "flex" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpen(false);
                  formik.values.endDate = null;
                }}
              >
                {formatMessage({ id: "clientManagement.newGroup.cancel" })}
              </Button>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIos />}
                onClick={checkErrorsForModal}
              >
                {formatMessage({
                  id: "clientManagement.newGroup.confirmEndDate",
                })}
              </Button>
            </DialogActions>
          </Card>
        </Dialog>
      </Card>
    </Dialog>
  );
};
