import { withAuthenticationRequired } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { IFormData } from "../../common/resources/bulkOrders.types";
import { useCreateProgramMutation } from "../../slices/bulkOrders/bulkOrders.service";
import { Login } from "../Login/Login";
import CommunicationsConfiguration from "./CommunicationsConfiguration";
import { ConfirmCloseModal } from "./ConfirmCloseModal";
import ProgramConfiguration from "./ProgramConfiguration";
import { ProgramCreationHeader } from "./ProgramCreationHeader";
import {
  communicationSchema,
  programConfigSchema,
  sponsorSchema,
} from "./programCreationSchemas";
import ProgramNavigation from "./ProgramNavigation";
import SponsorInformation from "./SponsorInformation";

const ProgramCreationModal: React.FC<{
  isOpen: boolean;
  setModalOpen: (open: boolean) => void;
}> = ({ isOpen, setModalOpen }) => {
  const [createProgram] = useCreateProgramMutation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [verify, setVerify] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { formatMessage } = useIntl();

  const getValidationSchema = () => {
    switch (activeStep) {
      case 0:
        return sponsorSchema;
      case 1:
        return programConfigSchema;
      case 2:
        return communicationSchema;
    }
  };

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      id: "",
      file_scheme: "SFTP",
      ingestion_protocol: "ADDITIVE",
      opt_out: false,
      expiration: "",
      label: "",
      workflow: "",
      contact_number: "",
      support_individual_interactions: false,
      contact_email: null,
      obfuscated: false,
      send_outreach: null,
      outbound_reported: false,
      expedited: false,
      ingestion_status: "TODO",
      results_outreach: null,
      created_at: new Date(),
      modified_at: new Date(),
      patient_negative_results_outreach: null,
    },
    validationSchema: getValidationSchema,
    onSubmit: async (values: IFormData) => {
      await createProgram(values);
      setModalOpen(false);
      formik.resetForm();
      setActiveStep(0);
      setVerify(false);
    },
  });

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, formik.values]);

  const currentPage =
    activeStep === 0 ? (
      <SponsorInformation formik={formik} />
    ) : activeStep === 1 ? (
      <ProgramConfiguration formik={formik} />
    ) : activeStep === 2 ? (
      <CommunicationsConfiguration
        formik={formik}
        verify={verify}
        setVerify={setVerify}
      />
    ) : null;

  const closeCreationModal = () => {
    setModalOpen(false);
    setVerify(false);
    formik.resetForm();
    setActiveStep(0);
  };

  const closeModalCancel = () => setConfirmModalOpen(false);

  const closeModalConfirm = () => {
    setConfirmModalOpen(false);
    closeCreationModal();
  };

  const closeModalOpen = () => setConfirmModalOpen(true);

  return (
    <Box>
      <ConfirmCloseModal
        closeModalConfirm={closeModalConfirm}
        closeModalCancel={closeModalCancel}
        isOpen={confirmModalOpen}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen}
      >
        <Box sx={{ padding: 4 }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginY: 2,
            }}
          >
            <Typography variant="h5">
              {formatMessage({ id: "bulkOrders.programCreation.createNew" })}
            </Typography>
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={closeModalOpen}
            />
          </Grid>
          <ProgramCreationHeader activeStep={activeStep} />
          {currentPage}
          <ProgramNavigation
            cancelCreation={closeCreationModal}
            formik={formik}
            handleStepChange={setActiveStep}
            activeStep={activeStep}
            verify={verify}
          />
        </Box>
      </Dialog>
    </Box>
  );
};

export default withAuthenticationRequired(ProgramCreationModal, {
  onRedirecting: () => <Login />,
});
