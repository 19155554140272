import { Box } from "@mui/material";
import React from "react";

import { DataGridWrapper } from "../../common/components/DataGrid/DataGrid";
import { useFetchOrderReportQuery } from "../../slices/exactScience/exactScience.service";
import DataPlot from "./DataPlot";

const DayReportCols = [
  {
    field: "Year",
    headerName: "Year",
    sortable: true,
    minWidth: 150,
  },
  {
    field: "MonthString",
    headerName: "Month",
    sortable: true,
    minWidth: 150,
  },
  {
    field: "DayOfMonth",
    headerName: "Day",
    sortable: true,
    minWidth: 150,
  },
  {
    field: "Count",
    headerName: "Count",
    sortable: true,
    minWidth: 150,
  },
];

export const OrderDayReport: React.FC = () => {
  const { data: dayReport, isFetching } = useFetchOrderReportQuery("day", {});
  return (
    <Box>
      <DataPlot dayReport={dayReport} />
      <DataGridWrapper
        pageSize={10}
        pageNumber={0}
        sortFieldName="count"
        sortDirection="desc"
        loading={isFetching}
        columns={DayReportCols}
        rows={dayReport || []}
      />
    </Box>
  );
};
