import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

export type SSOState = {
  sso: string;
  client: string;
  connection: string;
  domain: string;
};

const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;

export const ssoSlice: Slice<SSOState> = createSlice({
  name: "ssoState",
  initialState: {
    sso: "",
    client: "",
    connection: "",
    domain: REACT_APP_AUTH0_DOMAIN,
  } as SSOState,
  reducers: {
    setSSO: (state, action: PayloadAction<string>) => {
      state.sso = action.payload;
    },
    setClient: (state, action: PayloadAction<string>) => {
      state.client = action.payload;
    },
    setConnection: (state, action: PayloadAction<string>) => {
      state.connection = action.payload;
    },
    setDomain: (state, action: PayloadAction<string>) => {
      state.domain = action.payload;
    },
  },
});

export const { setSSO, setClient, setConnection, setDomain } = ssoSlice.actions;

export const selectClient = (state: RootState) => state.ssoState.client;
export const selectConnection = (state: RootState) => state.ssoState.connection;
export const selectDomain = (state: RootState) => state.ssoState.domain;
export const selectSSO = (state: RootState) => state.ssoState.sso;

export default ssoSlice.reducer;
