import { Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { LanguageKeys } from "../language";
import { getErrorCode } from "../utils/error";

export type ErrorMessageProps = {
  error?: Error;
};
export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  const { formatMessage } = useIntl();
  const code = getErrorCode(error);
  const message = error?.message ?? "";

  let formattedMessage = "";
  try {
    formattedMessage = formatMessage({
      id: code as LanguageKeys,
      defaultMessage: "",
    });
  } catch (error) {
    formattedMessage = "";
  }

  return (
    <>
      <Typography>
        {code}: {formattedMessage}
      </Typography>
      {process.env.NODE_ENV === "development" ? (
        <Typography>{message}</Typography>
      ) : null}
    </>
  );
};

export default ErrorMessage;
