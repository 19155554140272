import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  useFormControl,
} from "@mui/material";

import { theme } from "../../theme/theme";

export const FocusedHelperInput: React.FC<{
  value: string;
  label: string;
  fullWidth?: boolean;
  helperText?: string;
  onChange?: (value: string) => void;
}> = ({ value, label, helperText, onChange, fullWidth = true }) => {
  function InputFocusHelper() {
    const { focused } = useFormControl() || {};

    if (focused && helperText) {
      return (
        <FormHelperText sx={{ height: 0, overflow: "visible" }}>
          {helperText}
        </FormHelperText>
      );
    }
    return <></>;
  }

  return (
    <Box sx={{ marginBottom: 4, width: "100%" }}>
      <FormControl fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <OutlinedInput
          sx={{ backgroundColor: theme.palette.background.paper }}
          fullWidth
          id={label.toLowerCase + "-field"}
          label={label}
          value={value}
          onChange={(change) => {
            if (onChange) {
              onChange(change.target.value);
            }
          }}
        />
        <InputFocusHelper />
      </FormControl>
    </Box>
  );
};
