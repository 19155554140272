import React, { ReactElement, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { useUploadBenefitImageMutation } from "../../../slices/benefits/benefits.service";
import { BenefitTemplate } from "../../resources/benefits.types";

export const DropZone: React.FC<{
  children: ReactElement;
  fileFormat?: string[];
  field: string;
  selected: BenefitTemplate;
}> = ({ children, fileFormat, field, selected }) => {
  const [uploadBenefitImage] = useUploadBenefitImageMutation();

  const onDrop = useCallback((acceptedFiles: any[]) => {
    acceptedFiles.forEach((file) => {
      const reader: FileReader = new FileReader();
      reader.onload = async () => {
        if (selected) {
          const formData = new FormData();
          formData.append("file", file);
          uploadBenefitImage({
            benefit: selected,
            image: formData,
            field: field,
          });
        }
      };
      reader.readAsArrayBuffer(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fileFormatValidator(file: any) {
    const path: string = file.path;
    const pathArr: string[] = path.split(".");
    const format: string = pathArr[pathArr.length - 1];

    if (fileFormat && fileFormat.length > 0 && !fileFormat.includes(format)) {
      return {
        code: "wrong-file-format",
        message: `Incorrect file type for this input`,
      };
    }
    return null;
  }
  const { getRootProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    validator: fileFormatValidator,
  });

  return <div {...getRootProps()}>{children}</div>;
};
