import {
  ClientOfferingCode,
  dataColumns,
  Group,
  GroupOfferingCode,
  ProductOffering,
} from "./clientManagement.types";

export const group: Group[] = [
  {
    groupName: "Fake Group Name",
    clientGroupID: "num234A",
    isDemo: false,
    groupEmailFul: true,
    groupHardCopyFul: false,
    startDate: "10/01/2023",
    endDate: "10/01/2024",
    payerID: "",
    groupID: "123",
    clientID: "123",
    active: false,
    clientName: "FB",
  },
];

export const offering: ProductOffering[] = [
  {
    clientID: 1,
    clientProductOfferingID: 1,
    planName: "666",
    pepm: "6.66",
    couponId: 1,
    setToDependent: true,
    dataColumns: [
      {
        fieldName: "generalMedicalConsultFee",
        value: "1.00",
        available: true,
      },
      {
        fieldName: "licensedCounselingConsultFee",
        value: null,
        available: false,
      },
      {
        fieldName: "generalMedicalGroupFee",
        value: null,
        available: false,
      },
      {
        fieldName: "licensedCounselingConsultFee",
        value: "3.00",
        available: true,
      },
      {
        fieldName: "licensedCounselingGroupFee",
        value: "4.00",
        available: true,
      },
      {
        fieldName: "psychInitialConsultFee",
        value: null,
        available: false,
      },
      {
        fieldName: "psychInitialGroupFee",
        value: null,
        available: false,
      },
      {
        fieldName: "psychFollowUpConsultFee",
        value: null,
        available: false,
      },
      {
        fieldName: "psychFollowUpGroupFee",
        value: null,
        available: false,
      },
      {
        fieldName: "vpcInitialConsultFee",
        value: "9.00",
        available: true,
      },
      {
        fieldName: "vpcInitialGroupFee",
        value: "10.00",
        available: true,
      },
      {
        fieldName: "vpcFollowUpConsultFee",
        value: "11.00",
        available: true,
      },
      {
        fieldName: "vpcFollowUpGroupFee",
        value: null,
        available: false,
      },
    ] as dataColumns[],
  },
  {
    clientID: 2,
    clientProductOfferingID: 2,
    planName: "667",
    pepm: "6.66",
    couponId: 1,
    setToDependent: true,
    dataColumns: [
      {
        fieldName: "generalMedicalConsultFee",
        value: "1.00",
        available: true,
      },
      {
        fieldName: "generalMedicalGroupFee",
        value: "2.00",
        available: true,
      },
      {
        fieldName: "licensedCounselingConsultFee",
        value: null,
        available: false,
      },
      {
        fieldName: "licensedCounselingGroupFee",
        value: null,
        available: false,
      },
      {
        fieldName: "psychInitialConsultFee",
        value: "1.00",
        available: true,
      },
      {
        fieldName: "psychInitialGroupFee",
        value: "1111.11",
        available: true,
      },
      {
        fieldName: "psychFollowUpConsultFee",
        value: null,
        available: false,
      },
      {
        fieldName: "psychFollowUpGroupFee",
        value: null,
        available: false,
      },
      {
        fieldName: "vpcInitialConsultFee",
        value: "2.00",
        available: true,
      },
      {
        fieldName: "vpcInitialGroupFee",
        value: null,
        available: false,
      },
      {
        fieldName: "vpcFollowUpConsultFee",
        value: null,
        available: false,
      },
      {
        fieldName: "vpcFollowUpGroupFee",
        value: null,
        available: false,
      },
    ] as dataColumns[],
  },
];

export const tableHeadCells = [
  {
    id: "visitType",
    numeric: false,
    disablePadding: true,
    label: "Visit Type",
  },
  {
    id: "consultFee",
    numeric: true,
    disablePadding: false,
    label: "Member Fee",
  },
  {
    id: "groupFee",
    numeric: true,
    disablePadding: false,
    label: "Total Fee",
  },
  {
    id: "feeAfterDeductible",
    numeric: true,
    disablePadding: false,
    label: "Member Fee After Deductible",
  },
];

export const tableRows = [
  {
    name: "General Medical",
    consultType: "generalMedical",
  },
  {
    name: "Licensed Counseling",
    consultType: "licensedCounseling",
  },
  {
    name: "Psych Initial",
    consultType: "psychInitial",
    name2: { name: "Psych Follow-Up", consultType: "psychFollowUp" },
  },
  {
    name: "VPC Initial",
    consultType: "vpcInitial",
    name2: { name: "VPC Follow-UP", consultType: "vpcFollowUp" },
  },
];

export const GroupOfferingCodes: GroupOfferingCode[] = [
  {
    ClientProductOfferingID: 663,
    CustomGroupOfferingID: "GROUP CC",
    AllowDependentManagement: true,
  },
  {
    ClientProductOfferingID: 663,
    CustomGroupOfferingID: "GROUP VUC",
    AllowDependentManagement: false,
  },
  {
    ClientProductOfferingID: 670,
    CustomGroupOfferingID: "GROUP VPC",
    AllowDependentManagement: true,
  },
  {
    ClientProductOfferingID: 670,
    CustomGroupOfferingID: "GROUP CC2",
    AllowDependentManagement: true,
  },
  {
    ClientProductOfferingID: 664,
    CustomGroupOfferingID: "GROUP VUC2",
    AllowDependentManagement: false,
  },
  {
    ClientProductOfferingID: 664,
    CustomGroupOfferingID: "GROUP VPC2",
    AllowDependentManagement: true,
  },
  {
    ClientProductOfferingID: 669,
    CustomGroupOfferingID: "GROUP VUC3",
    AllowDependentManagement: false,
  },
  {
    ClientProductOfferingID: 669,
    CustomGroupOfferingID: "GROUP VPC3",
    AllowDependentManagement: true,
  },
];

export const ClientOfferingCodes: ClientOfferingCode[] = [
  {
    ClientProductOfferingID: 663,
    CustomClientProductOfferingID: "Client VPC",
  },
  {
    ClientProductOfferingID: 670,
    CustomClientProductOfferingID: "Client VUC",
  },
  {
    ClientProductOfferingID: 664,
    CustomClientProductOfferingID: "Client CC",
  },
  {
    ClientProductOfferingID: 671,
    CustomClientProductOfferingID: "Client CC",
  },
];
