import "./index.css";

import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { createRoot } from "react-dom/client";
import { RawIntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { store } from "./app/store";
import ErrorBoundary from "./common/components/ErrorBoundary";
import { intl } from "./common/language";
import RootError from "./common/navigation/RootError";
import { theme } from "./common/theme/theme";
import { worker } from "./mocks/browser";
import reportWebVitals from "./reportWebVitals";

const DOMAIN_VALUE: string = process.env.REACT_APP_AUTH0_DOMAIN || "";
const CLIENT_ID: string = process.env.REACT_APP_AUTH0_CLIENT || "";

const ENABLE_MOCK_SERVICE_WORKER: boolean =
  process.env.REACT_APP_ENABLE_MOCK_SERVICE_WORKER === "true";

if (ENABLE_MOCK_SERVICE_WORKER) {
  worker.start({
    onUnhandledRequest(req, print) {
      if (req.url.pathname.startsWith("/api/")) {
        print.warning();
      }

      return;
    },
  });
}

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ErrorBoundary errorComponent={RootError}>
      <Auth0Provider
        domain={DOMAIN_VALUE}
        clientId={CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <RawIntlProvider value={intl}>
              <Provider store={store}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </Provider>
            </RawIntlProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Auth0Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
