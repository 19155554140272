import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../../app/store";
import {
  BenefitTemplate,
  Product,
  TemplateStyle,
} from "../../common/resources/benefits.types";

export const benefitsApi = createApi({
  reducerPath: "benefitsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BENEFITS_URL}/benefits/api`,
    prepareHeaders: (headers, { getState, endpoint }) => {
      if (endpoint !== "uploadBenefitImage") {
        headers.set("Content-Type", "application/json");
      }
      const state = getState() as RootState;
      if (state.user.accessToken) {
        headers.append("Authorization", `Bearer ${state.user.accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Benefits", "Products"],
  endpoints: (builder) => ({
    fetchBenefits: builder.query<{ data: BenefitTemplate[] }, void>({
      query: () => ({
        url: "/benefit",
        method: "GET",
      }),
      providesTags: ["Benefits"],
    }),
    updateBenefit: builder.mutation<any, BenefitTemplate>({
      query: (body) => ({
        url: `/benefit/${body.id}/update`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Benefits"],
    }),
    createBenefit: builder.mutation<
      any,
      {
        newBenefit: {
          name: string;
          label: string;
          active: boolean;
          template: TemplateStyle.Text_Template;
          ctaLabel: string;
          customData: { card: any[]; content: any[] };
        };
      }
    >({
      query: (body) => ({
        url: "/benefit/create",
        method: "POST",
        body: body.newBenefit,
      }),
      invalidatesTags: ["Benefits"],
    }),
    fetchProducts: builder.query<{ data: Product[] }, void>({
      query: () => ({
        url: "/product",
        method: "GET",
      }),
      providesTags: ["Products"],
    }),
    fetchBenefitsForProducts: builder.query<
      any,
      { product: Product; updateSelected: boolean }
    >({
      query: (body) => ({
        url: `/benefit/${body.product.id}`,
        method: "GET",
      }),
    }),
    uploadBenefitImage: builder.mutation<
      any,
      { benefit: BenefitTemplate; image: any; field: string }
    >({
      query: (body) => {
        return {
          url: "/benefit/upload",
          method: "POST",
          body: body.image,
        };
      },
      invalidatesTags: ["Benefits"],
    }),
    updateProduct: builder.mutation<any, Product>({
      query: (body) => ({
        url: "/product/update",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["Products"],
    }),
    createProduct: builder.mutation<
      any,
      { name: string; eligibilityId: string }
    >({
      query: (body) => ({
        url: "/product/create",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Products"],
    }),
  }),
});

export const {
  useFetchBenefitsQuery,
  useUpdateBenefitMutation,
  useCreateBenefitMutation,
  useFetchProductsQuery,
  useLazyFetchBenefitsForProductsQuery,
  useUploadBenefitImageMutation,
  useUpdateProductMutation,
  useCreateProductMutation,
} = benefitsApi;
