import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../../app/store";

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BENEFITS_URL}/reporting/api/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const state = getState() as RootState;
      if (state.user.accessToken) {
        headers.append("Authorization", `Bearer ${state.user.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchReportTypes: builder.query<any, void>({
      query: () => ({
        url: `types`,
        method: "GET",
      }),
    }),
  }),
});

export const { useFetchReportTypesQuery } = reportApi;
