import { Button, Typography } from "@mui/material";
import React from "react";

import { useAppSelector } from "../../../app/hooks";
import { selectDisplaySelected } from "../../../slices/benefits/benefitSlice";
import { BenefitTemplate } from "../../resources/benefits.types";
import { theme } from "../../theme/theme";
import { BenefitTitle } from "./BenefitHeader";
import { CardDisplay } from "./CardDisplay";
import { PrototypeScreen } from "./PrototypeScreen";

export const BenefitScreen: React.FC = () => {
  const benefit = useAppSelector(selectDisplaySelected) as BenefitTemplate;
  return (
    <PrototypeScreen backgroundColor="#f2f2f7">
      <BenefitTitle title={benefit.label} />
      <div
        style={{
          height: "558px",
          overflowY: "scroll",
        }}
      >
        {benefit.customData.logo && (
          <img
            alt={benefit.name}
            style={{
              marginTop: 25,
              height: "70px",
              width: theme.shape.screenWidth - 150,
              objectFit: "contain",
            }}
            src={benefit.customData.logo}
          />
        )}
        <div
          style={{
            width: theme.shape.screenWidth - 34,
            marginLeft: "15pt",
            marginTop: "10pt",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          {benefit.customData.card &&
            benefit.customData.card.map((elem, index) => {
              return (
                <div key={`custom-data-${index}`}>
                  {elem.header && (
                    <Typography
                      variant="benefitsHeader2"
                      textAlign={"start"}
                    >
                      {elem.header}
                    </Typography>
                  )}
                  <CardDisplay cardTemplate={elem} />
                </div>
              );
            })}
          {benefit.customData &&
            benefit.customData.content.map((elem, index) => {
              return (
                <div
                  key={`content-display-${index}`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {elem.subheader && (
                    <Typography variant="benefitsHeader">
                      {elem.subheader}
                    </Typography>
                  )}
                  {elem.body && (
                    <Typography
                      style={{ margin: 10 }}
                      variant="benefitsBody"
                    >
                      {elem.body}
                    </Typography>
                  )}
                  {elem.buttons &&
                    elem.buttons?.map((button, buttonIndex) => {
                      return (
                        <Button
                          key={`content-${index}-button-${buttonIndex}`}
                          variant={button.style}
                          style={{ margin: 5, marginBottom: 8 }}
                          color="benefits"
                        >
                          {button.name}
                        </Button>
                      );
                    })}
                </div>
              );
            })}
          <div style={{ height: 50 }} />
        </div>
      </div>
    </PrototypeScreen>
  );
};
