import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../../app/store";
import {
  AttestationCreationRequest,
  CSVDownloadRequest,
  IFormData,
  OrderGroupResponse,
  orderGroupResponse,
  ProgramsRequest,
  ProgramsResponse,
  SubmitOrderGroupRequest,
  UpdateProviderInformation,
} from "../../common/resources/bulkOrders.types";

export const bulkOrdersApi = createApi({
  reducerPath: "bulkOrdersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_PRIVATE_URL}/exact-sciences-bulk/api`,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const state = getState() as RootState;
      if (state.user.accessToken) {
        headers.append("Authorization", `Bearer ${state.user.accessToken}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Programs", "OrderGroups", "States"],
  endpoints: (builder) => ({
    fetchAllPrograms: builder.query<ProgramsResponse, ProgramsRequest>({
      query: (queryParams) => ({
        url: `/clients/program-bulk-orders`,
        method: "GET",
        params: queryParams,
      }),
      providesTags: ["Programs"],
    }),

    updateProviderInformation: builder.mutation<
      void,
      UpdateProviderInformation
    >({
      query: (body) => ({
        url: `/attestation`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["OrderGroups"],
    }),

    createProgram: builder.mutation<string, IFormData>({
      query: (body) => ({
        url: `/clients`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Programs"],
    }),

    getStates: builder.query<orderGroupResponse, { clientID: string }>({
      query: (body) => ({
        url: `/orders/${body.clientID}`,
        method: "GET",
      }),
      providesTags: ["States"],
    }),

    createOrderGroup: builder.mutation<any, AttestationCreationRequest>({
      query: (body) => ({
        url: `/attestation`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["OrderGroups"],
    }),

    getOrderGroups: builder.query<OrderGroupResponse, { clientID: string }>({
      query: (body) => ({
        url: `/attestation/${body.clientID}`,
        method: "GET",
      }),
      providesTags: ["OrderGroups"],
    }),

    CSVFetch: builder.query<any, CSVDownloadRequest>({
      query: (body) => ({
        url: `/attestation/download-csv/${body.orderGroupId}`,
        method: "GET",
        responseHandler: async (response) => {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = `ordergroup_${body.programId}_${body.orderGroupId}.csv`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        },
        cache: "no-cache",
      }),
    }),

    submitOrders: builder.mutation<void, SubmitOrderGroupRequest>({
      query: (body) => ({
        url: `/attestation/submit-order-group`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["OrderGroups"],
    }),

    deletedOrder: builder.mutation<void, string>({
      query: (orderID) => ({
        url: `/attestation/${orderID}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OrderGroups"],
    }),
  }),
});

export const {
  useCreateProgramMutation,
  useCreateOrderGroupMutation,
  useFetchAllProgramsQuery,
  useLazyFetchAllProgramsQuery,
  useUpdateProviderInformationMutation,
  useLazyCSVFetchQuery,
  useGetStatesQuery,
  useGetOrderGroupsQuery,
  useSubmitOrdersMutation,
  useDeletedOrderMutation,
} = bulkOrdersApi;
