import { Environments } from "../../config/flags.config";
import { accessGroups, AccessKeys } from "../../config/pageAccess.config";

export const checkPageAccess = (
  accessKey: AccessKeys,
  userGroups: string[]
): boolean => {
  const environment: Environments =
    (process.env.REACT_APP_ENVIRONMENT as Environments) || "prod";

  if (environment === "prod") {
    if (userGroups.includes("SG-RecuroTooling-Admin")) {
      return true;
    }
  } else {
    if (userGroups.includes("SG-RecuroTooling-Admin-NonProd")) {
      return true;
    }
  }

  for (let i = 0; i < accessGroups[accessKey].length; i++) {
    if (userGroups.includes(accessGroups[accessKey][i])) {
      return true;
    }
  }

  return false;
};
