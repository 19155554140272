export const formatDate = (date: string) => {
  const newDate = new Date(date.replace(/-/g, "/").replace(/T.+/, ""));
  const month = newDate
    .toLocaleString("default", { month: "numeric" })
    .padStart(2, "0");
  const day = String(newDate.getDate());
  const year = newDate.getFullYear();

  return `${month}/${day}/${year}`;
};
// Only use this function when displaying the expiration date of a program to a user.
// DO NOT USE in calculating whether or not a program has actually expired.
export const formatExpirationDisplayDate = (date: string) => {
  const expirationDisplayValue = new Date(date);
  expirationDisplayValue.setHours(expirationDisplayValue.getHours() - 24);
  const month = expirationDisplayValue
    .toLocaleString("default", { month: "numeric" })
    .padStart(2, "0");
  const day = String(expirationDisplayValue.getDate());
  const year = expirationDisplayValue.getFullYear();

  return `${month}/${day}/${year}`;
};
