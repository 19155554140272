import "./CustomSnackBar.css";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";

export default function CustomSnackbar({
  open,
  onClose,
  severity,
  title,
  subTitle,
  autoHideDuration,
  anchorOrigin,
}: CustomSnackbarProps) {
  const severityIconClass = {
    success: "success-icon",
    info: "info-icon",
    warning: "warning-icon",
    error: "error-icon",
  };

  const severitySnackClass = {
    success: "success-snack",
    info: "info-snack",
    warning: "warning-snack",
    error: "error-snack",
  };

  return (
    <Stack
      spacing={2}
      sx={{ width: "100%" }}
    >
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration || 6000}
        onClose={onClose}
        className={`snack-item ${severitySnackClass[severity]}`}
        anchorOrigin={
          anchorOrigin || { vertical: "bottom", horizontal: "right" }
        }
      >
        <Box className="alert-container">
          <Box
            role="button"
            tabIndex={0}
            className="close-icon"
            onClick={onClose}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                onClose();
              }
            }}
          >
            <CloseIcon fontSize="small" />
          </Box>
          <CheckCircleOutlineIcon
            className={`check-circle ${severityIconClass[severity]}`}
          />
          <Box>
            <Typography
              variant="h6"
              gutterBottom
              display="flex"
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
            >
              {subTitle}
            </Typography>
          </Box>
        </Box>
      </Snackbar>
    </Stack>
  );
}

interface CustomSnackbarProps {
  open: boolean;
  onClose: () => void;
  severity: "success" | "info" | "warning" | "error";
  title: string;
  subTitle: string;
  autoHideDuration?: number;
  anchorOrigin?: {
    vertical: "top" | "bottom";
    horizontal: "left" | "center" | "right";
  };
}
