import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

import { DataGridWrapper } from "../../common/components/DataGrid/DataGrid";
import { ProviderReportRow } from "../../common/resources/reporting.types";
import { useFetchProviderResponseQuery } from "../../slices/exactScience/exactScience.service";

type UIRow = {
  id: number;
  inPerson: number;
  asyncVisit: number;
  firstName: string;
  lastName: string;
  npi: string;
};

export const ProviderReport: React.FC = () => {
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [month, setMonth] = useState<string>(String(new Date().getMonth() + 1));
  const [asyncCount, setAsyncCount] = useState<number>(0);
  const [inPersonCount, setInPersonCount] = useState<number>(0);
  const [providerRows, setProviderRows] = useState<UIRow[]>([]);

  const {
    data: providerReport,
    refetch,
    isFetching,
  } = useFetchProviderResponseQuery({ year, month });

  const ProviderColumns = [
    {
      field: "lastName",
      headerName: "Last Name",
      sortable: true,
      minWidth: 150,
    },
    {
      field: "firstName",
      headerName: "First Name",
      sortable: true,
      minWidth: 150,
    },
    {
      field: "npi",
      headerName: "NPI",
      sortable: true,
      minWidth: 150,
    },
    {
      field: "asyncVisit",
      headerName: "No Visit Consults",
      sortable: true,
      minWidth: 150,
    },
    {
      field: "inPerson",
      headerName: "PreTest Consults",
      sortable: true,
      minWidth: 150,
    },
  ];

  useEffect(() => {
    const providerMap: { [index: string]: UIRow } = {};
    let inPersonCountNew: number = 0;
    let asyncCountNew: number = 0;
    providerReport?.forEach((elem: ProviderReportRow, index: number) => {
      if (providerMap[elem.NPI] === undefined) {
        providerMap[elem.NPI] = {
          id: index,
          inPerson: 0,
          asyncVisit: 0,
          firstName: elem.FirstName.replaceAll('"', ""),
          lastName: elem.LastName.replaceAll('"', ""),
          npi: elem.NPI.replaceAll('"', ""),
        };
      }

      if (elem.InPersonVisit) {
        providerMap[elem.NPI].inPerson = elem.Count;
        inPersonCountNew += elem.Count;
      } else {
        providerMap[elem.NPI].asyncVisit = elem.Count;
        asyncCountNew += elem.Count;
      }
    });
    setProviderRows(Object.values(providerMap));
    setInPersonCount(inPersonCountNew);
    setAsyncCount(asyncCountNew);
  }, [providerReport]);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 15 }}
      >
        <Grid
          item
          xs={6}
          lg={4}
        >
          <DatePicker
            label="Report Date"
            defaultValue={new Date()}
            views={["month", "year"]}
            disableFuture
            onChange={(date) => {
              if (date) {
                setYear(date.getFullYear().toString());
                setMonth(String(date.getMonth() + 1));
                refetch();
              }
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          lg={3}
        >
          <Card>
            {!isFetching && (
              <CardContent>
                <Typography variant="h6">Totals:</Typography>
                <Typography>In Person Totals: {inPersonCount}</Typography>
                <Typography>No Visit Consult Totals: {asyncCount}</Typography>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
      <DataGridWrapper
        pageSize={10}
        pageNumber={0}
        loading={isFetching}
        columns={ProviderColumns}
        rows={providerRows}
      />
    </Box>
  );
};
