import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt/lib/sync";

import { benefitsApi } from "../slices/benefits/benefits.service";
import benefitReducer from "../slices/benefits/benefitSlice";
import bulkOrdersSlice from "../slices/bulkOrders/bulkOrders";
import { bulkOrdersApi } from "../slices/bulkOrders/bulkOrders.service";
import { clientManagementApi } from "../slices/clientManagement/clientManagement.service";
import clientManagementSlice from "../slices/clientManagement/clientManagementSlice";
import { exactSciencesApi } from "../slices/exactScience/exactScience.service";
import { memberApi } from "../slices/memberMerge/memberMerge.service";
import memberMergeSlice from "../slices/memberMerge/memberMergeSlice";
import { reportApi } from "../slices/report/report.service";
import reportSlice from "../slices/report/reportSlice";
import ssoReducer from "../slices/sso/ssoSlice";
import userReducer from "../slices/user/userSlice";

const encryptor = encryptTransform({
  secretKey: (process.env.REACT_APP_SECRET_KEY as string) || "internalsecret",
  onError: (error) => {
    console.error(error);
    throw error;
  },
});

const ssoPersistConfig = {
  key: "ssoState",
  storage,
  transforms: [encryptor],
};

const persistedReducer = combineReducers({
  benefit: benefitReducer,
  user: userReducer,
  memberActions: memberMergeSlice,
  clientManagement: clientManagementSlice,
  bulkOrders: bulkOrdersSlice,
  [bulkOrdersApi.reducerPath]: bulkOrdersApi.reducer,
  ssoState: persistReducer(ssoPersistConfig, ssoReducer),
  reportState: reportSlice,
  [benefitsApi.reducerPath]: benefitsApi.reducer,
  [clientManagementApi.reducerPath]: clientManagementApi.reducer,
  [exactSciencesApi.reducerPath]: exactSciencesApi.reducer,
  [memberApi.reducerPath]: memberApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      memberApi.middleware,
      clientManagementApi.middleware,
      bulkOrdersApi.middleware,
      exactSciencesApi.middleware,
      reportApi.middleware,
      benefitsApi.middleware
    ),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
