import { Box, Stack, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { useIntl } from "react-intl";

import { IFormData } from "../../common/resources/bulkOrders.types";

const SponsorInformation: React.FC<{ formik: FormikProps<IFormData> }> = ({
  formik,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Box sx={{ marginX: 1 }}>
      <Typography
        variant="subtitle1"
        sx={{ marginY: 1 }}
      >
        {formatMessage({ id: "sponsorInformation.title" })}
      </Typography>
      <Typography
        variant="body1"
        sx={{ marginY: 1 }}
      >
        {formatMessage({ id: "sponsorInformation.subTitle" })}
      </Typography>
      <Stack direction="column">
        <TextField
          onChange={formik.handleChange}
          error={Boolean(formik.errors.label) && formik.touched.label}
          helperText={formik.touched.label && formik.errors.label}
          onBlur={formik.handleBlur}
          value={formik.values.label}
          sx={{ width: 330, marginY: 1 }}
          id="label"
          label={formatMessage({ id: "sponsorInformation.planName.label" })}
          placeholder="Name"
        />
        <TextField
          onChange={(e) => {
            const numbersRegex = /^[(0-9).-]{1,14}$/;
            const newVal = e.target.value;
            if (newVal === "" || numbersRegex.test(newVal)) {
              formik.handleChange(e);
            }
          }}
          error={
            Boolean(formik.errors.contact_number) &&
            formik.touched.contact_number
          }
          type="tel"
          onBlur={formik.handleBlur}
          value={formik.values.contact_number}
          helperText={
            formik.touched.contact_number && formik.errors.contact_number
          }
          sx={{ width: 330, marginY: 1 }}
          id="contact_number"
          label={formatMessage({
            id: "sponsorInformation.supportNumber.label",
          })}
          placeholder="(_ _ _) _ _ _ - _ _ _ _"
        />
      </Stack>
    </Box>
  );
};

export default SponsorInformation;
