import { Button as MuiButton } from "@mui/material";
import React, { ReactElement } from "react";

export type ButtonTypes = "primary" | "text" | "underline";
type ButtonVariants = "contained" | "text";
type ButtonSizes = "small" | "medium" | "large";

export type ButtonProps = {
  children: React.ReactElement | string;
  onClick: () => void;
  type: ButtonTypes;
  disabled?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  ariaLabel?: string;
  size?: ButtonSizes;
};

const getVariant = (type: ButtonTypes): ButtonVariants => {
  const variants: { [key: string]: ButtonVariants } = {
    primary: "contained",
    text: "text",
    underline: "text",
  };

  return variants[type];
};

const getStyles = (type: ButtonTypes) => {
  if (type === "underline") {
    return () => ({
      "&": {
        textDecoration: "underline",
      },
      "&:hover": {
        textDecoration: "underline",
      },
    });
  }

  return {};
};

export const Button: React.FC<ButtonProps> = ({
  ariaLabel = null,
  children,
  disabled = false,
  endIcon = null,
  onClick,
  startIcon = null,
  type,
  size = "large",
}) => {
  const variant = getVariant(type);
  const styles = getStyles(type);

  return (
    <MuiButton
      onClick={onClick}
      color="primary"
      variant={variant}
      size={size}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={styles}
      {...(ariaLabel && { "aria-label": ariaLabel })}
    >
      {children}
    </MuiButton>
  );
};
