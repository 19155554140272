import { ReportType, ReportTypeResponse } from "./reporting.types";

const ClientCare: ReportTypeResponse = {
  value: ReportType.Client,
  id: "1",
};

const Invoices: ReportTypeResponse = {
  value: ReportType.Invoices,
  id: "2",
};

const MissedCallReports: ReportTypeResponse = {
  value: ReportType.Missed_Call,
  id: "3",
};

const Registration: ReportTypeResponse = {
  value: ReportType.Registration,
  id: "4",
};

const Service: ReportTypeResponse = {
  value: ReportType.Service,
  id: "5",
};

const State: ReportTypeResponse = {
  value: ReportType.State,
  id: "6",
};

const Utilization: ReportTypeResponse = {
  value: ReportType.Utilization,
  id: "7",
};

const UtilizationCollection: ReportTypeResponse = {
  value: ReportType.Utilization_Collection,
  id: "8",
};

const allReportTypes = [
  ClientCare,
  Invoices,
  MissedCallReports,
  Registration,
  Service,
  State,
  Utilization,
  UtilizationCollection,
];

export { allReportTypes };
