import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Add, Circle, ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Drawer,
  Fab,
  FormControl,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Modal,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { BenefitScreen } from "../../common/components/BenefitDisplay/BenefitScreen";
import { ProductScreen } from "../../common/components/BenefitDisplay/ProductScreen";
import { style } from "../../common/components/LoadingIndicator/LoadingIndicator.style";
import {
  BenefitTemplate,
  CardStyle,
  ContentTemplate,
  Product,
  TemplateStyle,
} from "../../common/resources/benefits.types";
import { theme } from "../../common/theme/theme";
import {
  useCreateBenefitMutation,
  useCreateProductMutation,
  useFetchBenefitsQuery,
  useFetchProductsQuery,
  useLazyFetchBenefitsForProductsQuery,
  useUpdateBenefitMutation,
  useUpdateProductMutation,
} from "../../slices/benefits/benefits.service";
import {
  castStringToId,
  selectBenefits,
  selectDisplaySelected,
  selectProductGroup,
  selectProducts,
  setDisplayElement,
  updateBenefit,
  updateProductGroup,
} from "../../slices/benefits/benefitSlice";
import { Login } from "../Login/Login";
import { BenefitForm } from "./BenefitForm";
import { ProductForm } from "./ProductForm";

const Benefits: React.FC = React.memo(() => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();
  const benefits = useAppSelector(selectBenefits) as BenefitTemplate[];
  const products = useAppSelector(selectProducts) as Product[];
  const selected = useAppSelector(selectDisplaySelected) as any;

  const productGroup = useAppSelector(selectProductGroup);
  const [benefitsOpen, setBenefitsOpen] = useState(true);
  const [productsOpen, setProductsOpen] = useState(true);
  const [modalData, setModalData] = useState<{
    open: boolean;
    type?: "benefit" | "product";
    name?: string;
  }>({ open: false });
  const [drawer, setDrawer] = useState<boolean>(false);

  const [productsSelectorOpen, setProductsSelectorOpen] = useState(true);
  const [fetchBenefitsForProducts] = useLazyFetchBenefitsForProductsQuery();
  const [updateBenefitQuery] = useUpdateBenefitMutation();
  const [createNewBenefit] = useCreateBenefitMutation();
  const [createNewProduct] = useCreateProductMutation();
  const [updateProductQuery] = useUpdateProductMutation();

  useEffect(() => {
    if (!selected && benefits.length > 0) {
      dispatch(setDisplayElement(benefits[0]));
    }
  }, [benefits, dispatch, selected]);

  useFetchBenefitsQuery();
  useFetchProductsQuery();

  const addCardSection = () => {
    dispatch(
      updateBenefit({
        ...selected,
        customData: {
          ...selected.customData,
          card: [
            ...selected.customData.card,
            {
              hero: false,
              style: CardStyle.Left_Align,
              hasOverlay: true,
              src: "",
            },
          ],
        },
      })
    );
  };

  const addBodySection = () => {
    const content = selected.customData.content.map(
      (content: ContentTemplate) => {
        return { ...content };
      }
    );
    content.push({});
    const customData = { ...selected.customData, content: content };
    dispatch(updateBenefit({ ...selected, customData: customData }));
  };

  return (
    <Box>
      <Box>
        <Grid2
          container
          spacing={2}
        >
          <Grid2
            xs={5}
            sm={4}
            md={3}
            lg={3}
          >
            <Drawer
              anchor="left"
              variant="permanent"
              sx={{
                width: "20%",
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: "20%",
                  boxSizing: "border-box",
                },
              }}
            >
              <Toolbar sx={{ padding: 2 }} />
              <List>
                <ListSubheader
                  key={`list-subheader-benefits`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "start",
                    alignItems: "center",
                  }}
                  component="div"
                  id="nested-list-subheader"
                  onClick={() => {
                    setBenefitsOpen(!benefitsOpen);
                  }}
                >
                  <ListItemText primary="Benefits" />
                  {benefitsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListSubheader>
                <Collapse
                  in={benefitsOpen}
                  unmountOnExit
                >
                  <List
                    component="div"
                    disablePadding
                  >
                    {benefits.map((elem: BenefitTemplate, index) => {
                      return (
                        <ListItemButton
                          key={`benefits-list-${index}`}
                          selected={elem?.id === selected?.id}
                          sx={{
                            pl: 4,
                          }}
                          onClick={() => {
                            dispatch(setDisplayElement(elem));
                          }}
                        >
                          <ListItemText primary={elem.name} />
                          {elem.dirty && (
                            <Circle
                              color="primary"
                              sx={{ height: 8 }}
                            />
                          )}
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
                <ListSubheader
                  key={`list-subheader-products`}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "start",
                    alignItems: "center",
                  }}
                  component="div"
                  id="nested-list-subheader"
                  onClick={() => {
                    setProductsOpen(!productsOpen);
                  }}
                >
                  <ListItemText primary="Products" />
                  {productsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListSubheader>
                <Collapse
                  in={productsOpen}
                  unmountOnExit
                >
                  <List
                    component="div"
                    disablePadding
                  >
                    {products.map((elem: Product, index: number) => {
                      return (
                        <ListItemButton
                          key={`benefits-list-${index}`}
                          selected={elem?.id === selected?.id}
                          sx={{ pl: 4 }}
                          onClick={() => {
                            fetchBenefitsForProducts({
                              product: elem,
                              updateSelected: true,
                            });
                          }}
                        >
                          <ListItemText primary={elem.name} />
                          {elem.dirty && (
                            <Circle
                              color="primary"
                              sx={{ height: 8 }}
                            />
                          )}
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              </List>
            </Drawer>
          </Grid2>
          <Grid2
            xs={7}
            sm={8}
            md={4}
            lg={4}
          >
            <Typography>
              {selected?.label ? "Benefit" : "Product"}
              {formatMessage({ id: "benefits.selectedLabel" })}
            </Typography>
            {selected?.label && <BenefitForm />}
            {selected?.benefits && <ProductForm />}
          </Grid2>
          <Grid2
            sm={12}
            md={5}
            sx={{
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
            }}
          >
            {selected?.benefits && (
              <Box sx={{ width: "100%" }}>
                <List>
                  <ListSubheader
                    disableSticky
                    key={`list-subheader-products`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "start",
                      alignItems: "center",
                    }}
                    component="div"
                    id="nested-list-subheader"
                    onClick={() => {
                      setProductsSelectorOpen(!productsSelectorOpen);
                    }}
                  >
                    <ListItemText primary="All Products" />
                    {productsSelectorOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListSubheader>
                  <Collapse
                    in={productsSelectorOpen}
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                    >
                      {products.map((elem, index) => {
                        return (
                          <ListItemButton
                            key={`checklist-item-${index}`}
                            onClick={() => {
                              fetchBenefitsForProducts({
                                product: elem,
                                updateSelected: false,
                              });
                              dispatch(updateProductGroup(elem));
                            }}
                            sx={{ pl: 4 }}
                          >
                            <Checkbox
                              checked={productGroup?.includes(
                                elem.eligibilityId
                              )}
                              color="warning"
                            ></Checkbox>
                            <ListItemText primary={elem.name} />
                            {selected.id === elem.id && (
                              <Typography color="GrayText">
                                {formatMessage({ id: "benefits.editing" })}
                              </Typography>
                            )}
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                </List>
                <Typography variant="h6">{productGroup?.join("+")}</Typography>
              </Box>
            )}
            {selected?.label && <BenefitScreen />}
            {selected?.benefits && (
              <ProductScreen products={productGroup ? productGroup : []} />
            )}
          </Grid2>
        </Grid2>

        <Modal
          open={modalData.open}
          onClose={() => setModalData({ open: false })}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                id="modal-title"
                variant="h6"
                component="h2"
              >
                {formatMessage({ id: "benefits.modalTitle" })} {modalData.type}
              </Typography>
              <FormControl>
                <TextField
                  value={modalData.name ?? ""}
                  onChange={(event) => {
                    setModalData({ ...modalData, name: event.target.value });
                  }}
                />
              </FormControl>
              <Button
                disabled={!modalData.name}
                onClick={() => {
                  if (modalData.name) {
                    if (modalData.type === "benefit") {
                      createNewBenefit({
                        newBenefit: {
                          name: modalData.name,
                          label: modalData.name,
                          active: true,
                          template: TemplateStyle.Text_Template,
                          ctaLabel: "View More",
                          customData: {
                            card: [],
                            content: [],
                          },
                        },
                      });
                    } else {
                      createNewProduct({
                        name: modalData.name,
                        eligibilityId: castStringToId(modalData.name),
                      });
                    }
                    setModalData({ open: false });
                  }
                }}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Modal>

        {!drawer && (
          <Fab
            color="secondary"
            aria-label="edit"
            onClick={() => {
              setDrawer(true);
            }}
            sx={{
              margin: 0,
              top: "auto",
              left: 20,
              bottom: 20,
              right: "auto",
              zIndex: theme.zIndex.drawer + 1,
              position: "fixed",
            }}
          >
            <Add />
          </Fab>
        )}
        {!drawer && (
          <Fab
            disabled={!selected?.dirty}
            color="secondary"
            variant="extended"
            aria-label="save"
            onClick={() => {
              if (selected.label) {
                updateBenefitQuery(selected);
              } else {
                updateProductQuery(selected);
              }
            }}
            sx={{
              margin: 0,
              top: "auto",
              left: 100,
              bottom: 20,
              right: "auto",
              zIndex: theme.zIndex.drawer + 1,
              position: "fixed",
            }}
          >
            Save
          </Fab>
        )}
        <Drawer
          anchor="bottom"
          open={drawer}
          onClose={() => {
            setDrawer(false);
          }}
        >
          <List>
            {selected?.label && (
              <Box>
                <ListItemButton
                  onClick={() => {
                    addBodySection();
                    setDrawer(false);
                  }}
                >
                  {formatMessage({ id: "benefits.contentBlockButton" })}
                </ListItemButton>
                <ListItemButton
                  disabled={selected.template === TemplateStyle.Text_Template}
                  onClick={() => {
                    addCardSection();
                    setDrawer(false);
                  }}
                >
                  {formatMessage({ id: "benefits.contentCardButton" })}
                </ListItemButton>
              </Box>
            )}
            <ListItemButton
              onClick={() => {
                setModalData({ open: true, type: "product" });
                setDrawer(false);
              }}
            >
              {formatMessage({ id: "benefits.newProductButton" })}
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                setModalData({ open: true, type: "benefit" });
                setDrawer(false);
              }}
            >
              {formatMessage({ id: "benefits.newBenefitButton" })}
            </ListItemButton>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
});

const authenticatedElement: React.FC = withAuthenticationRequired(Benefits, {
  onRedirecting: () => <Login />,
});

export default authenticatedElement;
