import {
  Benefits,
  BenefitTemplate,
  ButtonFormat,
  ButtonStyle,
  CardStyle,
  Product,
  TemplateStyle,
} from "./benefits.types";

const Brightline: BenefitTemplate = {
  name: "Brightline",
  active: true,
  id: Benefits.Brightline,
  label: "Pediatric Behavioral Health",
  ctaLabel: "View More",
  template: TemplateStyle.Text_Template,
  summaryImage:
    "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/brightline/brightline-logo.png",
  customData: {
    card: [],
    logo: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/brightline/brightline-logo.png",
    content: [
      {
        body: "Family Behavioral Health is designed specifically to support toddlers through teens with a range of needs. With this benefit, expert therapists and coaches provide personalized support through virtual visits, and on-demand resources are available to parents and caregivers. Six virtual sessions are included.",
      },
      {
        subheader: "To setup your account and get started:",
        buttons: [
          {
            name: "Go to Brightline Portal",
            value: "https://www.hellobrightline.com/recuro",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Text,
          },
        ],
      },
      {
        body: "Note: when signing up, make sure to answer “yes” to “Is Brightline a covered benefit for you?”",
      },
    ],
  },
};

const Papa: BenefitTemplate = {
  name: "Papa",
  active: true,
  id: Benefits.Papa,
  label: "Companion Care",
  ctaLabel: "View More",
  template: TemplateStyle.Text_Template,
  summaryImage:
    "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/papa/Papa Logo - PNG.png",
  customData: {
    card: [],
    logo: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/papa/Papa Logo - PNG.png",
    content: [
      {
        body: "Companion Care provides support for your loved ones who are elderly, disabled, or in need of assistance by connecting them with qualified, highly-vetted college students (Companions). Companions provide transportation, guidance, caregiver respite, emotional support, and help with household chores.",
      },
      {
        subheader: "Call us:",
        buttons: [
          {
            name: "800-348-7951",
            value: "800-348-7951",
            format: ButtonFormat.Call_Number,
            style: ButtonStyle.Contained,
          },
        ],
      },
    ],
  },
};

const eFamilyCare: BenefitTemplate = {
  name: "eFamilyCare",
  active: true,
  id: Benefits.eFamilyCare,
  label: "Caregiver Support",
  ctaLabel: "View More",
  template: TemplateStyle.Text_Template,
  summaryImage:
    "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/eFamilyCare/efamcarelogo.png",
  customData: {
    card: [],
    logo: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/eFamilyCare/efamcarelogo.png",
    content: [
      {
        body: "Caregiver Support is an online service that connects caregivers with expert advisors dedicated to helping them care for their loved ones. Advisors are available 24/7 via mobile and video app and can help you build a personalized support plan.",
      },
      {
        buttons: [
          {
            name: "Go to eFamilyCare Portal",
            value: "https://web.employeefamilycare.com/login",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Text,
          },
        ],
      },
      {
        body: "or",
        buttons: [
          {
            name: "iPhone App",
            value:
              "https://apps.apple.com/us/app/employee-efamilycare/id1587109806",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Text,
          },
          {
            name: "Android App",
            value:
              "https://play.google.com/store/apps/details?id=org.efc.chi&hl=en_US&gl=US",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Text,
          },
        ],
      },
    ],
  },
};

const PharmacyBenefits: BenefitTemplate = {
  name: "Prescription Benefit",
  active: true,
  id: Benefits.PharmacyBenefits,
  label: "Prescription Benefit",
  ctaLabel: "View More",
  template: TemplateStyle.Hero_Template,
  customData: {
    card: [
      {
        style: CardStyle.Right_Align,
        hero: true,
        hasOverlay: true,
        groupSuffix: "*BCP00PLS ",
        src: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/pharmacyBenefits/acute_rx_card_base.jpg",
      },
    ],
    content: [
      {
        body: "Present this card at your pharmacy when picking up medications on the covered prescription list.",
        buttons: [
          {
            name: "View Covered Prescription List",
            value: "https://acutecarerx.com/recuro",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Contained,
          },
          {
            name: "Add a Dependent",
            value: "https://acutecarerx.com/recuro-dependents",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Contained,
          },
        ],
      },
    ],
  },
};

const OnDemandCounseling3: BenefitTemplate = {
  id: Benefits.OnDemandCounseling3,
  active: true,
  name: "On-Demand Counseling: Three Visit",
  label: "On-Demand Counseling",
  ctaLabel: "View More",
  template: TemplateStyle.Text_Template,
  summaryImage:
    "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/supportlinc/supportlinc-eap-color.png",
  customData: {
    card: [],
    logo: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/supportlinc/supportlinc-eap-color.png",
    content: [
      {
        body: "On-Demand Counseling provides support navigating personal, family, and work-related challenges, such as emotional wellness and financial planning. Whether you’re a new parent, caregiver, or selling your home, there is an expert on staff to work with you and answer your questions. Personal information is treated with the utmost confidentiality.",
      },
      {
        subheader: "Call us:",
        buttons: [
          {
            name: "877-638-5302",
            value: "877-638-5302",
            format: ButtonFormat.Call_Number,
            style: ButtonStyle.Contained,
          },
        ],
      },
      {
        body: "Please have your group code ready to access your benefits.",
      },
      {
        subheader: "Group Code: wellnesseap",
        body: "or",
        buttons: [
          {
            name: "Go to SupportLinc Portal",
            value: "https://wellnesseap.mysupportportal.com/",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Text,
          },
        ],
      },
    ],
  },
};

const OnDemandCounselingStudent3: BenefitTemplate = {
  id: Benefits.OnDemandCounselingStudent3,
  active: true,
  name: "On-Demand Counseling: Student Three Visit",
  label: "On-Demand Counseling",
  ctaLabel: "View More",
  template: TemplateStyle.Text_Template,
  summaryImage:
    "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/supportlinc/StudentLinc.png",
  customData: {
    card: [],
    logo: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/supportlinc/StudentLinc.png",
    content: [
      {
        body: "On-Demand Counseling provides confidential and professional counseling for behavioral health concerns that may affect students personally or impact performance at school.",
      },
      {
        subheader: "Call us:",
        buttons: [
          {
            name: "877-638-5305",
            value: "877-638-5305",
            format: ButtonFormat.Call_Number,
            style: ButtonStyle.Contained,
          },
        ],
      },
      {
        body: "Please have your group code ready to access your benefits.",
      },
      {
        subheader: "Group Code: wellnessplanstudent",
        body: "or",
        buttons: [
          {
            name: "Go to SupportLinc Portal",
            value: "https://wellnessplanstudent.mysupportportal.com/",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Text,
          },
        ],
      },
    ],
  },
};

const OnDemandCounseling5: BenefitTemplate = {
  id: Benefits.OnDemandCounseling5,
  active: true,
  name: "On-Demand Counseling: Five Visit",
  label: "On-Demand Counseling",
  ctaLabel: "View More",
  template: TemplateStyle.Text_Template,
  summaryImage:
    "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/supportlinc/supportlinc-eap-color.png",
  customData: {
    card: [],
    logo: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/supportlinc/supportlinc-eap-color.png",
    content: [
      {
        body: "On-Demand Counseling provides support navigating personal, family, and work-related challenges, such as emotional wellness and financial planning. Whether you’re a new parent, caregiver, or selling your home, there is an expert on staff to work with you and answer your questions. Personal information is treated with the utmost confidentiality.",
      },
      {
        subheader: "Call us:",
        buttons: [
          {
            name: "877-638-5303",
            value: "877-638-5303",
            format: ButtonFormat.Call_Number,
            style: ButtonStyle.Contained,
          },
        ],
      },
      {
        body: "Please have your group code ready to access your benefits.",
      },
      {
        subheader: "Group Code: wellnessplanenhanced",
        body: "or",
        buttons: [
          {
            name: "Go to SupportLinc Portal",
            value: "https://wellnessplanenhanced.mysupportportal.com/",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Text,
          },
        ],
      },
    ],
  },
};

const OnDemandCounseling8: BenefitTemplate = {
  id: Benefits.OnDemandCounseling8,
  active: true,
  name: "On-Demand Counseling: Eight Visit",
  label: "On-Demand Counseling",
  ctaLabel: "View More",
  template: TemplateStyle.Text_Template,
  summaryImage:
    "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/supportlinc/supportlinc-eap-color.png",
  customData: {
    card: [],
    logo: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/supportlinc/supportlinc-eap-color.png",
    content: [
      {
        body: "On-Demand Counseling provides support navigating personal, family, and work-related challenges, such as emotional wellness and financial planning. Whether you’re a new parent, caregiver, or selling your home, there is an expert on staff to work with you and answer your questions. Personal information is treated with the utmost confidentiality.",
      },
      {
        subheader: "Call us:",
        buttons: [
          {
            name: "877-638-5304",
            value: "877-638-5304",
            format: ButtonFormat.Call_Number,
            style: ButtonStyle.Contained,
          },
        ],
      },
      {
        body: "Please have your group code ready to access your benefits.",
      },
      {
        subheader: "Group Code: wellnessplanplus",
        body: "or",
        buttons: [
          {
            name: "Go to SupportLinc Portal",
            value: "https://wellnessplanplus.mysupportportal.com/",
            format: ButtonFormat.Navigate_Link,
            style: ButtonStyle.Text,
          },
        ],
      },
    ],
  },
};

const HealthAdvocacy: BenefitTemplate = {
  id: Benefits.HealthAdvocate,
  active: true,
  name: "Health Advocacy",
  label: "Health Advocacy",
  ctaLabel: "View More",
  template: TemplateStyle.Text_Template,
  summaryImage:
    "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/HealthAdvocateLogoHoriz.png",
  customData: {
    logo: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/HealthAdvocateLogoHoriz.png",
    card: [],
    content: [
      {
        body: "Having trouble navigating your health options, finding and scheduling with the right doctor, transferring medical records, or negotiating a healthcare bill? Health Advocates work with healthcare providers, insurance plans and other health-related organizations to resolve problems and find the answers you need.",
      },
      {
        subheader: "Call us:",
        buttons: [
          {
            name: "866-695-8622",
            value: "866-695-8622",
            format: ButtonFormat.Call_Number,
            style: ButtonStyle.Contained,
          },
        ],
      },
      {
        body: "or",
        buttons: [
          {
            name: "Go to HealthAdvocate Portal",
            value: "health_advocate_sso",
            format: ButtonFormat.Other,
            style: ButtonStyle.Text,
          },
        ],
      },
    ],
  },
};

const BenefitCard: BenefitTemplate = {
  id: Benefits.SampleCard,
  active: true,
  name: "Benefit Card",
  label: "Your Benefits",
  ctaLabel: "View More",
  template: TemplateStyle.Card_Template,
  summaryImage: "",
  customData: {
    card: [
      {
        style: CardStyle.Left_Align,
        hero: false,
        hasOverlay: true,
        header: "Member ID Card - Front",
        groupSuffix: "",
        src: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/ARMEssential.png",
      },
      {
        style: CardStyle.No_Text,
        hero: false,
        hasOverlay: false,
        header: "Member ID Card - Back",
        src: "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/ARMBACK.png",
      },
    ],
    content: [
      {
        buttons: [
          {
            name: "eBenefits Essential Plan",
            value:
              "https://recurohealthstaticfiles.blob.core.windows.net/product-documents-assets/eBenefits - HDHPEssential Plan, 032022.pdf",
            format: ButtonFormat.Open_Pdf,
            style: ButtonStyle.Contained,
          },
        ],
      },
    ],
  },
};

const Flume: Product = {
  name: "Flume",
  id: "flume",
  eligibilityId: "flume",
  benefits: [Benefits.OnDemandCounseling8],
};

const TotalHealth: Product = {
  name: "TotalHealth",
  id: "totalhealth",
  eligibilityId: "totalhealth",
  benefits: [
    Benefits.OnDemandCounseling8,
    Benefits.Brightline,
    Benefits.HealthAdvocate,
    Benefits.eFamilyCare,
    Benefits.Papa,
    Benefits.PharmacyBenefits,
  ],
};

const allBenefits = [
  Brightline,
  Papa,
  eFamilyCare,
  PharmacyBenefits,
  OnDemandCounseling3,
  OnDemandCounseling5,
  OnDemandCounseling8,
  OnDemandCounselingStudent3,
  HealthAdvocacy,
  BenefitCard,
];

const allProducts = [Flume, TotalHealth];

export {
  allBenefits,
  allProducts,
  BenefitCard,
  Brightline,
  eFamilyCare,
  Flume,
  HealthAdvocacy,
  OnDemandCounseling3,
  OnDemandCounseling5,
  OnDemandCounseling8,
  OnDemandCounselingStudent3,
  Papa,
  PharmacyBenefits,
  TotalHealth,
};
