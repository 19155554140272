import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import { group } from "../../common/resources/clientManagement.mock";
import {
  ClientOfferingCode,
  ClientTemplate,
  Coupon,
  GetAllConsultationsData,
  Group,
  Payer,
  ProductOffering,
  SelectedProductOfferings,
  SnackBarTemplate,
} from "../../common/resources/clientManagement.types";
import { clientManagementApi } from "./clientManagement.service";

type ResponseStatus = "idle" | "loading" | "failed" | "success";

export type ClientManagementState = {
  group: Group;
  clientList: ClientTemplate[];
  coupons: Coupon[];
  couponStatus: ResponseStatus;
  groupList: Group[];
  payers: Payer[];
  payerStatus: ResponseStatus;
  availableConsultationTypes: GetAllConsultationsData[];
  client: ClientTemplate;
  selectedGroup?: string;
  status: ResponseStatus;
  planStatus: {
    fetch: ResponseStatus;
  };
  clientStatus: {
    fetch: ResponseStatus;
  };
  groupStatus: {
    fetch: ResponseStatus;
    create: ResponseStatus;
    update: ResponseStatus;
  };
  snackBarOpen: SnackBarTemplate;
  selectedProductOfferings: SelectedProductOfferings[];
  productOfferings: ProductOffering[];
};

const initialState: ClientManagementState = {
  group: {
    groupID: "",
    groupName: "",
    isDemo: false,
    clientGroupID: "",
    groupEmailFul: true,
    groupHardCopyFul: false,
    startDate: new Date(),
    endDate: null,
    payerID: "",
    clientID: "",
    groupProductOfferings: [],
  },
  clientList: [],
  coupons: [],
  couponStatus: "idle",
  groupList: [],
  payers: [],
  payerStatus: "idle",
  availableConsultationTypes: [],
  planStatus: {
    fetch: "idle",
  },
  clientStatus: {
    fetch: "idle",
  },
  groupStatus: {
    fetch: "idle",
    create: "idle",
    update: "idle",
  },
  client: {
    clientID: "",
    clientName: "",
    startDate: "",
    endDate: "",
    active: true,
    TotalGroups: 0,
    TotalPlans: 0,
    PrimaryMemberCount: 0,
    OnlyMemberIdRequired: false,
    GenerateUtilizationReports: false,
    clientProductOfferings: [],
  },
  selectedProductOfferings: [],
  productOfferings: [],
  status: "idle",
  snackBarOpen: {
    open: false,
    severity: "success",
    title: null,
    subTitle: null,
  },
};

export const clientManagementSlice = createSlice({
  name: "clientManagement",
  initialState,
  reducers: {
    resetClientManagementState: (state) => {
      return {
        ...initialState,
        snackBarOpen: state.snackBarOpen,
        clientList: state.clientList,
      };
    },
    resetGroupStatus: (state) => {
      state.groupStatus = initialState.groupStatus;
    },
    setClientInformation: (state, action: PayloadAction<ClientTemplate>) => {
      state.client = action.payload;
    },
    setClientID: (state, action) => {
      state.client.clientID = action.payload;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    },
    resetGroupState: (state) => {
      state.group = initialState.group;
    },
    setSnackBarOpen: (state, action: PayloadAction<SnackBarTemplate>) => {
      state.snackBarOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        clientManagementApi.endpoints.assignPO.matchFulfilled,
        (state, response: any) => {
          //Work around to utilize existing endpoint
          const payload = response.payload.data.Data as any;
          const newState = {
            ...state.group,
            groupProductOfferings: [
              {
                ClientProductOfferingID: payload[0].ProductOfferingID,
                CustomGroupOfferingID: payload[1]
                  ? payload[1].ProductOfferingID
                  : "",
                //not received on the response or displayed on the UI
                AllowDependentManagement: true,
              },
              ...(state.group.groupProductOfferings || []),
            ],
          };
          state.group = newState;
          if (payload[1] === undefined) {
            state.snackBarOpen = {
              open: true,
              severity: "success",
              title: "clientManagement.createSOC.successTitle",
              subTitle: "clientManagement.createSOC.successAlt",
            };
          } else {
            state.snackBarOpen = {
              open: true,
              severity: "success",
              title: "clientManagement.createSOC.successTitle",
              subTitle: "clientManagement.createSOC.success",
            };
          }
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.assignPO.matchRejected,
        (state) => {
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.assignPO.errorTitle",
            subTitle: "clientManagement.assignPO.error",
          };
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.createSOC.matchFulfilled,
        (state, response: any) => {
          const payload = response.payload.data.Data as ClientOfferingCode;
          const newState = {
            ...state.client,
            clientProductOfferings: [
              {
                ClientProductOfferingID: payload.ClientProductOfferingID,
                CustomClientProductOfferingID:
                  payload.CustomClientProductOfferingID,
              },
              ...(state.client.clientProductOfferings || []),
            ],
          };
          state.client = newState;
          state.snackBarOpen = {
            open: true,
            severity: "success",
            title: "clientManagement.createSOC.successTitle",
            subTitle: "clientManagement.createSOC.success",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.createSOC.matchRejected,
        (state) => {
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.createSOC.errorTitle",
            subTitle: "clientManagement.createSOC.error",
          };
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.createNewGroup.matchFulfilled,
        (state) => {
          state.groupStatus.create = "success";
          state.snackBarOpen = {
            open: true,
            severity: "success",
            title: "clientManagement.newGroup.successTitle",
            subTitle: "clientManagement.newGroup.success",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.createNewGroup.matchRejected,
        (state) => {
          state.groupStatus.create = "failed";
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.newGroup.errorTitle",
            subTitle: "clientManagement.newGroup.error",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.createNewGroup.matchPending,
        (state) => {
          state.groupStatus.create = "loading";
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.createClient.matchFulfilled,
        (state, action) => {
          const { clientID, clientName } = action.payload.data.Data;
          state.client = {
            ...state.client,
            clientID: clientID.toString(),
            clientName,
          };
          state.snackBarOpen = {
            open: true,
            severity: "success",
            title: "clientManagement.newClient.successTitle",
            subTitle: "clientManagement.newClient.success",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.createClient.matchRejected,
        (state) => {
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.newClient.errorTitle",
            subTitle: "clientManagement.newClient.error",
          };
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.createPO.matchFulfilled,
        (state) => {
          state.snackBarOpen = {
            open: true,
            severity: "success",
            title: "clientManagement.createPO.successTitle",
            subTitle: "clientManagement.createPO.success",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.createPO.matchRejected,
        (state) => {
          state.status = "failed";
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.createPO.errorTitle",
            subTitle: "clientManagement.createPO.error",
          };
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.fetchAllClients.matchFulfilled,
        (state, action) => {
          state.clientList = action.payload.data;
          state.clientStatus.fetch = "success";
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllClients.matchRejected,
        (state) => {
          state.clientStatus.fetch = "failed";
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllClients.matchPending,
        (state) => {
          state.clientStatus.fetch = "loading";
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.fetchAllConsultTypes.matchFulfilled,
        (state, action) => {
          state.planStatus.fetch = "success";
          state.availableConsultationTypes = action.payload.data.Data;
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllConsultTypes.matchRejected,
        (state) => {
          state.planStatus.fetch = "failed";
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.createPO.errorTitle",
            subTitle: "clientManagement.createOffering.error",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllConsultTypes.matchPending,
        (state) => {
          state.planStatus.fetch = "loading";
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.fetchAllCoupons.matchFulfilled,
        (state, action) => {
          state.couponStatus = "success";
          state.coupons = action.payload.data.Data;
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllCoupons.matchRejected,
        (state) => {
          state.couponStatus = "failed";
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.createPO.errorTitle",
            subTitle: "clientManagement.fetchCoupons.error",
          };
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.fetchAllGroupsByClient.matchFulfilled,
        (state, action) => {
          state.groupList = action.payload;
          state.groupStatus.fetch = "success";
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllGroupsByClient.matchRejected,
        (state) => {
          state.groupStatus.fetch = "failed";
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.createPO.errorTitle",
            subTitle: "clientManagement.fetchGroupsByClient.error",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllGroupsByClient.matchPending,
        (state, action) => {
          state.groupStatus.fetch = "loading";
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.fetchAllPayers.matchFulfilled,
        (state, action) => {
          state.payers = action.payload;
          state.payerStatus = "success";
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllPayers.matchRejected,
        (state) => {
          state.payerStatus = "failed";
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.fetchPayers.errorTitle",
            subTitle: "clientManagement.fetchPayers.error",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.fetchAllPayers.matchPending,
        (state) => {
          state.payerStatus = "loading";
        }
      )

      .addMatcher(
        clientManagementApi.endpoints.updateGroup.matchFulfilled,
        (state, response) => {
          const payload: Group = response.payload as Group;
          const newState = state.groupList.map((group) => {
            return group.groupID === payload.groupID
              ? { ...group, ...payload }
              : group;
          });
          state.groupList = newState;
          state.groupStatus.update = "success";
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.updateGroup.matchRejected,
        (state) => {
          state.groupStatus.update = "failed";
          state.snackBarOpen = {
            open: true,
            severity: "error",
            title: "clientManagement.newGroup.errorTitle",
            subTitle: "clientManagement.newGroup.error",
          };
        }
      )
      .addMatcher(
        clientManagementApi.endpoints.updateGroup.matchPending,
        (state, action) => {
          state.groupStatus.update = "loading";
        }
      );
  },
});

const selectGroup = (state: RootState): Group => state.clientManagement.group;
const selectClient = (state: RootState): ClientTemplate =>
  state.clientManagement.client;
const selectCoupons = (state: RootState): Coupon[] =>
  state.clientManagement.coupons;
const selectPayers = (state: RootState): Payer[] =>
  state.clientManagement.payers;
const selectPlanStatus = (state: RootState) =>
  state.clientManagement.planStatus;
const selectGroupStatus = (state: RootState) =>
  state.clientManagement.groupStatus;
const selectClientsList = (state: RootState): ClientTemplate[] =>
  state.clientManagement.clientList;
const selectGroups = (state: RootState): Group[] => group;
const selectSnackBarOpen = (state: RootState): SnackBarTemplate =>
  state.clientManagement.snackBarOpen;
const selectClientStatus = (state: RootState) =>
  state.clientManagement.clientStatus;
const selectAvailableConsultationTypes = (
  state: RootState
): GetAllConsultationsData[] =>
  state.clientManagement.availableConsultationTypes;
const selectGroupsList = (state: RootState): Group[] =>
  state.clientManagement.groupList;
const selectGroupById = (
  state: RootState,
  id: string | undefined
): Group | undefined => {
  let ind = -1;
  if (id !== undefined) {
    ind = state.clientManagement.groupList.findIndex(
      (elem: Group) => elem.groupID === id
    );
  }
  if (ind === -1) {
    return undefined;
  }
  return state.clientManagement.groupList[ind];
};

export const {
  resetClientManagementState,
  resetGroupStatus,
  setClientInformation,
  setSnackBarOpen,
  setClientID,
  setGroup,
  resetGroupState,
} = clientManagementSlice.actions;

export default clientManagementSlice.reducer;

export {
  selectAvailableConsultationTypes,
  selectClient,
  selectClientsList,
  selectClientStatus,
  selectCoupons,
  selectGroup,
  selectGroupById,
  selectGroups,
  selectGroupsList,
  selectGroupStatus,
  selectPayers,
  selectPlanStatus,
  selectSnackBarOpen,
};
