import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { style } from "./LoadingIndicator.style";

export type InputProps = {
  isLoading: boolean;
};

export const LoadingIndicator: React.FC<InputProps> = ({ isLoading }) => {
  const { formatMessage } = useIntl();
  const loadingIndicator = formatMessage({ id: "loadingIndicator" });

  return (
    <Modal open={isLoading}>
      <Box sx={style}>
        <CircularProgress
          color="primary"
          aria-hidden="true"
        />
        <Typography
          aria-live="assertive"
          id="loading-title"
        >
          {loadingIndicator}
        </Typography>
      </Box>
    </Modal>
  );
};
