import { LanguageKeys } from "../language";

export enum AccessType {
  provider = "PROVIDER",
  patient = "PATIENT",
}

export interface IFormData {
  id: string;
  file_scheme: string;
  ingestion_protocol: string;
  opt_out: boolean;
  expiration: Date | "";
  label: string;
  workflow: string;
  contact_number: string;
  support_individual_interactions: boolean;
  contact_email: null;
  obfuscated: boolean;
  send_outreach: boolean | null;
  outbound_reported: boolean;
  expedited: boolean;
  ingestion_status: string;
  results_outreach: boolean | null;
  created_at: Date;
  modified_at: Date;
  patient_negative_results_outreach: boolean | null;
}

export type OrderGroupResponse = {
  collection: OrderGroup[];
};

export type OrderGroup = {
  approver_first_name: {
    String: string;
    Valid: boolean;
  };
  approver_last_name: {
    String: string;
    Valid: boolean;
  };
  approver_npi: {
    String: string;
    Valid: boolean;
  };
  approver_phone_number: {
    String: string;
    Valid: boolean;
  };
  completed: boolean;
  id: string;
  member_count: number;
  src_url: {
    String: string;
    Valid: boolean;
  };
  states: {
    String: string;
    Valid: boolean;
  };
  submission_date: {
    Time: string;
    Valid: boolean;
  };
  orders_sent: number;
};

export type SnackBarTemplate = {
  open: boolean;
  severity: "error" | "success" | "info" | "warning";
  title: LanguageKeys | null;
  subTitle: LanguageKeys | null;
};

export type orderGroupResponse = {
  collection: states[];
};

export type states = {
  state: string;
  patient_count: number;
  approved_patients: number;
};

export type AttestationCreationRequest = {
  client_id: string;
  states: string[];
};

export type ProgramRow = {
  program_id: string;
  program_sponsor: string;
  workflow: string;
  total_members: number;
  total_opted_in: number;
  opt_out: boolean;
  created_at: Date | null;
  expiration: Date | null;
  patient_negative_results_outreach: boolean;
  results_outreach: boolean;
  send_outreach: boolean;
  support_individual_interactions: boolean;
};

export type UpdateProviderInformation = {
  id: string; // ID FOR THE ORDER GROUP
  approver_last_name: string;
  approver_first_name: string;
  approver_npi: string;
  approver_phone_number: string;
  completed: boolean;
};

export type ProviderInformationModel = {
  approver_first_name: string;
  approver_last_name: string;
  approver_phone_number: string;
  approver_npi: string;
};

export type ProgramsResponse = {
  orders: ProgramRow[];
  total_count: number;
};

export type ProgramsRequest = {
  page: number;
  per_page: number;
  search?: string;
};

export type SubmitOrderGroupRequest = {
  client_id: string;
  attestation_id: string;
};

export type CSVDownloadRequest = {
  orderGroupId: string;
  programId: string;
};
