import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Box, Container, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { MessageDescriptor, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Item } from "../../common/components/Item/Item";
import {
  RouteDefinition,
  RouteDefinitions,
} from "../../common/resources/routes";
import { checkPageAccess } from "../../common/utils/checkPageAccess";
import { FeatureFlag } from "../../config/flags.config";
import { Login } from "../Login/Login";

const Home: React.FC = () => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();
  const auth0 = useAuth0();

  const distinctGroupIds: string[] = [];
  const displayGroups: MessageDescriptor[] = [];
  RouteDefinitions.forEach((elem) => {
    if (
      elem.tileGroup !== undefined &&
      !distinctGroupIds.includes(elem.tileGroup?.id!)
    ) {
      displayGroups.push(elem.tileGroup);
      distinctGroupIds.push(elem.tileGroup.id!);
    }
  });

  const groups: string[] = auth0.user?.ad_groups || [];

  const GetGroupData = (groupName: MessageDescriptor, groupIndex: number) => {
    const groupDefinitions: RouteDefinition[] = RouteDefinitions.filter(
      (elem) => {
        const definitionIsInGroup: boolean = elem.tileGroup != null && elem.tileGroup.id! === groupName.id;
        const hasPageAccess: boolean = checkPageAccess(elem.accessKey, groups);
        const notInDevelopment: boolean =
          FeatureFlag.featuresInDevelopment || !elem.featureFlagged;
        return (
          definitionIsInGroup &&
          notInDevelopment &&
          hasPageAccess
        );
      }
    );

    if (groupDefinitions.length === 0) {
      return <Box key={`group-container-${groupIndex}`}></Box>;
    }

    return (
      <Box key={`group-container-${groupIndex}`}>
        <Typography
          key={`group-title-${groupIndex}`}
          variant="h5"
          sx={{ textAlign: "start", marginTop: 6, marginBottom: 5 }}
        >
          {formatMessage(groupName!)}
        </Typography>
        <Grid2
          key={`grid-group-${groupIndex}`}
          container
          spacing={2}
        >
          {groupDefinitions.map((definition, index) => {
            return (
              <Grid2
                key={`grid-item-${groupIndex}-${index}`}
                xs={4}
              >
                <Item
                  onClick={() => {
                    navigate(definition.path);
                  }}
                  sx={{ flexDirection: "column", cursor: "pointer" }}
                >
                  <Typography variant="h6">
                    {formatMessage(definition.tileTitle!)}
                  </Typography>
                  <Typography variant="subtitle2">
                    {formatMessage(definition.tileDesc!)}
                  </Typography>
                </Item>
              </Grid2>
            );
          })}
        </Grid2>
      </Box>
    );
  };

  return (
    <Container>
      <Box>
        {displayGroups.map((groupName, groupIndex) =>
          GetGroupData(groupName, groupIndex)
        )}
      </Box>
    </Container>
  );
};
export default withAuthenticationRequired(Home, {
  onRedirecting: () => <Login />,
});
