import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";

import { useAppDispatch } from "../../../app/hooks";
import { updateBenefit } from "../../../slices/benefits/benefitSlice";
import {
  BenefitTemplate,
  ButtonFormat,
  ButtonStyle,
  ButtonTemplate,
  ContentTemplate,
} from "../../resources/benefits.types";
import { ButtonEntryForm } from "./ButtonEntryForm";

export const BenefitInputCard: React.FC<{
  content: ContentTemplate;
  index: number;
  selected: BenefitTemplate;
}> = ({ content, index, selected }) => {
  const dispatch = useAppDispatch();
  const addButton = () => {
    const content = selected.customData.content.map((content) => {
      return { ...content };
    });
    const buttons =
      content[index].buttons?.map((val) => {
        return { ...val };
      }) ?? [];
    content[index].buttons = [
      ...buttons,
      { style: ButtonStyle.Text, format: ButtonFormat.Navigate_Link },
    ];
    const customData = { ...selected.customData, content: content };
    dispatch(updateBenefit({ ...selected, customData: customData }));
  };

  const removeSection = () => {
    const content = selected.customData.content.map((content) => {
      return { ...content };
    });
    content.splice(index, 1);
    const customData = { ...selected.customData, content: content };
    dispatch(updateBenefit({ ...selected, customData: customData }));
  };

  return (
    <Card
      sx={{ borderRadius: "5pt", marginBottom: 4, paddingTop: 4 }}
      id={`benefit-input-${index}`}
    >
      <FormControl fullWidth>
        <TextField
          style={{
            margin: 8,
          }}
          multiline
          label="Subheader Text"
          value={content.subheader ?? ""}
          onChange={(event: { target: { value: string } }) => {
            const content = selected?.customData.content.map(
              (content: ContentTemplate) => {
                return { ...content };
              }
            );
            content[index].subheader = event.target.value;
            const customData = { ...selected.customData, content: content };
            dispatch(updateBenefit({ ...selected, customData: customData }));
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          style={{
            margin: 8,
          }}
          multiline
          label="Body Text"
          value={content.body ?? ""}
          onChange={(event) => {
            const content = selected.customData.content.map((content) => {
              return { ...content };
            });
            content[index].body = event.target.value;
            const customData = { ...selected.customData, content: content };
            dispatch(updateBenefit({ ...selected, customData: customData }));
          }}
        />
      </FormControl>
      <FormControl
        fullWidth
        sx={{ width: "95%" }}
      >
        {content.buttons && content.buttons.length > 0 && (
          <Box>
            <Typography variant="h6">Buttons</Typography>
            {content.buttons.map((elem, buttonIndex) => {
              return (
                <Box key={`content-${index}-button-${buttonIndex}`}>
                  <ButtonEntryForm
                    button={elem}
                    selected={selected}
                    buttonIndex={buttonIndex}
                    bodyIndex={index}
                    removeButton={() => {
                      const content = selected.customData.content.map(
                        (content) => {
                          return { ...content };
                        }
                      );
                      const buttons =
                        content[index].buttons?.map((val) => {
                          return { ...val };
                        }) ?? [];
                      buttons.splice(buttonIndex, 1);
                      content[index].buttons = buttons;
                      const customData = {
                        ...selected.customData,
                        content: content,
                      };
                      dispatch(
                        updateBenefit({ ...selected, customData: customData })
                      );
                    }}
                    updateButtonValues={(button: ButtonTemplate) => {
                      if (content.buttons) {
                        const content = selected.customData.content.map(
                          (content) => {
                            return { ...content };
                          }
                        );
                        const buttons =
                          content[index].buttons?.map((val) => {
                            return { ...val };
                          }) ?? [];
                        buttons[buttonIndex] = button;
                        content[index].buttons = buttons;
                        const customData = {
                          ...selected.customData,
                          content: content,
                        };
                        dispatch(
                          updateBenefit({ ...selected, customData: customData })
                        );
                      }
                    }}
                  />
                  {content.buttons && content.buttons?.length > 0 && (
                    <Divider sx={{ marginBottom: 4 }} />
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </FormControl>
      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
      >
        <Button
          variant="text"
          color="error"
          onClick={() => {
            removeSection();
          }}
        >
          Remove
        </Button>
        <Button
          variant="text"
          onClick={() => {
            addButton();
          }}
        >
          Add Button
        </Button>
      </Box>
    </Card>
  );
};
