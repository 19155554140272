import { Button, Card, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import ErrorMessage from "./ErrorMessage";
import { withRouterError } from "./withRouterError";

export type RootErrorProps = {
  error?: Error;
};
export const RootError: React.FC<RootErrorProps> = ({ error }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Container
        component="main"
        id="provider-main"
        maxWidth="sm"
      >
        <Typography
          component="h1"
          variant="h5"
        >
          {formatMessage({ id: "systemError.header" })}
        </Typography>

        <Card sx={{ p: 2 }}>
          <Stack spacing={4}>
            <ErrorMessage error={error} />
            <Typography>{formatMessage({ id: "systemError.body" })}</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ alignSelf: "center" }}
              onClick={() => (window.location.href = "/")}
            >
              {formatMessage({ id: "systemError.button.refresh" })}
            </Button>
          </Stack>
        </Card>
      </Container>
    </>
  );
};

export default withRouterError(RootError);
