import {
  Box,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import parseHTML from "html-react-parser";
import { useEffect, useState } from "react";

import { defaultQuestionnaires } from "../../common/resources/questionnaires.mock";

const Questionnaire: React.FC = () => {
  const [response, setResponse] = useState<any>({});
  const [referenceQuestionnaire, setReference] = useState<any>({});
  const [mapped, setMapped] = useState<any[]>([]);

  useEffect(() => {
    const questionnaireId = response.questionnaire;
    const questionnaire = defaultQuestionnaires[questionnaireId];
    if (questionnaire) {
      const answers: any[] = response.item;
      const newMapped: any[] = [];
      if (questionnaire.item) {
        for (const question of questionnaire.item) {
          const subAnswers: any = answers
            .find((answer: any) => {
              return answer?.linkId === question.id;
            })
            ?.answer?.map((elem: any) => elem.value);
          const mappedAnswer = {
            question: question.text,
            answer: subAnswers?.map((subAnswer: any) => subAnswer.string),
          };
          newMapped.push(mappedAnswer);
        }
      }
      setMapped(newMapped);
      setReference(questionnaire);
    } else {
      setMapped([]);
    }
  }, [referenceQuestionnaire.id, response]);

  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Box width={"50vw"}>
          <FormControl fullWidth>
            <TextField
              sx={{ marginTop: 4 }}
              label="Response"
              multiline
              maxRows={10}
              onChange={(event) => {
                setResponse(JSON.parse(event.target.value));
              }}
            />
          </FormControl>
        </Box>
        <Box
          width={"50vw"}
          sx={{ textAlign: "start" }}
        >
          <Typography variant="h4">{referenceQuestionnaire?.name}</Typography>
          <br />
          <Typography variant="h5">Scores</Typography>
          {response.score &&
            Object.entries(response?.score).map(
              (resp: [string, any], index) => {
                return (
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Typography key={`score-value-${index}`}>
                      {`${resp[0]}: ${resp[1].toString()}`}
                    </Typography>
                  </Box>
                );
              }
            )}
          <br />
          {mapped.map((elem, index) => {
            if (elem.answer) {
              return (
                <Box key={`question-${index}`}>
                  <Typography
                    variant="h6"
                    sx={{ textAlign: "start" }}
                  >
                    {parseHTML(elem.question)}
                  </Typography>
                  {elem.answer.map((answer: string, aIndex: number) => (
                    <Box key={`answer-${aIndex}`}>
                      <Typography variant="body1">
                        {parseHTML(answer)}
                      </Typography>
                      <br />
                    </Box>
                  ))}
                </Box>
              );
            }
            return <></>;
          })}
        </Box>
      </Box>
    </Container>
  );
};

export default Questionnaire;
