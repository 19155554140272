import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { RootState } from "../../app/store";
import { MergeMember } from "../../common/resources/memberActions.types";

export const memberApi = createApi({
  reducerPath: "memberApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BENEFITS_URL}/memberActions/api/member/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Content-Type", "application/json");
      const state = getState() as RootState;
      if (state.user.accessToken) {
        headers.append("Authorization", `Bearer ${state.user.accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchMember: builder.query<object, { memberID: number }>({
      query: (body) => ({
        url: `${body.memberID}`,
        method: "GET",
      }),
    }),
    mergeMember: builder.query<object, MergeMember>({
      query: (body) => ({
        url: "merge",
        method: "PATCH",
        body: body,
      }),
    }),
  }),
});

export const { useLazyFetchMemberQuery, useLazyMergeMemberQuery } = memberApi;
