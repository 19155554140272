import { Box, Breadcrumbs, Link } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { resetState } from "../../slices/bulkOrders/bulkOrders";
import ProgramDetails from "../ProgramDetails/ProgramDetails";
import { ProgramTable } from "../ProgramDetails/ProgramTable";

export const BulkOrders: React.FC = () => {
  const [breadCrumb, setBreadCrumb] = useState<string[]>(["Programs"]);
  const dispatch = useDispatch();

  const nav = (value: string) => {
    setBreadCrumb([...breadCrumb, value]);
  };

  const display =
    breadCrumb.length === 1 ? <ProgramTable nav={nav} /> : <ProgramDetails />;

  return (
    <Box>
      <Breadcrumbs sx={{ p: 2 }}>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(resetState());
            setBreadCrumb(["Programs"]);
          }}
        >
          {breadCrumb[0]}
        </Link>
        <Link sx={{ cursor: "pointer" }}>{breadCrumb[1]}</Link>
      </Breadcrumbs>
      {display}
    </Box>
  );
};
