import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { FormikProps } from "formik";
import { useIntl } from "react-intl";

import { IFormData } from "../../common/resources/bulkOrders.types";

const CommunicationsConfiguration: React.FC<{
  formik: FormikProps<IFormData>;
  verify: boolean;
  setVerify: (value: boolean) => void;
}> = ({ formik, verify, setVerify }) => {
  const { formatMessage } = useIntl();

  const handleSendOutreach = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean | null
  ) => {
    formik.setFieldValue("send_outreach", value);
  };

  const handleNegativeSharing = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean | null
  ) => {
    formik.setFieldValue("patient_negative_results_outreach", value);
  };

  const handleSharing = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: boolean | null
  ) => {
    formik.setFieldValue("results_outreach", value);
  };

  return (
    <Box>
      <Grid
        item
        sx={{ margin: 2 }}
      >
        <Typography
          sx={{ marginY: 1 }}
          variant="subtitle1"
        >
          {formatMessage({
            id: "bulkOrders.programCreation.communicationsConfigurations",
          })}
        </Typography>
        <Typography
          sx={{ marginY: 1 }}
          variant="body1"
        >
          {formatMessage({ id: "bulkOrders.programCreation.selectSettings" })}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{ margin: 2 }}
      >
        <Grid item>
          <Typography variant="caption">
            {formatMessage({
              id: "bulkOrders.programCreation.initialOutreach",
            })}
          </Typography>
        </Grid>
        <ToggleButtonGroup
          value={formik.values.send_outreach}
          exclusive
          onChange={handleSendOutreach}
        >
          <ToggleButton
            value={false}
            name="outreach"
          >
            {formatMessage({ id: "bulkOrders.programCreation.none" })}
          </ToggleButton>
          <ToggleButton
            value
            name="outreach"
          >
            {formatMessage({ id: "bulkOrders.programCreation.mail" })}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid
        item
        sx={{ margin: 2 }}
      >
        <Grid item>
          <Typography variant="caption">
            {formatMessage({
              id: "bulkOrders.programCreation.negativeResults",
            })}
          </Typography>
        </Grid>
        <ToggleButtonGroup
          value={formik.values.patient_negative_results_outreach}
          exclusive
          onChange={handleNegativeSharing}
        >
          <ToggleButton
            value={false}
            name="negativeSharing"
          >
            {formatMessage({ id: "bulkOrders.programCreation.none" })}
          </ToggleButton>
          <ToggleButton
            value
            name="negativeSharing"
          >
            {formatMessage({ id: "bulkOrders.programCreation.mail" })}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid
        item
        sx={{ margin: 2 }}
      >
        <Grid item>
          <Typography variant="caption">
            {formatMessage({ id: "bulkOrders.programCreation.sharing" })}
          </Typography>
        </Grid>
        <ToggleButtonGroup
          value={formik.values.results_outreach}
          exclusive
          onChange={handleSharing}
        >
          <ToggleButton
            value={false}
            name="sharing"
          >
            {formatMessage({ id: "bulkOrders.programCreation.none" })}
          </ToggleButton>
          <ToggleButton
            value
            name="sharing"
          >
            {formatMessage({ id: "bulkOrders.programCreation.mail" })}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid
        container
        sx={{ margin: 2, textTransform: "none" }}
      >
        <Grid container>
          <Typography
            variant="subtitle1"
            sx={{ marginY: 1 }}
          >
            {formatMessage({ id: "bulkOrders.programCreation.review" })}
          </Typography>
        </Grid>
        <FormControlLabel
          name="verify"
          value={verify}
          onClick={() => setVerify(!verify)}
          control={<Checkbox checked={verify} />}
          label={formatMessage({ id: "bulkOrders.programCreation.verify" })}
        />
      </Grid>
    </Box>
  );
};

export default CommunicationsConfiguration;
