import {
  AppBar,
  Grid,
  SxProps,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import RecuroLogo from "../../assets/recuro-logo-horizontal-full.svg";
import LogoutButton from "../LogOutButton/LogOutButton";

export type HeaderProps = {
  sx?: SxProps<Theme>;
  isCloseVisible?: boolean;
  title?: string;
};

export const Header: React.FC<HeaderProps> = ({ title }) => {
  const { formatMessage } = useIntl();

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Grid
        direction="column"
        width="100%"
        container
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={4}
          sm={2}
          m={2}
        >
          <Link to="/">
            <img
              src={RecuroLogo}
              alt={formatMessage({ id: "logoAlt" })}
              style={{
                objectFit: "contain",
                height: 40,
                marginLeft: 10,
              }}
            />
          </Link>
        </Grid>
        <Grid
          item
          xs={5}
          sm={6}
        >
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid
          sx={{ position: "fixed", right: 50 }}
          item
          xs={5}
          sm={6}
        >
          <Toolbar>
            <LogoutButton />
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
};
