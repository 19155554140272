import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import { LanguageKeys } from "../../common/language";
import { members } from "../../common/resources/memberActions.mock";
import {
  FILTERED_FIELDS,
  MemberMergeBody,
  MemberTemplate,
  Status,
} from "../../common/resources/memberActions.types";
import { memberApi } from "./memberMerge.service";

type MemberSnackBar = {
  message: LanguageKeys | null;
  severity: "success" | "error";
};

export type MemberState = {
  membersList: MemberTemplate[];
  status: "idle" | "loading" | "failed";
  snackBar: MemberSnackBar;
  getMemberSuccess: boolean;
};

const initialState: MemberState = {
  membersList: [],
  status: "idle",
  getMemberSuccess: false,
  snackBar: {
    message: null,
    severity: "error",
  },
};

export const memberMergeSlice = createSlice({
  name: "memberActions",
  initialState,
  reducers: {
    addToMembersList: (state, action: PayloadAction<{ memberId: number }>) => {
      const { memberId } = action.payload;
      const updatedMembersList = [...state.membersList];
      const checkDuplicate = state.membersList.find(
        (member) => member.memberID === memberId
      );
      if (!checkDuplicate) {
        const newMember = members.find(
          (member) => memberId === member.memberID
        );
        if (newMember) {
          const addMember = { ...newMember, status: Status.doNothing };
          updatedMembersList.push(addMember);
          state.snackBar.message = null;
        } else {
          state.snackBar.message = "memberActions.snackBar.notFound";
          state.snackBar.severity = "error";
        }
        state.membersList = updatedMembersList;
      } else {
        state.snackBar.message = "memberActions.snackBar.duplicate";
        state.snackBar.severity = "error";
      }
    },
    removeFromMembersList: (state, action: PayloadAction<number>) => {
      const updatedMembersList = [...state.membersList];
      const toDeleteInd = state.membersList.findIndex(
        (member: MemberTemplate) => {
          return member.memberID === action.payload;
        }
      );
      if (toDeleteInd >= 0) {
        updatedMembersList.splice(toDeleteInd, 1);
      }
      state.membersList = updatedMembersList;
    },
    updateMemberStatus: (
      state,
      action: PayloadAction<{ memberID: number; status: Status }>
    ) => {
      const memberIndex = state.membersList.findIndex(
        (member) => member.memberID === action.payload.memberID
      );
      const membersList = [...state.membersList];
      if (memberIndex >= 0) {
        membersList[memberIndex].status = action.payload.status;
      }
      state.membersList = membersList;
    },
    setSnackBarMessageBlock: (state) => {
      state.snackBar.message = "memberActions.snackBar.block";
      state.snackBar.severity = "error";
    },
    setSnackBarNoMemberID: (state) => {
      state.snackBar.message = "memberActions.snackBar.memberIDRequired";
      state.snackBar.severity = "error";
    },
    resetSnackBarMessage: (state) => {
      state.snackBar.message = null;
      state.snackBar.severity = "error";
      state.getMemberSuccess = false;
    },
    resetInitialState: (state) => {
      state.membersList = initialState.membersList;
      state.snackBar.message = null;
      state.snackBar.severity = "error";
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(memberApi.endpoints.fetchMember.matchPending, (state) => {
        state.status = "loading";
      })
      .addMatcher(
        memberApi.endpoints.fetchMember.matchFulfilled,
        (state, action) => {
          state.status = "idle";
          const payload = action.payload as any;
          if (payload === undefined) {
            state.snackBar.message = "memberActions.snackBar.notFound";
            state.snackBar.severity = "error";
          } else {
            const response: MemberTemplate = payload?.data;
            const index = state.membersList.findIndex(
              (value) => value.memberID === response.memberID
            );
            if (index >= 0) {
              state.snackBar.message = "memberActions.snackBar.duplicate";
              state.snackBar.severity = "error";
            } else {
              state.snackBar.message = "memberActions.snackBar.memberSuccess";
              state.snackBar.severity = "success";
              state.membersList.push(response);
              state.getMemberSuccess = true;
            }
          }
        }
      )
      .addMatcher(memberApi.endpoints.fetchMember.matchRejected, (state) => {
        state.status = "failed";
        state.snackBar.message = "memberActions.snackBar.memberNotFound";
        state.snackBar.severity = "error";
      })
      .addMatcher(memberApi.endpoints.mergeMember.matchFulfilled, (state) => {
        state.membersList = initialState.membersList;
        state.snackBar.message = "memberActions.snackBar.mergeSuccess";
        state.snackBar.severity = "success";
        state.status = initialState.status;
      })
      .addMatcher(memberApi.endpoints.mergeMember.matchRejected, (state) => {
        state.status = "failed";
        state.snackBar.message = "memberActions.snackBar.mergeFail";
        state.snackBar.severity = "error";
      });
  },
});

export const memberToKeep = (state: RootState): MemberTemplate | undefined =>
  state.memberActions.membersList.find(
    (member: MemberTemplate) => member.status === Status.keep
  );
export const membersList = (state: RootState): MemberTemplate[] =>
  state.memberActions.membersList;
export const selectSnackBarMessage = (state: RootState): MemberSnackBar =>
  state.memberActions.snackBar;

export const getMemberSuccess = (state: RootState): boolean =>
  state.memberActions.getMemberSuccess;
export const deleteMembers = (state: RootState): MemberMergeBody[] =>
  state.memberActions.membersList
    .filter(
      (memberTemplate: MemberTemplate) =>
        memberTemplate.status === Status.delete
    )
    .map((member: MemberTemplate) => {
      const mappedObject: MemberMergeBody = { memberID: member.memberID };
      return mappedObject;
    });
export const selectUnmatchedFieldData = (state: RootState): any[] => {
  const keepMember = state.memberActions.membersList.find(
    (member: MemberTemplate) => member.status === Status.keep
  );
  let deleteMembers: any[] = [];
  const unmatchedKeys: string[] = [];

  if (keepMember) {
    for (const member of state.memberActions.membersList) {
      if (member.status === Status.delete) {
        for (const key of Object.keys(member)) {
          const k = key as keyof MemberTemplate;
          if (
            member[k]?.toString().toLowerCase() !==
              keepMember[k]?.toString().toLowerCase() &&
            !FILTERED_FIELDS.includes(key)
          ) {
            unmatchedKeys.push(key as string);
          }
        }
      }
    }

    deleteMembers = state.memberActions.membersList
      .filter(
        (memberTemplate: MemberTemplate) =>
          memberTemplate.status === Status.delete
      )
      .map((member: MemberTemplate) => {
        const mappedObject: any = {};
        for (const key of unmatchedKeys) {
          const k = key as keyof MemberTemplate;
          mappedObject[key] = member[k];
        }
        return mappedObject;
      });
  }

  return deleteMembers;
};

export const {
  addToMembersList,
  removeFromMembersList,
  updateMemberStatus,
  resetSnackBarMessage,
  resetInitialState,
  setSnackBarMessageBlock,
  setSnackBarNoMemberID,
} = memberMergeSlice.actions;

export default memberMergeSlice.reducer;
