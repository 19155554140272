import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";

import { states } from "../../common/resources/bulkOrders.types";
import { theme } from "../../common/theme/theme";
import { useCreateOrderGroupMutation } from "../../slices/bulkOrders/bulkOrders.service";

interface OrderGroupModalProps {
  open: boolean;
  onClose: () => void;
  stateList: states[];
  orderGroupStates: string[];
  clientID: string;
}

export const OrderGroupModal = ({
  stateList,
  orderGroupStates,
  open,
  onClose,
  clientID,
}: OrderGroupModalProps) => {
  const { formatMessage } = useIntl();
  const [createOrderGroup] = useCreateOrderGroupMutation();
  const [selectedStates, setSelectedStates] = useState<states[]>([]);

  return (
    <Dialog open={open}>
      <DialogTitle
        variant="h5"
        sx={{ textAlign: "left" }}
      >
        {formatMessage({
          id: "bulkOrders.createOrderGroup.title",
        })}
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          sx={{ textAlign: "left", marginY: 2 }}
        >
          {formatMessage({
            id: "bulkOrders.createOrderGroup.desc",
          })}
        </Typography>
        <FormControl fullWidth>
          <InputLabel>
            {formatMessage({
              id: "bulkOrders.createOrderGroup.stateSelect",
            })}
          </InputLabel>
          <Select
            input={
              <OutlinedInput
                label={formatMessage({
                  id: "bulkOrders.createOrderGroup.stateSelect",
                })}
              />
            }
            displayEmpty
            fullWidth
            multiple
            value={selectedStates}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {(selected as states[]).map((state) => (
                  <Chip
                    key={stateList.indexOf(state)}
                    label={[state.state, " (", state.approved_patients, ")"]}
                    sx={{
                      margin: 0.5,
                      backgroundColor: theme.palette.primary.light,
                      color: theme.palette.primary.dark,
                      fontWeight: "bold",
                    }}
                  />
                ))}
              </Box>
            )}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                  width: 250,
                },
              },
            }}
          >
            {stateList.map((state) =>
              state.approved_patients === 0 ? null : (
                <MenuItem
                  key={stateList.indexOf(state)}
                  value={stateList.indexOf(state)}
                  disabled={orderGroupStates.includes(state.state)}
                  onClick={() => {
                    if (selectedStates.includes(state)) {
                      setSelectedStates(
                        selectedStates.filter(
                          (selectedState) => selectedState !== state
                        )
                      );
                    } else {
                      setSelectedStates([...selectedStates, state]);
                    }
                  }}
                >
                  <Checkbox checked={selectedStates.includes(state)} />
                  {state.state}
                  <Chip
                    label={state.approved_patients + " Members"}
                    sx={{
                      backgroundColor: orderGroupStates.includes(state.state)
                        ? theme.palette.success.main
                        : theme.palette.primary.light,
                      color: theme.palette.primary.dark,
                      marginX: 2,
                    }}
                  />
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ margin: 2 }}>
        <Button
          onClick={() => {
            onClose();
            setSelectedStates([]);
          }}
          color="primary"
        >
          {formatMessage({
            id: "ui.button.cancel",
          })}
        </Button>
        <Button
          onClick={async () => {
            await createOrderGroup({
              client_id: clientID,
              states: selectedStates.map((state) => state.state),
            });
            onClose();
            setSelectedStates([]);
          }}
          color="primary"
          variant="contained"
        >
          {formatMessage({
            id: "bulkOrders.createOrderGroup.save",
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
