import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { LanguageKeys } from "../../common/language";
import {
  Group,
  PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS,
  ProductOffering,
} from "../../common/resources/clientManagement.types";

export type PlanViewDialogProps = {
  group: Group;
  open: boolean;
  onClose: () => void;
  onSelect: () => void;
  planData: ProductOffering | undefined;
};

export type TableRowPlanViewDialog = {
  visitType: string;
  consultFee?: string | null;
  groupFee: string;
  isVisible: boolean;
};

const PlanViewDialog: React.FC<PlanViewDialogProps> = ({
  group,
  open,
  onClose,
  onSelect,
  planData: possiblePlanData,
}) => {
  const { formatMessage } = useIntl();
  const planData = possiblePlanData ?? ({} as ProductOffering);

  const getIsVisible = (fieldName: string) => {
    const column = planData?.dataColumns?.find(
      (col) => col.fieldName === fieldName
    );
    if (column) {
      return column.available;
    }
    return false;
  };

  const tableRows: TableRowPlanViewDialog[] = [];

  for (let i = 0; i < PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS.length; i++) {
    const fieldName = PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS[i] + "ConsultFee";
    const groupField = PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS[i] + "GroupFee";
    tableRows.push({
      visitType: `${formatMessage({
        id: ("clientManagement.planView." + fieldName) as LanguageKeys,
      })}`,
      consultFee: planData?.dataColumns?.find(
        (col) => col.fieldName === fieldName
      )?.value,
      groupFee:
        planData?.dataColumns?.find((col) => col.fieldName === groupField)
          ?.value ?? "0.00",
      isVisible: getIsVisible(fieldName),
    });
  }

  return (
    <Dialog open={open}>
      <Card>
        <Paper sx={{ padding: 4, background: "#F5F6FF" }}>
          <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon onClick={() => onClose()} />
          </Grid>
          <Grid>
            <Typography
              component="h1"
              variant="h4"
              sx={{ marginBottom: 5, display: "flex" }}
            >
              {`${formatMessage({ id: "clientManagement.planView.plan" })} `}
              {planData.clientProductOfferingID}
            </Typography>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead
                sx={{
                  background: "#DCE6F5",
                  color: "#242459",
                  textAlign: "center",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal",
                }}
              >
                <TableRow>
                  <TableCell>
                    {formatMessage({
                      id: "clientManagement.planView.visitType",
                    })}
                  </TableCell>
                  <TableCell>
                    {formatMessage({
                      id: "clientManagement.planView.consultFee",
                    })}
                  </TableCell>
                  <TableCell>
                    {formatMessage({
                      id: "clientManagement.planView.groupFee",
                    })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((tableRow) => {
                  if (!tableRow.isVisible) {
                    return null;
                  }
                  return (
                    <TableRow key={tableRow.visitType}>
                      <TableCell>{tableRow.visitType}</TableCell>
                      <TableCell>{tableRow.consultFee}</TableCell>
                      <TableCell>{tableRow.groupFee}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Card>
    </Dialog>
  );
};

export default PlanViewDialog;
