import { Theme } from "@mui/material";

export const style = (theme: Theme) => ({
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 150,
  backgroundColor: theme.palette.common.white,
  border: `2px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  boxShadow: 24,
  p: 4,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "45px",
});
