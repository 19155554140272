import { Box, Button, Paper, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";

import { useAppSelector } from "../../../app/hooks";
import {
  selectBenefitsForProducts,
  selectHeroCardsForProducts,
} from "../../../slices/benefits/benefitSlice";
import {
  BenefitTemplate,
  CardTemplate,
  TemplateStyle,
} from "../../resources/benefits.types";
import { BenefitTitle } from "./BenefitHeader";
import { CardDisplay } from "./CardDisplay";
import { PrototypeScreen } from "./PrototypeScreen";

export const ProductScreen: React.FC<{ products: string[] }> = ({
  products,
}) => {
  const benefitsForProducts: BenefitTemplate[] = useAppSelector((state) => {
    return selectBenefitsForProducts(state, products);
  });
  const heros: CardTemplate[] = useAppSelector((state) => {
    return selectHeroCardsForProducts(state, products);
  });

  return (
    <PrototypeScreen backgroundColor="#f2f2f7">
      <BenefitTitle title="Benefits" />
      <div
        style={{
          height: "558px",
          overflowY: "scroll",
          width: "90%",
          marginLeft: "5%",
          backgroundColor: "#f2f2f7",
        }}
      >
        {heros.length > 0 &&
          heros.map((elem, index) => {
            return (
              <CardDisplay
                key={`hero-${index}`}
                cardTemplate={elem}
              />
            );
          })}
        <Grid2
          container
          spacing={2}
        >
          {benefitsForProducts?.map((elem, index) => {
            if (elem.template !== TemplateStyle.Hero_Template) {
              return (
                <Grid2
                  sm={6}
                  key={`tile-${index}`}
                >
                  <Paper
                    sx={{
                      borderRadius: "10pt",
                      height: 100,
                      width: 110,
                      padding: "10pt",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {elem.summaryImage && (
                      <img
                        style={{
                          height: 30,
                          width: 100,
                          objectFit: "contain",
                        }}
                        alt={elem.name}
                        src={elem.summaryImage}
                      />
                    )}
                    <Typography variant="benefitsHeader2">
                      {elem.label}
                    </Typography>
                    <Button
                      color="benefits"
                      variant="contained"
                      style={{
                        maxWidth: "100px",
                        minWidth: "100px",
                        padding: "1pt 3pt",
                        fontSize: 12,
                      }}
                    >
                      {elem.ctaLabel}
                    </Button>
                  </Paper>
                </Grid2>
              );
            }
            return <Box key={`benefit-${index}`}></Box>;
          })}
        </Grid2>
      </div>
    </PrototypeScreen>
  );
};
