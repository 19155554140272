import { MemberTemplate } from "./memberActions.types";

// RANDOM DATA
export const members: MemberTemplate[] = [
  {
    memberID: 1572990427,
    firstName: "Pasquale",
    lastName: "Whaites",
    dateOfBirth: "6/30/2010",
    emailAddress: "pwhaites0@epa.gov",
    address1: "03919 Holmberg Circle",
    city: "Dallas",
    state: "TX",
    zip: 75246,
    clientMemberID: "12AQV3Y9LQ",
    active: true,
  },
  {
    memberID: 1572990217,
    firstName: "Pasquale",
    lastName: "Whaites",
    dateOfBirth: "6/30/2011",
    emailAddress: "pwhaites0@epa.gov",
    address1: "03919 Holmber Circle",
    city: "Dallas",
    state: "TX",
    zip: 75246,
    clientMemberID: "12AQV3Y9L1",
    active: true,
  },
  {
    memberID: 15729900827,
    firstName: "Pasquale",
    lastName: "Whaites",
    dateOfBirth: "6/30/2010",
    emailAddress: "pwhaites0@epa.gov",
    address1: "03919 Holmberg Circle",
    city: "Dallas",
    state: "TX",
    zip: 75246,
    clientMemberID: "12AQV3Y9LQ",
    active: true,
  },
  {
    memberID: 4497421506,
    firstName: "Dagmar",
    lastName: "Sketch",
    dateOfBirth: "7/5/2015",
    emailAddress: "dsketch1@amazon.co.uk",
    address1: "343 Haas Hill",
    city: "Henderson",
    state: "NV",
    zip: 89074,
    clientMemberID: "23IAH3T5LC",
    active: false,
  },
  {
    memberID: 9181874659,
    firstName: "Blake",
    lastName: "Mephan",
    dateOfBirth: "6/13/2022",
    emailAddress: "bmephan2@google.com",
    address1: "4198 Schlimgen Junction",
    city: "Lancaster",
    state: "PA",
    zip: 17622,
    clientMemberID: "34UER9B6KZ",
    active: false,
  },
  {
    memberID: 1398055977,
    firstName: "Ronny",
    lastName: "McKelvey",
    dateOfBirth: "6/6/2014",
    emailAddress: "rmckelvey3@yelp.com",
    address1: "797 Bartillon Hill",
    city: "Bakersfield",
    state: "CA",
    zip: 93386,
    clientMemberID: "85JLV1W1QC",
    active: true,
  },
  {
    memberID: 8318568753,
    firstName: "Hillary",
    lastName: "Wakefield",
    dateOfBirth: "1/6/2002",
    emailAddress: "hwakefield4@ehow.com",
    address1: "3 Hooker Drive",
    city: "San Diego",
    state: "CA",
    zip: 92165,
    clientMemberID: "94JXO3H9PN",
    active: false,
  },
  {
    memberID: 8318566753,
    firstName: "Hillary",
    lastName: "Wakefield",
    dateOfBirth: "1/6/2002",
    emailAddress: "hwakefield4@ehow.com",
    address1: "3 Hooker Drive",
    city: "San Diego",
    state: "CA",
    zip: 92165,
    clientMemberID: "94JXO3H9PN",
    active: false,
  },
  {
    memberID: 8318568853,
    firstName: "Hillary",
    lastName: "Wakefield",
    dateOfBirth: "1/6/2002",
    emailAddress: "hwakefield4@ehow.com",
    address1: "3 Hooker Drive",
    city: "San Diego",
    state: "CA",
    zip: 92165,
    clientMemberID: "94JXO3H9PN",
    active: false,
  },
  {
    memberID: 4395886556,
    firstName: "Elizabet",
    lastName: "Codron",
    dateOfBirth: "12/27/1994",
    emailAddress: "ecodron5@time.com",
    address1: "21 Loeprich Avenue",
    city: "Lawrenceville",
    state: "GA",
    zip: 30245,
    clientMemberID: "42FBQ6P4XV",
    active: true,
  },
  {
    memberID: 2552778979,
    firstName: "Clem",
    lastName: "Nairy",
    dateOfBirth: "1/7/1994",
    emailAddress: "cnairy6@mail.ru",
    address1: "2 Westend Alley",
    city: "Long Beach",
    state: "CA",
    zip: 90810,
    clientMemberID: "75JWV3R2BW",
    active: false,
  },
  {
    memberID: 2552778990,
    firstName: "Clem",
    lastName: "Nairy",
    dateOfBirth: "1/7/1994",
    emailAddress: "cnairy6@mail.ru",
    address1: "2 Westend Alley",
    city: "Long Beach",
    state: "CA",
    zip: 90810,
    clientMemberID: "75JWV3R2BW",
    active: false,
  },
  {
    memberID: 3942033883,
    firstName: "Celestyna",
    lastName: "Dilliston",
    dateOfBirth: "5/7/2014",
    emailAddress: "cdilliston7@howstuffworks.com",
    address1: "96489 Armistice Way",
    city: "Port Charlotte",
    state: "FL",
    zip: 33954,
    clientMemberID: "73OVR9I9BV",
    active: false,
  },
  {
    memberID: 4804193995,
    firstName: "Guinevere",
    lastName: "Laimable",
    dateOfBirth: "7/20/1998",
    emailAddress: "glaimable8@usnews.com",
    address1: "675 5th Parkway",
    city: "Sacramento",
    state: "CA",
    zip: 95833,
    clientMemberID: "31SLG9K7AP",
    active: false,
  },
  {
    memberID: 2772400123,
    firstName: "Dosi",
    lastName: "Trimme",
    dateOfBirth: "12/16/2007",
    emailAddress: "dtrimme9@jugem.jp",
    address1: "7720 Ryan Lane",
    city: "Panama City",
    state: "FL",
    zip: 32405,
    clientMemberID: "21BMW8D9DJ",
    active: true,
  },
  {
    memberID: 1941015977,
    firstName: "Cullen",
    lastName: "Trevallion",
    dateOfBirth: "10/28/2009",
    emailAddress: "ctrevalliona@wordpress.org",
    address1: "57 Tomscot Drive",
    city: "Richmond",
    state: "VA",
    zip: 23213,
    clientMemberID: "85INM2S2FE",
    active: false,
  },
  {
    memberID: 3680657553,
    firstName: "Gabi",
    lastName: "Howles",
    dateOfBirth: "11/13/1996",
    emailAddress: "ghowlesb@ustream.tv",
    address1: "00270 Lakeland Pass",
    city: "Nashville",
    state: "TN",
    zip: 37250,
    clientMemberID: "31JIO4U9NR",
    active: true,
  },
  {
    memberID: 8433966038,
    firstName: "Maison",
    lastName: "Enrich",
    dateOfBirth: "2/13/2022",
    emailAddress: "menrichd@hatena.ne.jp",
    address1: "85717 Bobwhite Trail",
    city: "Loretto",
    state: "MN",
    zip: 55598,
    clientMemberID: "89TTH0P2CF",
    active: true,
  },
  {
    memberID: 6928407031,
    firstName: "Joaquin",
    lastName: "Ravenhill",
    dateOfBirth: "11/2/1990",
    emailAddress: "jravenhille@diigo.com",
    address1: "17708 Hauk Center",
    city: "San Rafael",
    state: "CA",
    zip: 94913,
    clientMemberID: "46AYR9F4WN",
    active: true,
  },
  {
    memberID: 4505068436,
    firstName: "Imogen",
    lastName: "Lovel",
    dateOfBirth: "10/4/2017",
    emailAddress: "ilovelf@blinklist.com",
    address1: "8 Steensland Hill",
    city: "Dallas",
    state: "TX",
    zip: 75260,
    clientMemberID: "26OUT6X8SW",
    active: false,
  },
  {
    memberID: 6178098569,
    firstName: "Britney",
    lastName: "Chasen",
    dateOfBirth: "10/23/2022",
    emailAddress: "bchaseng@opera.com",
    address1: "84 Di Loreto Junction",
    city: "Cumming",
    state: "GA",
    zip: 30130,
    clientMemberID: "95ZKM7T5EI",
    active: true,
  },
  {
    memberID: 8593636190,
    firstName: "Ambrosius",
    lastName: "Parkyns",
    dateOfBirth: "9/14/2000",
    emailAddress: "aparkynsh@w3.org",
    address1: "5190 American Street",
    city: "Jersey City",
    state: "NJ",
    zip: 37305,
    clientMemberID: "26INU8L6VA",
    active: false,
  },
  {
    memberID: 9566584394,
    firstName: "Celestyn",
    lastName: "Aindriu",
    dateOfBirth: "10/23/1996",
    emailAddress: "caindriui@irs.gov",
    address1: "55708 Bunting Junction",
    city: "Washington",
    state: "DC",
    zip: 20268,
    clientMemberID: "72MAV4S9WS",
    active: true,
  },
  {
    memberID: 3318378407,
    firstName: "Teressa",
    lastName: "Blake",
    dateOfBirth: "11/29/2021",
    emailAddress: "tblakej@bravesites.com",
    address1: "57453 Continental Parkway",
    city: "Baltimore",
    state: "MD",
    zip: 21211,
    clientMemberID: "94VLA4F7CH",
    active: true,
  },
  {
    memberID: 3212322346,
    firstName: "Ebonee",
    lastName: "Parley",
    dateOfBirth: "9/14/2002",
    emailAddress: "eparleyc@bandcamp.com",
    address1: "87548 Lunder Junction",
    city: "Philadelphia",
    state: "PA",
    zip: 19196,
    clientMemberID: "04VEH2N4DB",
    active: true,
  },
];
