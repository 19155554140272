import { withAuthenticationRequired } from "@auth0/auth0-react";
import { faChevronDown, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowForwardIos, Login } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { bool, number, object, string } from "yup";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LanguageKeys } from "../../common/language";
import {
  GetAllConsultationsData,
  NewOfferingInputs,
  PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS,
  ProductOffering,
  SelectedConsultInputs,
} from "../../common/resources/clientManagement.types";
import { theme } from "../../common/theme/theme";
import {
  useCreatePOMutation,
  useFetchAllConsultTypesQuery,
  useFetchAllCouponsQuery,
  useUpdatePOMutation,
} from "../../slices/clientManagement/clientManagement.service";
import {
  selectAvailableConsultationTypes,
  selectClient,
  selectCoupons,
  setSnackBarOpen,
} from "../../slices/clientManagement/clientManagementSlice";
import { StyledSwitch } from "../Clients/ClientView.style";

interface ProductOfferingModalProps {
  isOpen: boolean;
  isEditing: boolean;
  setCreateProductOfferingOpen: (open: any) => void;
  productOffering?: ProductOffering;
}

export const ProductOfferingModal = ({
  isOpen,
  isEditing,
  setCreateProductOfferingOpen,
  productOffering,
}: ProductOfferingModalProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [groupCheck, setGroupCheck] = useState(false);

  const visitTypeTableHeadCells = [
    {
      id: "consultType",
      numeric: false,
      disablePadding: true,
      label: formatMessage({
        id: "clientManagement.createOffering.consultType",
      }),
    },
    {
      id: "memberFee",
      numeric: true,
      disablePadding: false,
      label: formatMessage({ id: "clientManagement.createOffering.memberFee" }),
    },
    {
      id: "totalFee",
      numeric: true,
      disablePadding: false,
      label: formatMessage({ id: "clientManagement.createOffering.totalFee" }),
    },
    {
      id: "feeAfterDeductible",
      numeric: true,
      disablePadding: false,
      label: formatMessage({
        id: "clientManagement.createOffering.feeAfterDeductible",
      }),
    },
  ];

  const couponTableHeadCells = [
    {
      id: "couponName",
      numeric: false,
      disablePadding: false,
      label: "Coupon Name",
    },
    {
      id: "consultType",
      numeric: true,
      disablePadding: false,
      label: "Consult Type",
    },
    {
      id: "discount",
      numeric: true,
      disablePadding: false,
      label: "Discount",
    },
    {
      id: "includeDependents",
      numeric: false,
      disablePadding: false,
      label: "Include Dependents",
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const addFeatureOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const addFeatureClose = () => {
    setAnchorEl(null);
  };

  const regex = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
  const [createPO] = useCreatePOMutation();
  const [updatePO] = useUpdatePOMutation();
  const [hasDeductible, setHasDeductible] = useState<boolean>(false);
  const [hasCoupon, setHasCoupon] = useState<boolean>(false);
  const [couponConsultations, setCouponConsultations] = useState<number[]>([]);
  const [chosenConsultationTypes, setChosenConsultationTypes] = useState<
    SelectedConsultInputs[]
  >([
    {
      consultationTypeID: 0,
      description: "",
      memberFee: "",
      totalFee: "",
      feeAfterDeductible: "",
    },
  ]);
  const client = useAppSelector(selectClient);
  const coupons = useAppSelector(selectCoupons);
  const consultationTypes = useAppSelector(selectAvailableConsultationTypes);

  useFetchAllCouponsQuery();
  useFetchAllConsultTypesQuery();

  const newProductOfferingSchema = object({
    planName: string().required(
      formatMessage({ id: "clientManagement.createOffering.planNameRequired" })
    ),
    pepm: number().required(
      formatMessage({ id: "clientManagement.createOffering.pepmRequire" })
    ),
    couponId: number().nullable(),
    setToDependent: bool().nullable(),
  });

  useEffect(() => {
    const findAvailableConsultTypes = () => {
      const getIsVisible = (fieldName: string) => {
        const column = productOffering?.dataColumns?.find(
          (col) => col.fieldName === fieldName
        );
        if (column) {
          return column.available;
        }
        return false;
      };

      const consultsAvailable: SelectedConsultInputs[] = [];

      for (let i = 0; i < PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS.length; i++) {
        const fieldName = PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS[i] + "ConsultFee";
        const groupField = PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS[i] + "GroupFee";
        if (getIsVisible(fieldName)) {
          const memberFee = productOffering?.dataColumns?.find(
            (col) => col.fieldName === fieldName
          )?.value;
          const groupFee = productOffering?.dataColumns?.find(
            (col) => col.fieldName === groupField
          )?.value;
          const consultID = productOffering?.dataColumns?.find(
            (col) => col.fieldName === fieldName
          )?.id;
          consultsAvailable.push({
            consultationTypeID: consultID ?? 0,
            description: `${formatMessage({
              id: ("clientManagement.planView." + fieldName) as LanguageKeys,
            })}`,
            memberFee: memberFee ?? "0.00",
            totalFee: groupFee ?? "0.00",
            feeAfterDeductible: "",
          });
        }
      }
      setChosenConsultationTypes(consultsAvailable);
    };
    if (isEditing) {
      findAvailableConsultTypes();
      if (productOffering?.couponId) {
        setHasCoupon(true);
      }
    }
  }, [productOffering, isEditing, formatMessage]);

  const formik = useFormik({
    initialValues: {
      planName: "",
      pepm: productOffering?.pepm ?? "",
      couponId: productOffering?.couponId ?? null,
      setToDependent: productOffering?.setToDependent ?? true,
    },
    validationSchema: newProductOfferingSchema,
    enableReinitialize: true,
    onSubmit: async (values: NewOfferingInputs) => {
      const consultationFees: object[] = [];
      chosenConsultationTypes.forEach((type) => {
        const consultation = {
          ConsultationTypeID: type.consultationTypeID,
          MemberFee: type.memberFee,
          MemberFeeAfterDeductibleMet: type.feeAfterDeductible
            ? type.feeAfterDeductible
            : null,
          TotalFee: type.totalFee,
          Req270: false,
          IgnoreCoInsurance: false,
          Minutes: null,
        };
        consultationFees.push(consultation);
      });
      if (isEditing) {
        await updatePO({
          ClientProductOfferingID:
            productOffering?.clientProductOfferingID?.toString() ?? "",
          ClientID: client.clientID.toString(),
          BillingFee: values.pepm,
          CouponID: values.couponId,
          SetToDependent: values.setToDependent,
          StartDate: new Date(),
          ConsultationFees: consultationFees,
        });
      } else {
        await createPO({
          ClientID: client.clientID,
          BillingFee: values.pepm,
          CouponID: values.couponId,
          SetToDependent: values.setToDependent,
          StartDate: new Date(),
          ConsultationFees: consultationFees,
        });
      }
      setCreateProductOfferingOpen({
        isOpen: false,
        isEditing: false,
        isSavedUpdated: true,
        productOffering: undefined,
      });
      setChosenConsultationTypes([
        {
          consultationTypeID: 0,
          description: "",
          memberFee: "",
          totalFee: "",
          feeAfterDeductible: "",
        },
      ]);
      setHasDeductible(false);
      setHasCoupon(false);
      formik.resetForm();
      setCouponConsultations([]);
    },
  });

  const verifyConsultFees = (): boolean => {
    let verified = true;
    chosenConsultationTypes.forEach((type) => {
      if (!type.memberFee || !type.totalFee) {
        verified = false;
      }
    });
    return verified;
  };

  const checkIfCanCreatePlan = () => {
    formik.validateForm();
    if (
      formik.isValid &&
      chosenConsultationTypes.length > 0 &&
      verifyConsultFees()
    ) {
      return true;
    }

    dispatch(
      setSnackBarOpen({
        open: true,
        severity: "error",
        title: "clientManagement.createPO.errorTitle",
        subTitle: "clientManagement.createPO.error",
      })
    );
    return false;
  };

  const addVisit = () => {
    setChosenConsultationTypes((prevState) => [
      ...prevState,
      {
        consultationTypeID: 0,
        description: "",
        memberFee: "",
        totalFee: "",
        feeAfterDeductible: "",
      },
    ]);
  };

  const updateSelectedConsults = (
    currentConsultTypeID: any,
    rowIndex: number
  ) => {
    const chosenType = consultationTypes.find(
      (type: GetAllConsultationsData) =>
        type.ConsultationTypes[0].consultationTypeID === currentConsultTypeID
    );
    const alreadyChosen = chosenConsultationTypes.find(
      (type) =>
        type.consultationTypeID ===
        chosenType?.ConsultationTypes[0].consultationTypeID
    );

    const findConsultType = consultationTypes.find(
      (cType: GetAllConsultationsData) =>
        cType.ConsultationTypes.find(
          (c) =>
            c.consultationTypeID ===
            chosenConsultationTypes[rowIndex].consultationTypeID
        )
    );

    if (chosenType && !alreadyChosen) {
      const newChosenType = (index: number) => ({
        consultationTypeID:
          chosenType.ConsultationTypes[index].consultationTypeID,
        description: chosenType.ConsultationTypes[index].description,
        memberFee: "0.00",
        totalFee: "0.00",
        feeAfterDeductible: "",
      });

      let updatedChosenConsultationTypes: SelectedConsultInputs[] = [
        ...chosenConsultationTypes,
      ];

      if (!findConsultType) {
        updatedChosenConsultationTypes = updatedChosenConsultationTypes.filter(
          (item) => item.consultationTypeID !== 0
        );
      }

      if (findConsultType?.IsFollowUpType) {
        const firstFilter = updatedChosenConsultationTypes.filter(
          (item) =>
            item.consultationTypeID !==
            findConsultType.ConsultationTypes[0].consultationTypeID
        );
        const secondFilter = firstFilter.filter(
          (item) =>
            item.consultationTypeID !==
            findConsultType.ConsultationTypes[1].consultationTypeID
        );
        updatedChosenConsultationTypes = secondFilter;
      } else {
        updatedChosenConsultationTypes = updatedChosenConsultationTypes.filter(
          (item) =>
            item.consultationTypeID !==
            findConsultType?.ConsultationTypes[0].consultationTypeID
        );
      }

      if (chosenType.IsFollowUpType) {
        updatedChosenConsultationTypes.push(newChosenType(0), newChosenType(1));
      } else {
        updatedChosenConsultationTypes.push(newChosenType(0));
      }
      setChosenConsultationTypes(updatedChosenConsultationTypes);
    }
  };

  const removeChosenConsultType = (type: SelectedConsultInputs) => {
    const findConsultType = consultationTypes.find(
      (cType: GetAllConsultationsData) =>
        cType.ConsultationTypes.find(
          (c) => c.consultationTypeID === type.consultationTypeID
        )
    );
    if (findConsultType) {
      if (findConsultType?.IsFollowUpType) {
        const firstFilter = chosenConsultationTypes.filter(
          (item) =>
            item.consultationTypeID !==
            findConsultType.ConsultationTypes[0].consultationTypeID
        );
        const secondFilter = firstFilter.filter(
          (item) =>
            item.consultationTypeID !==
            findConsultType.ConsultationTypes[1].consultationTypeID
        );
        return setChosenConsultationTypes(secondFilter);
      }
      setChosenConsultationTypes(
        chosenConsultationTypes.filter(
          (item) =>
            item.consultationTypeID !==
            findConsultType.ConsultationTypes[0].consultationTypeID
        )
      );
    } else if (type.consultationTypeID === 0) {
      setChosenConsultationTypes(
        chosenConsultationTypes.filter((item) => item.consultationTypeID !== 0)
      );
    }
  };

  const updateConsultFees = (
    feeType: string,
    consultID: number,
    feeAmount: string
  ) => {
    const findConsultType = chosenConsultationTypes.findIndex(
      (elem) => elem.consultationTypeID === consultID
    );
    const modifiedArray = [...chosenConsultationTypes];
    if (feeType === "memberFee") {
      modifiedArray[findConsultType].memberFee = feeAmount;
    } else if (feeType === "totalFee") {
      modifiedArray[findConsultType].totalFee = feeAmount;
    } else if (feeType === "feeAfterDeductible") {
      modifiedArray[findConsultType].feeAfterDeductible = feeAmount;
    }
    setChosenConsultationTypes(modifiedArray);
  };

  const updatedVisitTypeTableHeadCells = visitTypeTableHeadCells.filter(
    (headCell) => headCell.id !== "feeAfterDeductible" || hasDeductible
  );

  // used as place holder for disabled consult select until V2 implementation 
  useEffect(() => {
    const thisCoupon = coupons.find((coupon) => coupon.CouponID === formik.values.couponId)
    if (thisCoupon?.Name.includes("BH")) {
      setCouponConsultations([1099, 1102, 1111])
    } else if (thisCoupon?.Name.includes("Therapy" || "Counseling")) {
      setCouponConsultations([1099])
    } else if (thisCoupon?.Name.includes("Psychiatry")) {
      setCouponConsultations([1102, 1111])
    } else if (thisCoupon?.Name.includes("Consultation")) {
      setCouponConsultations([1])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.couponId]);

  // this is used to find the consultation names from within the ConsultationTypes array
  const listCouponConsulations = (
    couponConsultations.map((couponConsultation) => {

      // depending on the consult type, initial and follow up are paired together in the consultationTypes.ConsultationTypes array
      const firstConsultation = consultationTypes.find((consultation) =>
        consultation.ConsultationTypes[0].consultationTypeID === couponConsultation
      )?.ConsultationTypes[0].description || "";

      // this is to find the second consultation type in the array
      const secondConsultation = consultationTypes.find((consultation) =>
        consultation.ConsultationTypes[1]?.consultationTypeID === couponConsultation
      )?.ConsultationTypes[1].description || "";
      return firstConsultation + " " + secondConsultation;
    }).join(", ")
  )

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <Typography
              component="h4"
              variant="h4"
              sx={{
                color: "#242459",
                fontSize: "20px",
                font: "Cheltenham Pro",
                fontWeight: 700,
                textAlign: "left",
              }}
            >
              {formatMessage({ id: "clientManagement.createOffering.title" })}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            textAlign="right"
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#CBCEEC",
                color: "#242459",
              }}
              endIcon={<FontAwesomeIcon icon={faChevronDown} />}
              onClick={addFeatureOpen}
            >
              {formatMessage({ id: "clientManagement.productOfferingModal.addFeature" })}
            </Button>
            <Menu
              anchorEl={anchorEl}
              onClose={addFeatureClose}
              open={menuOpen}
            >
              <MenuItem
                onClick={() => {
                  addFeatureClose();
                  setHasDeductible(!hasDeductible);
                }}
              >
                {formatMessage({ id: "clientManagement.productOfferingModal.addDeductible" })}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  addFeatureClose();
                  setHasCoupon(!hasCoupon);
                }}
              >
                {formatMessage({ id: "clientManagement.productOfferingModal.coupons" })}
              </MenuItem>
            </Menu>
          </Grid>
          <Grid
            item
            xs={12}
            paddingTop={0}
            marginTop={-2}
          >
            <Typography
              sx={{ textAlign: "left", color: "#242459" }}
              component="h4"
              variant="h6"
            >
              {formatMessage({
                id: "clientManagement.createOffering.planDetails",
              })}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid component="form">
          <Typography
            sx={{ textAlign: "left", color: "#242459" }}
            component="h4"
            variant="h6"
          >
            {formatMessage({ id: "clientManagement.productOfferingModal.planDetails" })}
          </Typography>
          <Grid
            container
            display="flex"
            justifyContent="space-around"
            sx={{ marginTop: 2 }}
          >
            <TextField
              name="planName"
              onChange={formik.handleChange}
              value={formik.values.planName}
              required
              placeholder={formatMessage({
                id: "clientManagement.createOffering.planNamePlaceholder",
              })}
              label={formatMessage({
                id: "clientManagement.planView.planName",
              })}
              sx={{ width: 400 }}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.planName)}
              helperText={formik.errors.planName}
            />
            <TextField
              label={formatMessage({
                id: "clientManagement.newGroup.clientLabel",
              })}
              name="clientName"
              value={client.clientName}
              disabled
              sx={{ width: 400 }}
            />
            <TextField
              name="pepm"
              onChange={(e) => {
                const newVal = e.target.value;
                if (newVal === "" || regex.test(newVal)) {
                  formik.handleChange(e);
                }
              }}
              value={formik.values.pepm}
              required
              placeholder={formatMessage({
                id: "clientManagement.createOffering.pepmPlaceholder",
              })}
              label={formatMessage({
                id: "clientManagement.planView.PEPM",
              })}
              onBlur={formik.handleBlur}
              error={Boolean(formik.errors.pepm)}
              helperText={formik.errors.pepm}
            />
          </Grid>
          <Typography
            sx={{ textAlign: "left", color: "#242459", marginTop: 5 }}
            component="h4"
            variant="h6"
          >
            {formatMessage({ id: "clientManagement.productOfferingModal.visitTypes" })}
          </Typography>
          <Grid
            container
            display="flex"
            marginTop={4}
            justifyContent="center"
          >
            <TableContainer sx={{ width: "95%" }}>
              <Table
                aria-labelledby="tableTitle"
                size="small"
              >
                <TableHead>
                  <TableRow sx={{ background: "#F5F6FF" }}>
                    {updatedVisitTypeTableHeadCells.map((headCell) => (
                      <TableCell
                        sx={{
                          fontWeight: 700,
                          fontSize: "16px",
                          color: "#242459",
                        }}
                        key={headCell.id}
                      >
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {chosenConsultationTypes.map((type, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover
                        sx={{ cursor: "pointer", paddingBottom: 0 }}
                        style={{ padding: 0 }}
                      >
                        <TableCell
                          sx={{ fontWeight: 700, color: "#242459" }}
                          component="th"
                          scope="row"
                        >
                          <Select
                            label="Consultation Types"
                            placeholder="Consultation Types"
                            value={chosenConsultationTypes[index]}
                            onChange={(event) =>
                              updateSelectedConsults(event.target.value, index)
                            }
                            input={
                              <OutlinedInput
                                label={formatMessage({
                                  id: "clientManagement.createOffering.consultationTypeLabel",
                                })}
                              />
                            }
                            renderValue={(selected) => {
                              const row = selected;
                              return (
                                <Stack
                                  gap={1}
                                  direction="row"
                                >
                                  <CancelIcon
                                    onClick={() => removeChosenConsultType(row)}
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                  <Typography key={row.consultationTypeID}>
                                    {row.description
                                      ? row.description
                                      : "Select A Consultation Type"}
                                  </Typography>
                                </Stack>
                              );
                            }}
                          >
                            {consultationTypes.map(
                              (consultationType: GetAllConsultationsData) => (
                                <MenuItem
                                  key={
                                    consultationType.ConsultationTypes[0]
                                      .description
                                  }
                                  value={
                                    consultationType.ConsultationTypes[0]
                                      .consultationTypeID
                                  }
                                >
                                  {
                                    consultationType.ConsultationTypes[0]
                                      .description
                                  }
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            required
                            value={type.memberFee}
                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(e) =>
                              updateConsultFees(
                                "memberFee",
                                type.consultationTypeID,
                                e.target.value
                              )
                            }
                            type="number"
                            placeholder={formatMessage({
                              id: "clientManagement.createOffering.consultInputPlaceholder",
                            })}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            size="small"
                            required
                            InputProps={{
                              inputProps: { min: 0 },
                              value: type.totalFee,
                            }}
                            onChange={(e) =>
                              updateConsultFees(
                                "totalFee",
                                type.consultationTypeID,
                                e.target.value
                              )
                            }
                            type="number"
                            placeholder={formatMessage({
                              id: "clientManagement.createOffering.consultInputPlaceholder",
                            })}
                          />
                        </TableCell>
                        {hasDeductible && (
                          <TableCell>
                            <TextField
                              size="small"
                              InputProps={{ inputProps: { min: 0 } }}
                              onChange={(e) =>
                                updateConsultFees(
                                  "feeAfterDeductible",
                                  type.consultationTypeID,
                                  e.target.value
                                )
                              }
                              type="number"
                              placeholder={formatMessage({
                                id: "clientManagement.createOffering.consultInputPlaceholder",
                              })}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            container
            marginTop={1}
          >
            <Stack
              sx={{ marginTop: 1 }}
              direction="row"
              alignItems="left"
            >
              <Button
                size="large"
                variant="text"
                onClick={addVisit}
              >
                <AddIcon sx={{ marginRight: 2 }} />
                {` ${formatMessage({
                  id: "clientManagement.createOffering.addVisit",
                })}`}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        {hasCoupon ?
          <Grid>
            <Typography
              sx={{ textAlign: "left", color: "#242459" }}
              component="h4"
              variant="h6"
            >
              {formatMessage({ id: "clientManagement.productOfferingModal.coupons" })}
            </Typography>
            <Grid
              container
              display="flex"
              marginTop={4}
              justifyContent="center"
            >
              <TableContainer sx={{ width: "95%" }}>
                <Table
                  aria-labelledby="tableTitle"
                  size="small"
                >
                  <TableHead>
                    <TableRow sx={{ background: "#F5F6FF" }}>
                      {couponTableHeadCells.map((headCell) => (
                        <TableCell
                          sx={{
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#242459",
                          }}
                          key={headCell.id}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      hover
                      sx={{ cursor: "pointer", paddingBottom: 0 }}
                      style={{ padding: 0 }}
                    >
                      <TableCell
                        sx={{ fontWeight: 700, color: "#242459" }}
                        component="th"
                        scope="row"
                      >
                        <FormControl>
                          <InputLabel id="couponLabel">
                            {formatMessage({ id: "clientManagement.productOfferingModal.selectCoupon" })}
                          </InputLabel>
                          <Select
                            name="couponId"
                            id="couponId"
                            labelId="couponLabel"
                            label={formatMessage({ id: "clientManagement.productOfferingModal.selectCoupon" })}
                            sx={{ minWidth: 200 }}
                            value={formik.values.couponId || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            {coupons.map((coupon) => (
                              <MenuItem key={coupon.CouponID} value={coupon.CouponID}>
                                {coupon.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {/* Disabled until V2 allowing new coupon creation */}
                        <FormControl
                          fullWidth
                          sx={{ minWidth: 200, maxWidth: 250 }}
                        >
                          <InputLabel id="consultLabel">
                            {formatMessage({ id: "clientManagement.productOfferingModal.selectConsult" })}
                          </InputLabel>
                          <Tooltip
                            title={listCouponConsulations}
                            placement="top-end"
                          >
                            <Select
                              labelId="consultLabel"
                              label={formatMessage({ id: "clientManagement.productOfferingModal.selectConsult" })}
                              disabled
                              multiple
                              value={couponConsultations}
                              fullWidth
                            // reenable for V2 new coupon creation
                            // onChange={(e) => setCouponConsultations(e.target.value as number[])}
                            >
                              {consultationTypes.map((consultationType) => (
                                <MenuItem
                                  key={consultationType.ConsultationTypes[0].consultationTypeID}
                                  value={consultationType.ConsultationTypes[0].consultationTypeID}
                                >
                                  {consultationType.ConsultationTypes[0].description}
                                </MenuItem>
                              ))}
                            </Select>
                          </Tooltip>
                        </FormControl>
                      </TableCell>
                      {/* Disabled until V2 allowing new coupon creation */}
                      <TableCell>
                        <TextField
                          sx={{ width: 200 }}
                          placeholder={formatMessage({ id: "clientManagement.productOfferingModal.enterValue" })}
                          disabled
                          value={coupons.find(
                            (coupon) => coupon.CouponID === formik.values.couponId
                          )?.PercentageOff ?? 0}
                          InputProps={{
                            endAdornment: (
                              <Grid sx={{ display: "flex" }}>
                                <Typography
                                  sx={{ fontSize: "30px", marginRight: 3 }}
                                >|</Typography>
                                <FormControl
                                  variant="standard"
                                >
                                  {/* only one option until V2 */}
                                  <Select
                                    disableUnderline
                                    value={1}
                                    disabled
                                  >
                                    <MenuItem
                                      value={1}
                                    >
                                      {formatMessage({ id: "clientManagement.productOfferingModal.off" })}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            )
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                          sx={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}
                        >
                          <Grid
                            item
                            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}
                          >
                            <StyledSwitch
                              name="setToDependent"
                              id="setToDependent"
                              checked={formik.values.setToDependent}
                              onChange={formik.handleChange}
                            />
                          </Grid>
                          {formik.values.couponId ?
                            <Grid item>
                              <FontAwesomeIcon
                                onClick={() => {
                                  formik.setFieldValue("couponId", null);
                                  setCouponConsultations([]);
                                }}
                                style={{ cursor: "pointer", color: theme.palette.error.dark }}
                                icon={faXmark}
                              />
                            </Grid>
                            : null}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          : null}
      </DialogContent>
      <DialogActions sx={{ marginTop: 5 }}>
        <Button
          sx={{
            width: 150,
            height: 45,
            color: "#757679",
            borderColor: "#757679",
          }}
          onClick={() => {
            setHasDeductible(false);
            setHasCoupon(false);
            setCreateProductOfferingOpen({
              isOpen: false,
              isEditing: false,
              isSavedUpdated: false,
            });
            formik.resetForm();
            setChosenConsultationTypes([
              {
                consultationTypeID: 0,
                description: "",
                memberFee: "",
                totalFee: "",
                feeAfterDeductible: "",
              },
            ]);
            setCouponConsultations([]);
          }}
          variant="outlined"
        >
          {formatMessage({ id: "clientManagement.planView.cancelButton" })}
        </Button>
        <Button
          sx={{ width: 150, height: 45 }}
          id="newGroupDispatch"
          variant="contained"
          endIcon={<ArrowForwardIos />}
          onClick={() => {
            if (isEditing) {
              setGroupCheck(true);
            } else if (checkIfCanCreatePlan()) {
              formik.handleSubmit();
            }
          }}
          fullWidth
        >
          {formatMessage({
            id: "clientManagement.createOffering.saveButton",
          })}
        </Button>
      </DialogActions>
      <Dialog
        open={groupCheck}
        onClose={() => setGroupCheck(false)}
      >
        <DialogTitle>
          {`${productOffering?.groupCount} `}
          {formatMessage({
            id: "clientManagement.planView.groupsPlan",
          })}
        </DialogTitle>
        <DialogActions>
          <Button
            sx={{ width: 150, height: 45 }}
            id="newGroupDispatch"
            variant="contained"
            onClick={() => setGroupCheck(false)}
          >
            {formatMessage({
              id: "clientManagement.planView.cancelButton",
            })}
          </Button>
          <Button
            sx={{ width: 150, height: 45 }}
            id="newGroupDispatch"
            variant="contained"
            endIcon={<ArrowForwardIos />}
            onClick={() => {
              setGroupCheck(false);
              if (checkIfCanCreatePlan()) {
                formik.handleSubmit();
              }
            }}
          >
            {formatMessage({
              id: "clientManagement.createOffering.confirm",
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default withAuthenticationRequired(ProductOfferingModal, {
  onRedirecting: () => <Login />,
});
