import { createIntl, createIntlCache } from "react-intl";

import appLangEn from "./en/app.en.json";
import benefitsLangEn from "./en/benefits.en.json";
import bulkOrders from "./en/bulkOrders.en.json";
import clientManagementLangEn from "./en/clientManagement.en.json";
import homeLangEn from "./en/home.en.json";
import loginLangEn from "./en/login.en.json";
import memberLangEn from "./en/memberActions.en.json";
import reportingLangEn from "./en/reporting.en.json";
import ssoToolLangEn from "./en/ssoTool.en.json";

export const TRANSLATIONS_FOR_USERS_LOCALE = {
  en: {
    ...appLangEn,
    ...memberLangEn,
    ...benefitsLangEn,
    ...clientManagementLangEn,
    ...loginLangEn,
    ...homeLangEn,
    ...ssoToolLangEn,
    ...reportingLangEn,
    ...bulkOrders,
  },
};

export type LanguageKeys = keyof (typeof TRANSLATIONS_FOR_USERS_LOCALE)["en"];

declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: LanguageKeys;
    }
  }
}

const usersLocale = "en";
const cache = createIntlCache();

export const intl = createIntl(
  {
    locale: usersLocale,
    messages: TRANSLATIONS_FOR_USERS_LOCALE[usersLocale],
  },
  cache
);
