export enum TemplateStyle {
  Text_Template = "text_template",
  Card_Template = "card_template",
  Hero_Template = "hero_template",
}

export enum CardStyle {
  Right_Align = "right_align",
  Left_Align = "left_align",
  No_Text = "no_text",
}

export enum ButtonFormat {
  Navigate_Link = "navigate_link",
  Call_Number = "call_number",
  Open_Pdf = "open_pdf",
  Other = "other",
}

export enum ButtonStyle {
  Contained = "contained",
  Text = "text",
}

export enum Benefits {
  OnDemandCounseling3 = "ondemand3",
  OnDemandCounseling5 = "ondemand5",
  OnDemandCounseling8 = "ondemand8",
  OnDemandCounselingStudent3 = "ondemandstudent3",
  Papa = "papa",
  Brightline = "brightline",
  eFamilyCare = "efamilycare",
  PharmacyBenefits = "pharmacy",
  HealthAdvocate = "healthadvocate",
  SampleCard = "sample",
}

export type CardTemplate = {
  hero: boolean;
  style: CardStyle;
  hasOverlay: boolean;
  groupPrefix?: string;
  groupSuffix?: string;
  src: string;
  header?: string;
};

export type ButtonTemplate = {
  name?: string;
  value?: string;
  format: ButtonFormat;
  style: ButtonStyle;
};

export type ContentTemplate = {
  subheader?: string;
  body?: string;
  buttons?: ButtonTemplate[];
};

export type BenefitTemplate = {
  name: string;
  active: boolean;
  goLive?: string;
  label: string;
  template: TemplateStyle;
  ctaLabel: string;
  chiClient?: string;
  summaryImage?: string;
  dirty?: boolean;
  id: string;
  customData: {
    logo?: string;
    card: CardTemplate[];
    content: ContentTemplate[];
  };
};

export type Product = {
  name: string;
  id: string;
  eligibilityId: string;
  benefits: string[];
  dirty?: boolean;
};
