import { useAuth0 } from "@auth0/auth0-react";
import { Box, Container } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { useAppDispatch } from "./app/hooks";
import { persistor } from "./app/store";
import { Header } from "./common/components/Header/Header";
import { NoAccessDisplay } from "./common/components/NoAccessDisplay/NoAccessDisplay";
import PageError from "./common/navigation/pageError";
import { RouteDefinition, RouteDefinitions } from "./common/resources/routes";
import { checkPageAccess } from "./common/utils/checkPageAccess";
import { FeatureFlag } from "./config/flags.config";
import Home from "./pages/Home/Home";
import { login } from "./slices/user/userSlice";

function App() {
  const dispatch = useAppDispatch();
  const auth0 = useAuth0();
  auth0.getIdTokenClaims().then((resp) => {
    if (resp?.__raw) {
      dispatch(login(resp?.__raw));
    }
  });

  return (
    <>
      <Header />
      <Box height={80} />
      <PersistGate persistor={persistor}>
        <Routes>
          {RouteDefinitions.map((elem: RouteDefinition, index) => {
            let routeElement: React.ReactElement | null = null;
            const groups: string[] = auth0.user?.ad_groups || [];
            const hasPageAccess = checkPageAccess(elem.accessKey, groups);
            const notInDevelopment: boolean =
              FeatureFlag.featuresInDevelopment || !elem.featureFlagged;
            if (notInDevelopment && hasPageAccess) {
              routeElement = <elem.element />;
            } else {
              routeElement = (
                <Container maxWidth="md">
                  <NoAccessDisplay />
                </Container>
              );
            }
            return (
              <Route
                errorElement={<PageError />}
                key={`route-${index}`}
                path={elem.path}
                element={routeElement}
              />
            );
          })}
          <Route
            path="*"
            element={<Home />}
          />
        </Routes>
      </PersistGate>
    </>
  );
}

export default App;
