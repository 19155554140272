import { useAuth0 } from "@auth0/auth0-react";
import { Box, Tab, Tabs } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";

import { Environments, FeatureFlag } from "../../config/flags.config";
import { BulkOrders } from "./BulkOrders";
import { InsuranceLookup } from "./InsuranceLookup";
import { OrderDayReport } from "./OrderDayReport";
import { PerformanceMetricReport } from "./PerformanceMetricsReport";
import { ProviderReport } from "./ProviderReport";
import { StateOutstandingReport } from "./StateOutstandingReport";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      aria-controls={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </Box>
  );
}

const securityGroupConfig = {
  reporting: [
    "SG-AdminPortal-EXAS-Read",
    "SG-AdminPortal-EXAS-Write",
    "SG-AdminPortal-EXAS-Billing",
  ],
  billing: ["SG-AdminPortal-EXAS-Billing"],
  phiAccess: [], // Empty Arrays are only available to admins
  bulkOrders: FeatureFlag.bulkOrders
    ? ["SG-RecuroTooling-Admin", "SG-AdminPortal-ClientMgmt-Admin"]
    : "",
};

type LocalAccessKey = keyof typeof securityGroupConfig;

const defaultTabGroups: {
  index: number;
  tabName: string;
  component: ReactElement;
  disabled: boolean;
  accessKey: LocalAccessKey;
}[] = [
  {
    index: 0,
    tabName: "Monthly Metrics",
    component: <PerformanceMetricReport />,
    disabled: true,
    accessKey: "reporting",
  },
  {
    index: 1,
    tabName: "Insurance Lookup",
    component: <InsuranceLookup />,
    disabled: true,
    accessKey: "phiAccess",
  },
  {
    index: 3,
    tabName: "Provider Report",
    component: <ProviderReport />,
    disabled: true,
    accessKey: "billing",
  },
  {
    index: 4,
    tabName: "Pending Orders",
    component: <StateOutstandingReport />,
    disabled: true,
    accessKey: "reporting",
  },
  {
    index: 5,
    tabName: "Received Orders Report",
    component: <OrderDayReport />,
    disabled: true,
    accessKey: "reporting",
  },
  {
    index: 6,
    tabName: "Bulk Orders",
    component: <BulkOrders />,
    disabled: true,
    accessKey: "bulkOrders",
  },
];

const ExactSciencesAdmin: React.FC = () => {
  const { user } = useAuth0();

  const [selectedTab, setSelectedTab] = useState(0);
  const [TabMap, setTabMap] =
    useState<typeof defaultTabGroups>(defaultTabGroups);

  const environment: Environments =
    (process.env.REACT_APP_ENVIRONMENT as Environments) || "prod";

  const isDisabled = (accessKey: LocalAccessKey, userGroups: string[]) => {
    const isAdmin =
      userGroups.includes("SG-AdminPortal-Prod-Admin") ||
      userGroups.includes("SG-AdminPortal-EXAS-Admin");
    const isNonProdAdmin = userGroups.includes(
      "SG-AdminPortal-AllNonProd-Admin"
    );
    if (isAdmin || (environment !== "prod" && isNonProdAdmin)) {
      return false;
    }

    const accessValues = securityGroupConfig[accessKey];
    for (let i = 0; i < accessValues.length; i++) {
      if (userGroups.includes(accessValues[i])) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const userGroups = user?.ad_groups;
    const newTabs = TabMap.map((elem) => {
      elem.disabled = isDisabled(elem.accessKey, userGroups);
      return elem;
    });
    setTabMap(newTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        value={selectedTab}
        variant="scrollable"
        scrollButtons="auto"
        onChange={(event, value) => {
          setSelectedTab(value);
        }}
        aria-label="basic tabs example"
      >
        {TabMap.sort((a, b) => a.index - b.index).map((elem) => (
          <Tab
            key={`tab-header-${elem.index}`}
            label={elem.tabName}
            value={elem.index}
            disabled={elem.disabled}
          />
        ))}
      </Tabs>
      {TabMap.map((elem) => {
        return (
          <CustomTabPanel
            key={`tab-panel-${elem.index}`}
            value={selectedTab}
            index={elem.index}
          >
            {elem.component}
          </CustomTabPanel>
        );
      })}
    </Box>
  );
};

export { ExactSciencesAdmin };
