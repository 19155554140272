export const accessGroups = {
  benefits: ["SG-AdminPortal-Benefits-Write", "SG-AdminPortal-Benefits-Admin"],
  development: ["SG-ENGINEERINGTEAM"],
  memberMerge: [
    "SG-AdminPortal-MemberMerge-Write",
    "SG-AdminPortal-MemberMerge-Admin",
  ],
  clientManagement: [
    "SG-AdminPortal-ClientMgmt-Admin",
    "SG-AdminPortal-ClientMgmt-Write",
    "SG-AdminPortal-ClientMgmt-Read",
  ],
  developerTools: [],
  reporting: [
    "SG-AdminPortal-Reporting-Write",
    "SG-AdminPortal-Reporting-Admin",
  ],
  sso: ["SG-ENGINEERING-VCA-TEAM"],
  exasAdmin: [
    "SG-AdminPortal-Prod-Admin",
    "SG-AdminPortal-EXAS-Admin",
    "SG-AdminPortal-AllNonProd-Admin",
    "SG-AdminPortal-EXAS-Read",
    "SG-AdminPortal-EXAS-Write",
    "SG-AdminPortal-EXAS-Billing",
  ],
};

// Using implicit type definition to prevent writing tons of boiler plate
export type AccessConfig = typeof accessGroups;
export type AccessKeys = keyof AccessConfig;
