import { Paper, Typography } from "@mui/material";

import { CardStyle, CardTemplate } from "../../resources/benefits.types";
import { theme } from "../../theme/theme";

const CardDisplay: React.FC<{ cardTemplate: CardTemplate }> = ({
  cardTemplate,
}) => {
  return (
    <Paper
      sx={{
        borderRadius: 0,
        width: theme.shape.screenWidth - 40,
        marginBottom: "16pt",
      }}
    >
      <img
        style={{ width: theme.shape.screenWidth - 50, objectFit: "fill" }}
        alt="card displayed with text"
        src={cardTemplate.src}
      />
      {cardTemplate.style === CardStyle.Right_Align && (
        <div
          style={{
            position: "relative",
            height: "0px",
            overflow: "visible",
            top: "-91pt",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            left: "85pt",
            alignItems: "self-start",
          }}
        >
          <Typography
            variant="benefitsHeader2"
            style={{ margin: 0 }}
          >
            Test Member
          </Typography>
          <Typography
            variant="benefitsHeader2"
            style={{ margin: 0 }}
          >
            {cardTemplate.groupPrefix}TEST_GROUP{cardTemplate.groupSuffix}
          </Typography>
        </div>
      )}
      {cardTemplate.style === CardStyle.Left_Align && (
        <div
          style={{
            position: "relative",
            height: "0px",
            overflow: "visible",
            top: "-87pt",
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            left: "8pt",
            alignItems: "self-start",
          }}
        >
          <Typography
            variant="benefitsHeader2"
            style={{ margin: 0, padding: "2.5pt" }}
          >
            Test Member
          </Typography>
          <Typography
            variant="benefitsHeader2"
            style={{ margin: 0, padding: "2.5pt" }}
          >
            {cardTemplate.groupPrefix}TEST_MEMBER_1{cardTemplate.groupSuffix}
          </Typography>
          <Typography
            variant="benefitsHeader2"
            style={{ margin: 0, padding: "2.5pt" }}
          >
            TEST_GROUP
          </Typography>
        </div>
      )}
    </Paper>
  );
};
export { CardDisplay };
