import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { CreateClientInput } from "../../common/resources/clientManagement.types";
import { getYYYYMMDDFormat } from "../../common/utils/getCurrentTimeFormat";
import {
  useCreateClientMutation,
  useUpdateClientMutation,
} from "../../slices/clientManagement/clientManagement.service";
import { StyledSwitch } from "./ClientView.style";

export const ClientModal = ({
  isOpen,
  isEditing,
  client,
  setClientModalOpen,
  clientModalOpen,
}: {
  isOpen: boolean;
  isEditing: boolean;
  client?: {
    clientID: string;
    name: string;
    requireMemberID: boolean;
    addToUtilization: boolean;
  };
  setClientModalOpen: (open: {
    isOpen: boolean;
    isEditing: boolean;
    client?: {
      clientID: string;
      name: string;
      requireMemberID: boolean;
      addToUtilization: boolean;
    };
  }) => void;
  clientModalOpen: {
    isOpen: boolean;
    isEditing: boolean;
    client?: {
      clientID: string;
      name: string;
      requireMemberID: boolean;
      addToUtilization: boolean;
    };
  };
}) => {
  const [clientInformation, setClientInformation] = useState<{
    clientID: string;
    name: string;
    requireMemberID?: boolean;
    addToUtilization?: boolean;
  }>({
    clientID: "",
    name: "",
    requireMemberID: false,
    addToUtilization: false,
  });
  const { formatMessage } = useIntl();
  const [createClient, { error }] = useCreateClientMutation();
  const [updateClient, { error: updateError }] = useUpdateClientMutation();
  const [formError, setFormError] = useState<boolean>(false);

  useEffect(() => {
    if (isEditing && client) {
      setClientInformation(client);
    } else {
      setClientInformation({
        clientID: "",
        name: "",
        requireMemberID: false,
        addToUtilization: false,
      });
    }
  }, [isOpen, client, isEditing]);

  const handleCreateClient = async (startDate: string, endDate?: string) => {
    const createClientInput: CreateClientInput = {
      name: clientInformation.name,
      startDate,
      endDate,
      onlyMemberIdRequired: clientInformation.requireMemberID,
      generateUtilizationReports: clientInformation.addToUtilization,
    };

    await createClient(createClientInput);

    if (!error) {
      setClientModalOpen({
        ...clientModalOpen,
        isOpen: false,
        isEditing: false,
      });
    }
  };

  const handleUpdateClient = async () => {
    const createClientInput: CreateClientInput = {
      id: clientInformation.clientID,
      name: clientInformation.name,
      onlyMemberIdRequired: clientInformation.requireMemberID,
      generateUtilizationReports: clientInformation.addToUtilization,
    };

    await updateClient(createClientInput);

    if (!updateError) {
      setClientModalOpen({
        ...clientModalOpen,
        isOpen: false,
        isEditing: false,
      });
    }
  };

  const handleSubmit = async () => {
    if (!clientInformation.name) {
      return setFormError(true);
    }

    if (isEditing) {
      await handleUpdateClient();
    } else {
      await handleCreateClient(getYYYYMMDDFormat(new Date()), "");
    }
  };

  return (
    <Dialog open={isOpen}>
      <Box
        sx={{ width: 600, height: 300, padding: 1 }}
        component="form"
      >
        <Typography
          variant="h5"
          sx={{
            textAlign: "left",
            fontWeight: 700,
            marginLeft: 3,
            marginTop: 2,
          }}
        >
          {isEditing
            ? formatMessage({
                id: "clientManagement.clientView.editingClientTitle",
              })
            : formatMessage({
                id: "clientManagement.clientView.creatingClientTitle",
              })}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            textAlign: "left",
            fontWeight: 500,
            marginLeft: 3,
            marginBottom: 0,
          }}
        >
          {isEditing
            ? formatMessage({
                id: "clientManagement.clientView.editingClientSubTitle",
              })
            : formatMessage({
                id: "clientManagement.clientView.creatingClientSubTitle",
              })}
        </Typography>
        <DialogContent sx={{ marginTop: 0 }}>
          <TextField
            sx={{ width: 450 }}
            name="clientName"
            onChange={(e) =>
              setClientInformation({
                ...clientInformation,
                name: e.target.value,
              })
            }
            value={clientInformation.name}
            label={formatMessage({
              id: "clientManagement.clientView.clientModalInputLabel",
            })}
            variant="outlined"
            error={Boolean(formError)}
            helperText={
              Boolean(formError) &&
              formatMessage({
                id: "clientManagement.clientView.clientNameRequired",
              })
            }
          />
        </DialogContent>
        <Box sx={{ marginLeft: 1 }}>
          <FormControlLabel
            id="addToUtilization"
            name="addToUtilization"
            control={
              <StyledSwitch
                sx={{ marginLeft: 15 }}
                onChange={(e) =>
                  setClientInformation({
                    ...clientInformation,
                    addToUtilization: e.target.checked,
                  })
                }
                checked={clientInformation.addToUtilization}
              />
            }
            label={formatMessage({
              id: "clientManagement.clientView.addToUtilizationLabel",
            })}
            labelPlacement="start"
            value={clientInformation.addToUtilization}
          />
          <FormControlLabel
            id="requireMemberID"
            name="requireMemberID"
            control={
              <StyledSwitch
                sx={{ marginLeft: 3.5 }}
                onChange={(e) =>
                  setClientInformation({
                    ...clientInformation,
                    requireMemberID: e.target.checked,
                  })
                }
                checked={clientInformation.requireMemberID}
              />
            }
            label={formatMessage({
              id: "clientManagement.clientView.requireMemberIDLabel",
            })}
            labelPlacement="start"
            value={clientInformation.requireMemberID}
          />
        </Box>
      </Box>
      <DialogActions sx={{ marginTop: 5 }}>
        <Button
          sx={{
            width: 100,
            height: 40,
            color: "#757679",
            borderColor: "#757679",
          }}
          onClick={() =>
            setClientModalOpen({
              ...clientModalOpen,
              isOpen: false,
              isEditing: false,
            })
          }
          variant="outlined"
        >
          {formatMessage({ id: "clientManagement.clientView.cancelButton" })}
        </Button>
        <Button
          sx={{ width: 100, height: 40 }}
          variant="contained"
          onClick={handleSubmit}
        >
          {isEditing
            ? formatMessage({ id: "clientManagement.clientView.updateButton" })
            : formatMessage({ id: "clientManagement.clientView.saveButton" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
