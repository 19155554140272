import {
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListSubheader,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";

import { theme } from "../../common/theme/theme";
import {
  useFetchMailCountQuery,
  useFetchOrderStatusReportQuery,
  useFetchOutreachStatusQuery,
  useFetchResultStatusReportQuery,
} from "../../slices/exactScience/exactScience.service";

interface ListItemProps {
  desc: string;
  value: number | undefined;
  focused?: boolean;
  backgroundColor?: string;
}
const ListItemCustom: React.FC<ListItemProps> = ({
  desc,
  value,
  backgroundColor,
}) => {
  return (
    <ListItem
      divider
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: backgroundColor,
      }}
    >
      <p>{desc}</p>
      <p>{value ?? 0}</p>
    </ListItem>
  );
};
export const PerformanceMetricReport: React.FC = () => {
  const [year, setYear] = useState<string>(new Date().getFullYear().toString());
  const [month, setMonth] = useState<string>(String(new Date().getMonth() + 1));

  const {
    data: resultStatusReport,
    refetch: refetchResultStatus,
    isFetching: isResultFetching,
  } = useFetchResultStatusReportQuery({ year, month });
  const {
    data: outreachStatusReport,
    refetch: refetchOutreachStatus,
    isFetching: isOutreachFetching,
  } = useFetchOutreachStatusQuery({ year, month });
  const {
    data: mailCountReport,
    refetch: refetchMailCount,
    isFetching: isMailFetching,
  } = useFetchMailCountQuery({ year, month });
  const {
    data: orderStatusReport,
    refetch: refetchOrderStatus,
    isFetching: isOrderFetching,
  } = useFetchOrderStatusReportQuery({ year, month });

  const doReportRefetch = () => {
    refetchResultStatus();
    refetchMailCount();
    refetchOutreachStatus();
    refetchOrderStatus();
  };

  const getResultsFromReport = (key: string) => {
    return (
      resultStatusReport?.find((elem) => {
        return elem.Result === key;
      })?.Count ?? 0
    );
  };

  const getOtherResultCount = () => {
    if (!resultStatusReport || resultStatusReport.length === 0) {
      return 0;
    }
    const otherResults = resultStatusReport
      ?.filter(
        (elem) => elem.Result !== "POSITIVE" && elem.Result !== "NEGATIVE"
      )
      .map((elem) => elem.Count);

    if (!otherResults || otherResults.length === 0) {
      return 0;
    }

    return otherResults.reduce((a, b) => a + b);
  };

  return (
    <Box>
      <DatePicker
        label="Report Date"
        defaultValue={new Date()}
        views={["month", "year"]}
        disableFuture
        sx={{ mb: 2 }}
        onChange={(date) => {
          if (date) {
            setYear(date.getFullYear().toString());
            setMonth(String(date.getMonth() + 1));
            doReportRefetch();
          }
        }}
      />

      {(isResultFetching ||
        isOutreachFetching ||
        isMailFetching ||
        isOrderFetching) && <LinearProgress variant="query" />}
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 15 }}
      >
        <Grid
          item
          xs={6}
          lg={4}
        >
          <Card>
            <CardContent>
              <List>
                <ListSubheader>
                  <Typography variant="h6">No Visit Consults</Typography>
                </ListSubheader>
                <ListItemCustom
                  desc="Received"
                  value={orderStatusReport?.NoVisit.Received}
                  focused
                />
                <ListItemCustom
                  backgroundColor={theme.palette.grey[100]}
                  desc="Reviewed - Submitted within 48 hours"
                  value={orderStatusReport?.NoVisit.SubmittedOnTime}
                />
                <ListItemCustom
                  desc="Reviewed - Submitted outside of 48 hours"
                  value={orderStatusReport?.NoVisit.SubmittedLate}
                />
                <ListItemCustom
                  backgroundColor={theme.palette.grey[100]}
                  desc="Reviewed - Rejected within 48 hours"
                  value={orderStatusReport?.NoVisit.DeniedOnTime}
                />
                <ListItemCustom
                  desc="Reviewed - Rejected outside of 48 hours"
                  value={orderStatusReport?.NoVisit.DeniedLate}
                />
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={6}
          lg={4}
        >
          <Card>
            <CardContent>
              <List>
                <ListSubheader>
                  <Typography variant="h6">Pre-Test Consult</Typography>
                </ListSubheader>
                <ListItemCustom
                  desc="Received"
                  value={orderStatusReport?.PreTest.Received}
                  focused
                />
                <ListItemCustom
                  backgroundColor={theme.palette.grey[100]}
                  desc="Reviewed - Submitted"
                  value={orderStatusReport?.PreTest.Submitted}
                />
                <ListItemCustom
                  desc="Reviewed - Rejected"
                  value={orderStatusReport?.PreTest.Rejected}
                />
                <ListItemCustom
                  backgroundColor={theme.palette.grey[100]}
                  desc="Reviewed - Cancelled"
                  value={orderStatusReport?.PreTest.Cancelled}
                />
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid
          item
          xs={6}
          lg={4}
        >
          <Card>
            <CardContent>
              <List>
                <ListSubheader>
                  <Typography variant="h6">Results</Typography>
                </ListSubheader>
                <ListItemCustom
                  desc="Negative"
                  value={getResultsFromReport("NEGATIVE")}
                />
                <ListItemCustom
                  backgroundColor={theme.palette.grey[100]}
                  desc="Other"
                  value={getOtherResultCount()}
                />
                <ListItemCustom
                  desc="Positive"
                  value={getResultsFromReport("POSITIVE")}
                />
                <ListItemCustom
                  backgroundColor={theme.palette.grey[100]}
                  desc="First Positive Outreach - within 7 days"
                  value={outreachStatusReport?.OnTime}
                />
                <ListItemCustom
                  desc="First Positive Outreach - outside of 7 days"
                  value={outreachStatusReport?.Late}
                />
                <ListItemCustom
                  backgroundColor={theme.palette.grey[100]}
                  desc="Certified Mail Sent - after 3 unsuccessful outreach"
                  value={mailCountReport?.mailCount}
                />
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
