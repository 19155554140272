import { Close } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import {
  BenefitTemplate,
  ButtonFormat,
  ButtonStyle,
  ButtonTemplate,
} from "../../resources/benefits.types";
import { theme } from "../../theme/theme";
import { DropZone } from "../DropZone/DropZone";
import { FocusedHelperInput } from "../HelperInput/HelperInput";

export const ButtonEntryForm: React.FC<{
  button: ButtonTemplate;
  updateButtonValues: (button: ButtonTemplate) => void;
  removeButton: () => void;
  selected: BenefitTemplate;
  bodyIndex: number;
  buttonIndex: number;
}> = ({
  button,
  updateButtonValues,
  removeButton,
  selected,
  bodyIndex,
  buttonIndex,
}) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", justifyContent: "start" }}
    >
      <IconButton
        onClick={() => {
          removeButton();
        }}
        size="small"
        sx={{ height: "25pt" }}
      >
        <Close color="error" />
      </IconButton>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <FocusedHelperInput
            fullWidth
            label="Name"
            value={button.name ?? ""}
            onChange={(value) => {
              const updatedButton: ButtonTemplate = { ...button, name: value };
              updateButtonValues(updatedButton);
            }}
            helperText="What text the button will show"
          />
          {button.format === ButtonFormat.Open_Pdf ? (
            <DropZone
              fileFormat={["pdf"]}
              field={`button-${bodyIndex}-${buttonIndex}`}
              selected={selected}
              key={`${selected.id}-button-${bodyIndex}-${buttonIndex}-drop`}
            >
              <FocusedHelperInput
                label="Value"
                value={button.value ?? ""}
                helperText="What data the button will use to execute"
              />
            </DropZone>
          ) : (
            <FocusedHelperInput
              fullWidth
              label="Value"
              value={button.value ?? ""}
              onChange={(value) => {
                const updatedButton: ButtonTemplate = {
                  ...button,
                  value: value,
                };
                updateButtonValues(updatedButton);
              }}
              helperText="What data the button will use to execute"
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="button-format-select-label">
              Button Format
            </InputLabel>
            <Select
              fullWidth
              sx={{
                backgroundColor: theme.palette.background.paper,
                textAlign: "start",
                marginBottom: 4,
              }}
              onChange={(event) => {
                const updatedButton: ButtonTemplate = {
                  ...button,
                  format: event.target.value as ButtonFormat,
                  value: "",
                };
                updateButtonValues(updatedButton);
              }}
              labelId="button-format-select-label"
              id="button-format-select"
              value={button.format}
              label="Button Action Type"
            >
              {Object.entries(ButtonFormat).map((button, index) => {
                return (
                  <MenuItem
                    key={`menu-item-${index}`}
                    value={button[1]}
                  >
                    {button[0].replace("_", " ")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="button-style-select-label">Button Style</InputLabel>
            <Select
              fullWidth
              sx={{
                backgroundColor: theme.palette.background.paper,
                textAlign: "start",
                marginBottom: 4,
              }}
              onChange={(event) => {
                const updatedButton: ButtonTemplate = {
                  ...button,
                  style: event.target.value as ButtonStyle,
                };
                updateButtonValues(updatedButton);
              }}
              labelId="button-style-select-label"
              id="button-style-select"
              value={button.style}
              label="Button Style"
            >
              {Object.entries(ButtonStyle).map((button, index) => {
                return (
                  <MenuItem
                    key={`menu-item-${index}`}
                    value={button[1]}
                  >
                    {button[0].replace("_", " ")}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
