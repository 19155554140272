import React, { PropsWithChildren } from "react";

type ErrorBoundaryProps = PropsWithChildren<{
  errorComponent: React.FC<{
    error?: Error;
  }>;
}>;

type ErrorBoundaryState = {
  error: Error | undefined;
};

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state = {
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    this.setState({
      error,
    });
  }

  render() {
    const { error } = this.state;
    if (error) {
      const { errorComponent: ErrorComponent } = this.props;
      return <ErrorComponent error={error} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
