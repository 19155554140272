import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { object, string } from "yup";

import { ProviderInformationModel } from "../../common/resources/bulkOrders.types";
import { useUpdateProviderInformationMutation } from "../../slices/bulkOrders/bulkOrders.service";

const style = {
  position: "absolute" as "absolute",
  borderRadius: 2,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: 580,
  width: 500,
  bgcolor: "background.paper",
  p: 3,
};

const ProviderInformationModal: React.FC<{
  isOpen: boolean;
  isReadOnly: boolean;
  orderGroupID: string;
  handleClose: () => void;
  providerInfo?: ProviderInformationModel;
}> = ({ isOpen, isReadOnly, handleClose, providerInfo, orderGroupID }) => {
  useEffect(() => {
    if (providerInfo) {
      formik.setValues(providerInfo);
    } else {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, providerInfo]);

  const { formatMessage } = useIntl();
  const [updateProviderInfo] = useUpdateProviderInformationMutation();

  const providerInformationSchema = object({
    approver_first_name: string().required("Required"),
    approver_last_name: string().required("Required"),
    approver_phone_number: string()
      .required("Required")
      .matches(
        /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
        "Please enter a valid phone number"
      ),
    approver_npi: string()
      .required("Required")
      .matches(/^\d\d\d\d\d\d\d\d\d\d$/, "Must be a 10 digit NPI"),
  });

  const formik = useFormik({
    initialValues: {
      approver_first_name: "",
      approver_last_name: "",
      approver_phone_number: "",
      approver_npi: "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: providerInformationSchema,
    onSubmit: async () => {
      if (formik.isValid && !isReadOnly) {
        const {
          approver_last_name,
          approver_first_name,
          approver_npi,
          approver_phone_number,
        } = formik.values;
        updateProviderInfo({
          approver_last_name,
          approver_first_name,
          approver_npi,
          approver_phone_number,
          completed: false,
          id: orderGroupID,
        });
        handleClose();
      }
    },
  });

  return (
    <Box>
      <Modal open={isOpen}>
        <Box sx={style}>
          <Typography
            align="left"
            variant="h4"
            component="h2"
          >
            {formatMessage({ id: "bulkOrders.providerInfoModal.title" })}
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ marginTop: 1, marginBottom: 2 }}
          >
            {formatMessage({ id: "bulkOrders.providerInfoModal.description" })}
          </Typography>
          <Stack direction="column">
            <TextField
              required
              disabled={isReadOnly}
              name="approver_first_name"
              value={formik.values.approver_first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.approver_first_name &&
                Boolean(formik.errors.approver_first_name)
              }
              helperText={
                formik.touched.approver_first_name &&
                formik.errors.approver_first_name
              }
              label={formatMessage({
                id: "bulkOrders.providerInfoModal.label.firstName",
              })}
              placeholder={formatMessage({
                id: "bulkOrders.providerInfoModal.placeholder.firstName",
              })}
              InputLabelProps={{ shrink: true }}
              sx={{
                marginY: 1,
              }}
            />
            <TextField
              required
              disabled={isReadOnly}
              name="approver_last_name"
              value={formik.values.approver_last_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.approver_last_name &&
                Boolean(formik.errors.approver_last_name)
              }
              helperText={
                formik.touched.approver_last_name &&
                formik.errors.approver_last_name
              }
              label={formatMessage({
                id: "bulkOrders.providerInfoModal.label.lastName",
              })}
              placeholder={formatMessage({
                id: "bulkOrders.providerInfoModal.placeholder.lastName",
              })}
              InputLabelProps={{ shrink: true }}
              sx={{
                marginY: 1,
              }}
            />
            <TextField
              required
              disabled={isReadOnly}
              name="approver_phone_number"
              value={formik.values.approver_phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.approver_phone_number &&
                Boolean(formik.errors.approver_phone_number)
              }
              helperText={
                formik.touched.approver_phone_number &&
                formik.errors.approver_phone_number
              }
              label={formatMessage({
                id: "bulkOrders.providerInfoModal.label.phone",
              })}
              placeholder="(_ _ _)_ _ _ - _ _ _ _"
              InputLabelProps={{ shrink: true }}
              sx={{
                marginY: 1,
              }}
            />
            <TextField
              required
              disabled={isReadOnly}
              name="approver_npi"
              value={formik.values.approver_npi}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.approver_npi &&
                Boolean(formik.errors.approver_npi)
              }
              helperText={
                formik.touched.approver_npi && formik.errors.approver_npi
              }
              label={formatMessage({
                id: "bulkOrders.providerInfoModal.label.npi",
              })}
              placeholder="_ _ _ _ _ _ _ _ _ _"
              InputLabelProps={{ shrink: true }}
              sx={{
                marginY: 1,
              }}
            />
          </Stack>
          <Stack
            spacing={2}
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}
            direction="row"
          >
            <Button
              onClick={handleClose}
              sx={{ width: 122 }}
            >
              {formatMessage({
                id: "ui.button.cancel",
              })}
            </Button>
            <Button
              disabled={!formik.isValid || isReadOnly}
              sx={{ width: 122 }}
              variant="contained"
              onClick={() => formik.handleSubmit()}
            >
              {formatMessage({
                id: "bulkOrders.providerInfoModal.button.save",
              })}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProviderInformationModal;
