import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";

type AppSelectProps = {
  items: { label: string; value: string | number }[];
  helperText?: string | false | undefined;
} & SelectProps;
export const AppSelect: React.FC<AppSelectProps> = ({
  items,
  helperText,
  ...props
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        error={props.error}
        id={props.labelId}
        required={props.required}
      >
        {props.label}
      </InputLabel>
      <Select {...props}>
        {items.map((item) => (
          <MenuItem
            key={item.label}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={props.error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
