import { useAuth0 } from "@auth0/auth0-react";

import { Environments } from "../../config/flags.config";

const useFeatureControl = () => {
  const { user } = useAuth0();
  const environment: Environments =
    (process.env.REACT_APP_ENVIRONMENT as Environments) || "prod";

  const readOnly =
    user?.ad_groups.includes("SG-AdminPortal-ClientMgmt-Read") &&
    !user?.ad_groups.includes(
      "SG-AdminPortal-ClientMgmt-Write",
      "SG-AdminPortal-ClientMgmt-Admin",
      "SG-RecuroTooling-Admin"
    );
  const prodReadOnly =
    user?.ad_groups.includes("SG-AdminPortal-ClientMgmt-Read") &&
    environment === "prod" &&
    !user?.ad_groups.includes(
      "SG-AdminPortal-ClientMgmt-Write",
      "SG-AdminPortal-ClientMgmt-Admin",
      "SG-RecuroTooling-Admin"
    );

  const clientManagementAccessControl = readOnly || prodReadOnly;

  return clientManagementAccessControl;
};

export default useFeatureControl;
