export const isJavaScriptError = (error: Error) => {
  if (
    [
      EvalError.name,
      RangeError.name,
      ReferenceError.name,
      SyntaxError.name,
      TypeError.name,
      URIError.name,
    ].includes(error.name)
  ) {
    return true;
  }

  return false;
};

export const getErrorMessage = (error?: Error) => {
  if (!error) {
    return;
  }

  return error.message;
};

export const getErrorCode = (error?: Error) => {
  if (!error) {
    return "P000000";
  }

  if (isJavaScriptError(error)) {
    return "P000001";
  }

  return "P000000";
};
