import { createIntl, createIntlCache } from "react-intl";
import { boolean, date, object, string } from "yup";

import { TRANSLATIONS_FOR_USERS_LOCALE } from "../../common/language";

const usersLocale = "en";
const cache = createIntlCache();

const intl = createIntl(
  {
    locale: usersLocale,
    messages: TRANSLATIONS_FOR_USERS_LOCALE[usersLocale],
  },
  cache
);

export const sponsorSchema = object({
  label: string().required(
    intl.formatMessage({ id: "sponsorInformation.planName.error" })
  ),
  contact_number: string()
    .matches(
      /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      intl.formatMessage({ id: "sponsorInformation.supportNumber.error" })
    )
    .required(
      intl.formatMessage({ id: "sponsorInformation.supportNumber.required" })
    ),
});

const today = new Date();
const yesterday = new Date(today.setHours(0, 0, 0, 0) - 86400000);

export const programConfigSchema = object({
  workflow: string().required(
    intl.formatMessage({ id: "bulkOrders.field.programType.error" })
  ),
  opt_out: boolean().required(
    intl.formatMessage({ id: "bulkOrders.field.programType.error" })
  ),
  support_individual_interactions: boolean().required(
    intl.formatMessage({ id: "bulkOrders.field.programType.error" })
  ),
  id: string()
    .matches(
      /^\S*$/,
      intl.formatMessage({ id: "bulkOrders.field.programID.error" })
    )
    .required(
      intl.formatMessage({ id: "bulkOrders.field.programID.required" })
    ),
  expiration: date().min(yesterday).required(),
});

export const communicationSchema = object({
  send_outreach: boolean().required(),
  results_outreach: boolean().required(),
  patient_negative_results_outreach: boolean().required(),
});
