import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Modal,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";

import { useSubmitOrdersMutation } from "../../slices/bulkOrders/bulkOrders.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 5,
};

const OrderGroupConfirmationModal: React.FC<{
  isOpen: boolean;
  setModalOpen: (open: boolean) => void;
  selectedProgramID: string;
  groupOrderID: string;
}> = ({ isOpen, setModalOpen, selectedProgramID, groupOrderID }) => {
  const { formatMessage } = useIntl();
  const [submitOrders] = useSubmitOrdersMutation();

  const submitOrderGroup = () => {
    submitOrders({
      client_id: selectedProgramID,
      attestation_id: groupOrderID,
    });
    setModalOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        setModalOpen(false);
      }}
    >
      <Box sx={style}>
        <Card sx={{ p: 1 }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
            >
              {formatMessage({
                id: "bulkOrders.programDetails.submitOrdersModal.title",
              })}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
            >
              {formatMessage({
                id: "bulkOrders.programDetails.submitOrdersModal.subtitle",
              })}
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              onClick={() => setModalOpen(false)}
              sx={{ width: 140 }}
            >
              {formatMessage({
                id: "ui.button.cancel",
              })}
            </Button>
            <Button
              onClick={submitOrderGroup}
              sx={{ width: 140 }}
              variant="contained"
            >
              {formatMessage({
                id: "ui.button.submit",
              })}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
};

export default OrderGroupConfirmationModal;
