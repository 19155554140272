/* eslint-disable react-hooks/exhaustive-deps */
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ArrowForwardIos, Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppDispatch } from "../../app/store";
import CustomSnackbar from "../../common/components/CustomSnackBar/CustomSnackBar";
import { DataGridWrapper } from "../../common/components/DataGrid/DataGrid";
import { SkeletonLoadingOverlay } from "../../common/components/SkeletonDataGrid/SkeletonDataGrid";
import {
  dataColumns,
  PRODUCT_OFFERING_COLUMNS_ID,
  ProductOffering,
  ProductOfferingColumnsId,
} from "../../common/resources/clientManagement.types";
import useFeatureControl from "../../common/utils/featureControl";
import {
  useAssignPOMutation,
  useFetchProductOfferingQuery,
  useLazyFetchAllConsultTypesQuery,
} from "../../slices/clientManagement/clientManagement.service";
import {
  resetGroupState,
  selectClient,
  selectGroup,
  selectPlanStatus,
  selectSnackBarOpen,
  setSnackBarOpen,
} from "../../slices/clientManagement/clientManagementSlice";
import { Login } from "../Login/Login";
import OfferingCodeDialog from "./OfferingCodeDialog";
import PlanViewDialog from "./PlanViewDialog";
import ProductOfferingModal from "./ProductOfferingModal";

const PlanView: React.FC = () => {
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const [filteredRows, setFilteredRows] = useState<
    ProductOffering[] | undefined
  >([]);

  const { formatMessage } = useIntl();
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();

  const client = useAppSelector(selectClient);
  const group = useAppSelector(selectGroup);
  const snackBarOpen = useAppSelector(selectSnackBarOpen);
  const { clientID, groupID } = useParams();
  const featureControl = useFeatureControl();

  const { data: allProductOfferings, refetch } = useFetchProductOfferingQuery({
    clientId: clientID as string,
  });

  const [assignPOs] = useAssignPOMutation();
  const [fetchConsultTypes] = useLazyFetchAllConsultTypesQuery();
  const [confirm, setConfirm] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const status = useAppSelector(selectPlanStatus);
  const [rowSelectionModel, setRowSelectionModel] = useState<number[]>([]);
  const [planDialog, setPlanDialog] = useState(false);
  const [thisPlanData, setThisPlanData] = useState<
    ProductOffering | undefined
  >();

  const [productOfferingModalOpen, setProductOfferingModalOpen] = useState<{
    isOpen: boolean;
    isEditing: boolean;
    productOffering?: ProductOffering;
    isSavedUpdated?: boolean;
  }>({
    isOpen: false,
    isEditing: false,
    productOffering: undefined,
    isSavedUpdated: false,
  });

  useEffect(() => {
    const columnsToState: string[] = [];
    if (allProductOfferings?.data) {
      for (let i = 0; i < allProductOfferings.data.length; i++) {
        const filtered = allProductOfferings.data[i].dataColumns.filter(
          (column: dataColumns) => !column.fieldName.includes("GroupFee")
        );
        for (let j = 0; j < filtered.length; j++) {
          if (!columnsToState.includes(filtered[j].fieldName)) {
            columnsToState.push(filtered[j].fieldName);
          }
        }
      }
    }
    const baseColumns: GridColDef[] = [
      {
        field: "planName",
        headerName: `${formatMessage({
          id: "clientManagement.planView.planName",
        })}`,
        sortable: true,
        minWidth: 90,
        headerAlign: "center",
        display: "flex",
        align: "left",
        renderCell: (params: { row: ProductOffering }) => {
          const { row } = params;
          return (
            <Button
              onClick={() => {
                setPlanDialog(true);
                setThisPlanData(row);
              }}
              id={`plan-table__plan-name--${row.planName}`}
            >
              {row.clientProductOfferingID}
            </Button>
          );
        },
        valueGetter: (value, row) => {
          return row.planName;
        },
      },
      {
        field: "PEPM",
        headerName: `${formatMessage({
          id: "clientManagement.planView.PEPM",
        })}`,
        minWidth: 80,
        sortable: true,
        headerAlign: "center",
        display: "flex",
        align: "right",
        valueGetter: (value, row) => {
          return row.pepm;
        },
      },
      {
        field: "OfferingCodes",
        headerName: "Offering Codes",
        sortable: false,
        minWidth: 50,
        headerAlign: "center",
        display: "flex",
        align: "center",
        valueGetter: (value, row) => {
          return row.clientProductOfferingID;
        },
        renderCell: (renderCellProps) => (
          <OfferingCodeDialog {...renderCellProps} />
        ),
      },
      {
        field: "Status",
        headerName: "Status",
        sortable: false,
        minWidth: 80,
        headerAlign: "center",
        display: "flex",
        align: "center",
        renderCell: (row) => {
          const isActive = group.groupProductOfferings?.some(
            (offering) => offering.ClientProductOfferingID === row.id
          );
          return isActive ? (
            <Chip
              color="success"
              label="Active"
            />
          ) : (
            <Chip
              color="error"
              label="Inactive"
            />
          );
        },
      },
    ];
    const dynamicColumns: GridColDef[] = columnsToState.map(
      (columnName: string) => {
        const messageId =
          PRODUCT_OFFERING_COLUMNS_ID[columnName as ProductOfferingColumnsId];
        return {
          width: 120,
          field: columnName,
          headerName: formatMessage({
            id: messageId,
          }),
          sortable: true,
          headerAlign: "center",
          display: "flex",
          align: "center",
          renderCell: ({ row }) => {
            const dataColumn = row.dataColumns.find(
              (column: dataColumns) => column.fieldName === columnName
            );
            if (dataColumn) {
              if (dataColumn.available) {
                return (
                  <CheckCircleOutlineIcon
                    aria-label="Selected"
                    aria-selected="true"
                    color="success"
                  />
                );
              }
              return (
                <CancelOutlinedIcon
                  aria-label="Not Selected"
                  aria-selected="false"
                  color="error"
                />
              );
            }
            return "";
          },
        };
      }
    );
    const actionColumn: GridColDef[] = [
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "center",
        align: "center",
        display: "flex",
        sortable: false,
        flex: 0.1,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <EditIcon
              onClick={() => {
                fetchConsultTypes();
                setProductOfferingModalOpen({
                  isOpen: true,
                  isEditing: true,
                  productOffering: params.row,
                });
              }}
              sx={{ cursor: "pointer" }}
            />
          );
        },
        valueGetter: (value, row) => {
          return row;
        },
      },
    ];
    setColumns([...baseColumns, ...dynamicColumns, ...actionColumn]);
    setFilteredRows(allProductOfferings?.data);
    setIsLoading(false);
  }, [allProductOfferings, status, group]);

  useEffect(() => {
    if (isLoading === false && status.fetch === "loading") {
      setIsLoading(true);
    }
  }, [status]);

  useEffect(() => {
    if (productOfferingModalOpen.isSavedUpdated) {
      setProductOfferingModalOpen({
        isOpen: false,
        isEditing: false,
        isSavedUpdated: false,
      });
      refetch();
    }
  }, [productOfferingModalOpen]);

  const handleSearchChange = (searchString: string) => {
    if (searchString) {
      setFilteredRows(
        allProductOfferings?.data.filter(
          (productOffering: ProductOffering) =>
            productOffering?.clientProductOfferingID
              ?.toString()
              .toLowerCase()
              .startsWith(searchString.toLowerCase())
        )
      );
    } else {
      setFilteredRows(allProductOfferings?.data);
    }
  };

  const planBreadcrumb = () => {
    return group.groupID ? (
      <Link
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/planView/${clientID}/${groupID}`);
        }}
      >
        {group.groupID
          ? `${formatMessage({ id: "clientManagement.newGroup.Group" })} ${
              group.groupName
            }`
          : `${formatMessage({ id: "clientManagement.newGroup.Plans" })}`}
      </Link>
    ) : (
      ""
    );
  };

  return (
    <Box sx={{ textAlign: "left", px: 5 }}>
      <CustomSnackbar
        open={Boolean(snackBarOpen.open)}
        autoHideDuration={5000}
        onClose={() =>
          dispatch(setSnackBarOpen({ ...snackBarOpen, open: false }))
        }
        title={
          snackBarOpen.title ? formatMessage({ id: snackBarOpen.title }) : ""
        }
        subTitle={
          snackBarOpen.subTitle
            ? formatMessage({ id: snackBarOpen.subTitle })
            : ""
        }
        severity={snackBarOpen.severity}
      />
      <Breadcrumbs sx={{ p: 2 }}>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/clientManagement`);
          }}
        >
          {formatMessage({ id: "clientManagement.newGroup.ClientManagement" })}
        </Link>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            dispatch(resetGroupState());
            navigate(`/groups/${clientID}`);
          }}
        >
          {formatMessage({ id: "clientManagement.newGroup.Client" })}
          {` ${client.clientName}`}
        </Link>
        {planBreadcrumb()}
      </Breadcrumbs>
      <Card sx={{ py: 3, px: 2 }}>
        <Grid container>
          <Grid item>
            <Typography
              sx={{ fontWeight: 700, color: "#242459" }}
              variant="h5"
            >
              {group.groupID ? `${group.groupName}` : `${client.clientName} `}
              {group.isDemo ? (
                <Chip
                  label={formatMessage({
                    id: "clientManagement.planView.DEMO",
                  })}
                  variant="outlined"
                />
              ) : (
                ""
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs
          >
            <Grid
              container
              direction="row-reverse"
            >
              <Grid
                item
                xs={12}
                sx={{ justifyContent: "flex-end", display: "flex" }}
              >
                {featureControl ? null : (
                  <Button
                    sx={{ width: 150, height: 45 }}
                    variant="contained"
                    onClick={() => {
                      fetchConsultTypes();
                      setProductOfferingModalOpen({
                        isOpen: true,
                        isEditing: false,
                        productOffering: {
                          clientID: 0,
                          clientProductOfferingID: 0,
                          pepm: "",
                          couponId: null,
                          setToDependent: true,
                          planName: "",
                          dataColumns: [],
                        },
                      });
                    }}
                  >
                    <AddIcon />
                    {` ${formatMessage({
                      id: "clientManagement.planView.newPlan",
                    })}`}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
            ></Grid>
          </Grid>
        </Grid>
        {group.groupID ? (
          <p>
            {formatMessage({ id: "clientManagement.planView.groupSelect" })}
          </p>
        ) : (
          <p>{formatMessage({ id: "clientManagement.planView.select" })}</p>
        )}
        <Grid sx={{ m: 1 }}>
          {!group.groupID ? (
            <Button
              variant="text"
              onClick={() => {
                dispatch(resetGroupState());
                navigate(`/groups/${clientID}`);
              }}
            >
              {formatMessage({ id: "clientManagement.groupView.groups" })}
            </Button>
          ) : null}
          <Button
            sx={{ textDecoration: "underline" }}
            variant="text"
            onClick={() => {
              navigate(`/planView/${clientID}/${groupID}`);
            }}
          >
            {group.groupID
              ? `${group.groupName} ${formatMessage({
                  id: "clientManagement.planView.Assignment",
                })}`
              : `${formatMessage({
                  id: "clientManagement.planView.plans",
                })}`}
          </Button>
          <TextField
            onChange={(e) => handleSearchChange(e.target.value)}
            sx={{ borderRadius: 1, marginX: 2, width: 490 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            label={formatMessage({ id: "clientManagement.planView.planName" })}
            placeholder={formatMessage({
              id: "clientManagement.planView.placeholderPlan",
            })}
          />
        </Grid>
        <DataGridWrapper
          pageSize={10}
          pageNumber={0}
          sortFieldName="planName"
          sortDirection="desc"
          {...(!group.groupID || featureControl
            ? {}
            : { checkboxSelection: true })}
          columns={columns}
          columnVisibilityModel={{
            ...(group.groupID
              ? {}
              : {
                  Status: false,
                }),
            ...(featureControl
              ? {
                  actions: false,
                }
              : {}),
          }}
          slots={{
            loadingOverlay: SkeletonLoadingOverlay,
            noRowsOverlay: () => (
              <Stack
                sx={{ fontWeight: 700, fontSize: "16px" }}
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                {formatMessage({ id: "clientManagement.planView.noPlans" })}
              </Stack>
            ),
          }}
          loading={isLoading}
          rows={filteredRows || []}
          rowIdField="clientProductOfferingID"
          onRowSelectionModelChange={(rowSelection) => {
            if (rowSelection.length > 1) {
              setRowSelectionModel([
                Number(rowSelection[rowSelection.length - 1]),
              ]);
            } else {
              setRowSelectionModel(rowSelection.map((id) => Number(id)));
            }
          }}
          disableRowSelectionOnClick
          disableColumnMenu
          rowSelectionModel={rowSelectionModel}
        />
        <br />
        {!group.groupID || featureControl ? (
          ""
        ) : (
          <Grid
            item
            xs={12}
            sx={{ justifyContent: "flex-end", display: "flex" }}
          >
            <Button
              id="groupDispatch"
              variant="contained"
              type="submit"
              onClick={() => setConfirm(true)}
            >
              {formatMessage({ id: "clientManagement.planView.activateGroup" })}
            </Button>
          </Grid>
        )}
        <PlanViewDialog
          group={group}
          open={planDialog}
          onClose={() => setPlanDialog(false)}
          onSelect={() => {
            setPlanDialog(false);
          }}
          planData={thisPlanData}
        />
      </Card>
      <Dialog open={confirm}>
        <Paper sx={{ padding: 2, background: "#F5F6FF" }}>
          <Grid>
            <DialogTitle>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "left",
                  marginY: 2,
                }}
              >
                {formatMessage({
                  id: "clientManagement.planView.confirmChanges",
                })}
              </Typography>
              <Typography sx={{ fontSize: 16, textAlign: "left" }}>
                {`${rowSelectionModel[0]} `}
                {formatMessage({
                  id: "clientManagement.planView.activated",
                })}
                {` ${group.groupName}`}.
              </Typography>
              <Typography sx={{ fontSize: 16, marginY: 1, textAlign: "left" }}>
                {formatMessage({
                  id: "clientManagement.planView.confirmChangesText",
                })}
              </Typography>
            </DialogTitle>
          </Grid>
          <DialogActions>
            <Button
              onClick={() => setConfirm(false)}
              variant="outlined"
              sx={{ width: "10rem", height: "3rem" }}
            >
              {formatMessage({ id: "clientManagement.newGroup.cancel" })}
            </Button>
            <Button
              id="newGroupDispatch"
              sx={{ width: 150, height: 45 }}
              variant="contained"
              endIcon={<ArrowForwardIos />}
              onClick={async () => {
                if (
                  group.groupID &&
                  !group.groupProductOfferings?.some(
                    (offering) =>
                      offering.ClientProductOfferingID === rowSelectionModel[0]
                  )
                ) {
                  await assignPOs({
                    groupID: group.groupID ?? "",
                    productOfferings: [
                      {
                        ClientProductOfferingID:
                          rowSelectionModel[0].toString() ?? "",
                        CustomProductOfferingID: "",
                      },
                    ],
                  });
                } else {
                  dispatch(
                    setSnackBarOpen({
                      open: true,
                      severity: "error",
                      title: "clientManagement.assignPO.errorTitle",
                      subTitle: "clientManagement.assignPO.errorNoneSelected",
                    })
                  );
                }
                setConfirm(false);
              }}
              fullWidth
            >
              {formatMessage({ id: "clientManagement.newGroup.saveChanges" })}
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <ProductOfferingModal
        isOpen={productOfferingModalOpen.isOpen}
        isEditing={productOfferingModalOpen.isEditing}
        setCreateProductOfferingOpen={setProductOfferingModalOpen}
        productOffering={productOfferingModalOpen.productOffering}
      />
    </Box>
  );
};

export default withAuthenticationRequired(PlanView, {
  onRedirecting: () => <Login />,
});
