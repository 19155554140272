/* eslint-disable react-hooks/exhaustive-deps */
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Search } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Chip,
  Grid,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppDispatch } from "../../app/store";
import CustomSnackbar from "../../common/components/CustomSnackBar/CustomSnackBar";
import { DataGridWrapper } from "../../common/components/DataGrid/DataGrid";
import { SkeletonLoadingOverlay } from "../../common/components/SkeletonDataGrid/SkeletonDataGrid";
import { ClientTemplate } from "../../common/resources/clientManagement.types";
import useFeatureControl from "../../common/utils/featureControl";
import {
  useFetchAllClientsQuery,
  useLazyFetchAllGroupsByClientQuery,
  useLazyFetchAllPayersQuery,
} from "../../slices/clientManagement/clientManagement.service";
import {
  selectClientsList,
  selectClientStatus,
  selectSnackBarOpen,
  setClientID,
  setClientInformation,
  setSnackBarOpen,
} from "../../slices/clientManagement/clientManagementSlice";
import { Login } from "../Login/Login";
import { ClientModal } from "./ClientModal";

const ClientManagement: React.FC = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const clientList = useAppSelector(selectClientsList);
  const status = useAppSelector(selectClientStatus);
  const featureControl = useFeatureControl();

  const [isLoading, setIsLoading] = useState(false);
  const [getAllPayers] = useLazyFetchAllPayersQuery();
  const [getGroupsList] = useLazyFetchAllGroupsByClientQuery();
  const navigate = useNavigate();
  const [clientModalOpen, setClientModalOpen] = useState<{
    isOpen: boolean;
    isEditing: boolean;
    client?: {
      clientID: string;
      name: string;
      requireMemberID: boolean;
      addToUtilization: boolean;
    };
  }>({
    isOpen: false,
    isEditing: false,
  });

  useFetchAllClientsQuery();

  const [filteredRows, setFilteredRows] = useState<GridRowsProp>([]);

  const columns: GridColDef[] = [
    {
      field: "clientID",
      headerName: "ClientID",
      sortable: false,
      headerAlign: "center",
      display: "flex",
      align: "center",
      minWidth: 150,
    },
    {
      field: "clientName",
      headerName: "Client Name",
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
      minWidth: 200,
      flex: 2,
      renderCell: (rowSelection) => {
        return (
          <Button
            sx={{
              "&.MuiButton-root:hover": {
                bgcolor: "transparent",
              },
            }}
            onClick={() => {
              getGroups(rowSelection.row);
            }}
          >
            {rowSelection.row.clientName}
          </Button>
        );
      },
    },
    {
      field: "active",
      headerName: "Status",
      sortable: false,
      headerAlign: "center",
      display: "flex",
      align: "center",
      minWidth: 150,
      renderCell: (params) => {
        const { row } = params;
        return row.active ? (
          <Chip
            color="success"
            label="Active"
          />
        ) : (
          <Chip
            color="error"
            label="Inactive"
          />
        );
      },
    },
    {
      field: "totalGroups",
      headerName: "Total Groups",
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
      minWidth: 150,
    },
    {
      field: "totalPlans",
      headerName: "Total Plans",
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
      minWidth: 150,
    },
    {
      field: "activePrimaryMembers",
      headerName: "Active Primary Members",
      sortable: true,
      headerAlign: "center",
      display: "flex",
      align: "center",
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      display: "flex",
      align: "center",
      sortable: false,
      minWidth: 150,
      renderCell: (params) => {
        const { row } = params;
        return (
          <EditIcon
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setClientModalOpen({
                isOpen: true,
                isEditing: true,
                client: {
                  clientID: row.clientID,
                  name: row.clientName,
                  requireMemberID: row.onlyMemberIdRequired,
                  addToUtilization: row.generateUtilizationReports,
                },
              });
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (clientList.length > 0) {
      setFilteredRows(clientList);
      setIsLoading(false);
    }

    if (isLoading === false && status.fetch === "loading") {
      setIsLoading(true);
    }

    if (isLoading === true && status.fetch === "success") {
      setFilteredRows(clientList);
      setIsLoading(false);
    }
  }, [status]);

  const snackBarOpen = useAppSelector(selectSnackBarOpen);

  const { formatMessage } = useIntl();

  const handleSearchChange = (searchString: string) => {
    if (searchString) {
      setFilteredRows(
        clientList.filter((client) =>
          client.clientName.toLowerCase().startsWith(searchString.toLowerCase())
        )
      );
    } else {
      setFilteredRows(clientList);
    }
  };

  const getGroups = (row: ClientTemplate) => {
    getAllPayers();
    getGroupsList({ clientID: row.clientID.toString() });
    dispatch(setClientID(row.clientID.toString()));
    dispatch(setClientInformation(row));
    navigate(`/groups/${row.clientID}`);
  };

  return (
    <Box sx={{ textAlign: "left", px: 5 }}>
      <Breadcrumbs sx={{ p: 2 }}>
        <Link
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/clientManagement`);
          }}
        >
          {formatMessage({ id: "clientManagement.newGroup.ClientManagement" })}
        </Link>
      </Breadcrumbs>
      <Card sx={{ px: 2, py: 3 }}>
        <Grid container>
          <Grid item>
            <Typography
              sx={{ fontWeight: 700 }}
              variant="h5"
            >
              {formatMessage({ id: "clientManagement.newGroup.Clients" })}
            </Typography>
          </Grid>
          <Grid
            item
            xs
          >
            <Grid
              container
              direction="row-reverse"
            >
              <Grid item>
                {featureControl ? null : (
                  <Button
                    sx={{ width: 150, height: 45 }}
                    variant="contained"
                    onClick={() =>
                      setClientModalOpen({
                        ...clientModalOpen,
                        isOpen: true,
                        isEditing: false,
                      })
                    }
                  >
                    {formatMessage({
                      id: "clientManagement.clientView.creatingClientTitle",
                    })}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <p>{formatMessage({ id: "clientManagement.clientView.clientDesc" })}</p>
        <TextField
          onChange={(e) => handleSearchChange(e.target.value)}
          sx={{ borderRadius: 1, pb: 2 }}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          label={formatMessage({ id: "clientManagement.newGroup.clientLabel" })}
          placeholder={formatMessage({
            id: "clientManagement.clientView.placeholder",
          })}
        />
        <CustomSnackbar
          open={Boolean(snackBarOpen.open)}
          autoHideDuration={5000}
          onClose={() =>
            dispatch(setSnackBarOpen({ ...snackBarOpen, open: false }))
          }
          title={
            snackBarOpen.title ? formatMessage({ id: snackBarOpen.title }) : ""
          }
          subTitle={
            snackBarOpen.subTitle
              ? formatMessage({ id: snackBarOpen.subTitle })
              : ""
          }
          severity={snackBarOpen.severity}
        />
        <DataGridWrapper
          pageSize={10}
          pageNumber={0}
          sortFieldName="clientID"
          sortDirection="desc"
          checkboxSelection={false}
          columns={columns}
          columnVisibilityModel={
            featureControl
              ? {
                  actions: false,
                }
              : {}
          }
          slots={{
            loadingOverlay: SkeletonLoadingOverlay,
          }}
          loading={isLoading}
          rows={filteredRows}
          rowIdField="clientID"
          disableRowSelectionOnClick
          disableColumnMenu
        />
        <ClientModal
          clientModalOpen={clientModalOpen}
          client={clientModalOpen.client}
          isEditing={clientModalOpen.isEditing}
          isOpen={clientModalOpen.isOpen}
          setClientModalOpen={setClientModalOpen}
        />
      </Card>
    </Box>
  );
};

export default withAuthenticationRequired(ClientManagement, {
  onRedirecting: () => <Login />,
});
