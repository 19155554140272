import {
  Box,
  Card,
  Divider,
  InputLabel,
  List,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  FILTERED_FIELDS,
  MemberTemplate,
  Status,
} from "../../common/resources/memberActions.types";
import {
  memberToKeep,
  updateMemberStatus,
} from "../../slices/memberMerge/memberMergeSlice";

export const decideBackColor = (
  field: keyof MemberTemplate,
  keepMember: MemberTemplate,
  member: MemberTemplate
): string => {
  const isGoodField = keepMember?.hasOwnProperty(field);
  let returnVal: string = "";
  const shouldCheckMatch =
    keepMember && isGoodField && member.status !== Status.doNothing;
  if (
    shouldCheckMatch &&
    keepMember[field]?.toString().toLowerCase() !==
      member[field]?.toString().toLowerCase()
  ) {
    returnVal = "#F10000";
  }
  return returnVal;
};

const MemberCard: React.FC<{ member: MemberTemplate }> = ({ member }) => {
  const { formatMessage } = useIntl();
  const keepMember = useAppSelector(memberToKeep);
  const dispatch = useAppDispatch();

  return (
    <Card
      sx={{
        minWidth: 150,
        maxWidth: 260,
        borderRadius: "5pt",
        border: 2,
        margin: 2,
        borderColor:
          member.status === Status.keep
            ? "blue"
            : member.status === Status.delete
            ? "#F10000"
            : "",
      }}
    >
      {Object.keys(member).map((elem, index) => {
        const k = elem as keyof MemberTemplate;
        if (FILTERED_FIELDS.includes(elem)) {
          return "";
        }
        return (
          <Box
            key={`card-${member.memberID}-${index}`}
            sx={{ width: "100%" }}
          >
            <List
              sx={{
                backgroundColor: decideBackColor(k, keepMember!, member),
                textAlign: "start",
                padding: 1,
              }}
            >
              <Typography
                component="span"
                style={{ fontWeight: "700", fontSize: "14px" }}
              >
                {k.toUpperCase()}:
              </Typography>
              {` ${member[k]}`}
            </List>
            <Divider />
          </Box>
        );
      })}
      <Box style={{ margin: "5px", padding: 2 }}>
        <InputLabel>
          {formatMessage({ id: "memberActions.input.card" })}
        </InputLabel>
        <Select
          value={member.status ? member.status : ""}
          fullWidth
          onChange={(event) => {
            const newStatus: Status = event.target.value as Status;
            dispatch(
              updateMemberStatus({
                memberID: member.memberID,
                status: newStatus,
              })
            );
          }}
        >
          <MenuItem
            value={Status.keep}
            disabled={keepMember ? true : false}
          >
            {formatMessage({ id: "memberActions.menu.keep" })}
          </MenuItem>
          <MenuItem value={Status.delete}>
            {formatMessage({ id: "memberActions.menu.delete" })}
          </MenuItem>
        </Select>
      </Box>
    </Card>
  );
};

export default MemberCard;
