import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Card,
  Container,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AppRoute } from "../../common/resources/routes";
import {
  selectClient,
  selectConnection,
  selectDomain,
  selectSSO,
  setClient,
  setConnection,
  setDomain,
  setSSO,
} from "../../slices/sso/ssoSlice";
import { Login } from "../Login/Login";

const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;

const SSOTool: React.FC = () => {
  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const client = useAppSelector(selectClient);
  const connection = useAppSelector(selectConnection);
  const domain = useAppSelector(selectDomain);
  const sso = useAppSelector(selectSSO);
  const navigate = useNavigate();

  const [clientIdState, setClientIdState] = useState<string | undefined>(
    client || ""
  );
  const [connectionState, setConnectionState] = useState<string>(
    connection || ""
  );
  const [domainState, setDomainState] = useState<string>(
    domain || REACT_APP_AUTH0_DOMAIN
  );
  const [secretState, setSecretState] = useState<string | undefined>(sso || "");

  const [error, setError] = useState<string>("");
  const [errorDescription, setErrorDescription] = useState<string>("");
  const [jwt, setJwt] = useState<string>("");

  const getAxiosOptions = (
    client: string,
    connection: string,
    sso: string,
    domain: string,
    code: string
  ) => ({
    method: "POST",
    url: `https://${domain}/oauth/token`,
    headers: { "content-type": "application/x-www-form-urlencoded" },
    data: {
      grant_type: "authorization_code",
      client_id: client,
      client_secret: sso,
      code,
      redirect_uri: `${
        window.location.href.split("?")[0]
      }?connection=${connection}`,
    },
  });

  useEffect(() => {
    const qs = new URL(window.location.href).searchParams;
    const newError = qs.get("error");
    const newErrorDescription = qs.get("error_description");

    if (newError) {
      setError(newError);
      if (newErrorDescription) {
        setErrorDescription(newErrorDescription);
      }
      return;
    }

    const code = qs.get("code");

    if (!code) return;

    dispatch(setClient(client || ""));
    dispatch(setConnection(connection || ""));
    dispatch(setSSO(sso || ""));
    dispatch(setDomain(domain || REACT_APP_AUTH0_DOMAIN));

    if (!sso) {
      window.alert("Please set the Auth App Secret and try again");
      return;
    }

    const options = getAxiosOptions(client, connection, sso, domain, code);
    axios
      .request(options)
      .then(function (response) {
        if (response.data.id_token) {
          setJwt(response.data.id_token);
        }
      })
      .catch(function (error) {
        setError(`${error.name}`);
        setErrorDescription(`${error.message}`);
      });
  }, [
    client,
    connection,
    domain,
    sso,
    setError,
    setErrorDescription,
    dispatch,
  ]);

  const getParsedJwt = <T extends object = { [k: string]: string | number }>(
    token: string
  ): T | undefined => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch {
      return undefined;
    }
  };

  const resetSession = () => {
    setJwt("");

    dispatch(setClient(""));
    dispatch(setConnection(""));
    dispatch(setSSO(""));
    dispatch(setDomain(REACT_APP_AUTH0_DOMAIN));

    setError("");
    setErrorDescription("");

    navigate(AppRoute.ssoTool);
  };

  const setSSOSecret = (val: string) => {
    dispatch(setSSO(val));
    setSecretState(val);
  };

  const setSSOClient = (val: string) => {
    dispatch(setClient(val));
    setClientIdState(val);
  };

  const setSSOConnection = (val: string) => {
    dispatch(setConnection(val));
    setConnectionState(val);
  };

  const setSSODomain = (val: string) => {
    dispatch(setDomain(val));
    setDomainState(val);
  };

  const tryConnection = (e: any) => {
    e.preventDefault();

    window.location.assign(
      `https://${domain}/authorize?client_id=${client}&response_type=code&connection=${connection}&prompt=login&scope=openid%20profile&redirect_uri=${window.location.href}?connection=${connection}`
    );
  };

  const renderJwt = () => {
    const jwtObject = getParsedJwt(jwt);

    return (
      <List component="dl">
        {jwtObject &&
          Object.keys(jwtObject).map((key) => (
            <ListItem key={key}>
              <ListItemText
                primary={key}
                primaryTypographyProps={{
                  component: "dt",
                  variant: "body1",
                }}
                secondary={jwtObject[key]}
                secondaryTypographyProps={{
                  component: "dd",
                  variant: "body2",
                  sx: { ml: 1 },
                }}
              />
            </ListItem>
          ))}
      </List>
    );
  };

  if (error && !jwt) {
    return (
      <Container maxWidth="sm">
        <Card sx={{ p: 2 }}>
          <Stack spacing={2}>
            <Typography
              variant="h6"
              component="h1"
            >
              {formatMessage({ id: "ssoTool.error" })}
            </Typography>
            <Typography>
              {error}
              {formatMessage({ id: "ssoTool.errorBody" })}
            </Typography>
            <Typography variant="body2">{errorDescription}</Typography>
            <Box>
              <Button
                variant="contained"
                onClick={resetSession}
              >
                {formatMessage({ id: "ssoTool.errorReset" })}
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
    );
  }

  if (jwt) {
    return (
      <Container
        maxWidth="sm"
        sx={{ mt: 2, textAlign: "left" }}
      >
        <Card sx={{ p: 2 }}>
          <Typography
            variant="h6"
            component="h1"
          >
            {formatMessage({ id: "ssoTool.authSuccess" })}
          </Typography>
          {renderJwt()}

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, padding: "1rem 0" }}
          >
            {formatMessage({ id: "ssoTool.addClient" })}
          </Typography>

          <Stack spacing={2}>
            <TextField
              value={client}
              label="ApplicationClientIDWeb"
              disabled
            />
            <TextField
              value={domain}
              label="AuthDomain"
              disabled
            />
            <TextField
              value={connection}
              label="ConnectionName"
              disabled
            />
            <Button
              variant="contained"
              onClick={resetSession}
            >
              {formatMessage({ id: "ssoTool.resetSession" })}
            </Button>
          </Stack>
        </Card>
      </Container>
    );
  }

  return (
    <Container
      maxWidth="sm"
      sx={{ textAlign: "left" }}
    >
      <Card sx={{ p: 2 }}>
        <Typography
          variant="h6"
          component="h1"
        >
          {formatMessage({ id: "ssoTool.confirmIdp" })}
        </Typography>
        <Typography
          variant="caption"
          component="p"
          sx={{ mb: 3 }}
        >
          {formatMessage(
            { id: "ssoTool.caption" },
            { href: window.location.href }
          )}
        </Typography>
        <Stack
          spacing={2}
          component="form"
          onSubmit={tryConnection}
        >
          <TextField
            name="authenticationServiceDomain"
            value={domainState}
            label="Authentication Service Domain"
            onChange={(e) => {
              setSSODomain(e.target.value);
            }}
            helperText={formatMessage({
              id: "ssoTool.authenticationServiceDomain",
            })}
          />
          <TextField
            name="ssoName"
            value={connectionState}
            label="SSO Name"
            onChange={(e) => {
              setSSOConnection(e.target.value);
            }}
            helperText={formatMessage({ id: "ssoTool.ssoName" })}
          />
          <TextField
            name="clientID"
            value={clientIdState}
            label="Client ID"
            onChange={(e) => {
              setSSOClient(e.target.value);
            }}
            helperText={formatMessage({ id: "ssoTool.clientID" })}
          />
          <TextField
            name="authenticationClientSecret"
            value={secretState}
            label="Authentication Client Secret"
            onChange={(e) => {
              setSSOSecret(e.target.value);
            }}
            helperText={formatMessage({
              id: "ssoTool.authenticationClientSecret",
            })}
          />
          <Button
            type="submit"
            variant="contained"
          >
            {formatMessage({ id: "ssoTool.testIntegration" })}
          </Button>
        </Stack>
      </Card>
    </Container>
  );
};

export default withAuthenticationRequired(SSOTool, {
  onRedirecting: () => <Login />,
});
