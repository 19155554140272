import React from "react";
import { useRouteError } from "react-router-dom";

export const withRouterError = (Component: React.FC<{ error?: Error }>) => {
  return () => {
    const error = useRouteError() as Error;

    return <Component error={error} />;
  };
};
