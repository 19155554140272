import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  ClientOfferingCode,
  GroupOfferingCode,
  ProductOffering,
} from "../../common/resources/clientManagement.types";
import useFeatureControl from "../../common/utils/featureControl";
import {
  useAssignPOMutation,
  useLazyCreateSOCQuery,
} from "../../slices/clientManagement/clientManagement.service";
import {
  selectClient,
  selectGroup,
  setSnackBarOpen,
} from "../../slices/clientManagement/clientManagementSlice";

type OfferingCodeDialogCellProps = {
  row: ProductOffering;
};

const OfferingCodeDialog: React.FC<OfferingCodeDialogCellProps> = ({ row }) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const group = useAppSelector(selectGroup);
  const client = useAppSelector(selectClient);
  const [code, setCode] = useState("");
  const { formatMessage } = useIntl();
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [createSOC] = useLazyCreateSOCQuery();
  const [assignPOs] = useAssignPOMutation();
  const featureControl = useFeatureControl();

  const clientOfferings =
    client.clientProductOfferings?.filter(
      (code) => code.ClientProductOfferingID === row.clientProductOfferingID
    ) ?? [];

  const groupOfferings =
    group.groupProductOfferings?.filter(
      (code) =>
        code.CustomGroupOfferingID &&
        code.ClientProductOfferingID === row.clientProductOfferingID
    ) ?? [];

  const combinedOfferings = [...groupOfferings, ...clientOfferings];

  const SOCTable = () => {
    const SOC = (code: ClientOfferingCode | GroupOfferingCode) => {
      return "CustomClientProductOfferingID" in code
        ? code.CustomClientProductOfferingID
        : code.CustomGroupOfferingID;
    };

    return (
      <>
        {combinedOfferings?.length === 0 ? (
          <TableRow>
            <TableCell align="center">
              {formatMessage({
                id: "clientManagement.offeringCodeDialog.noOC",
              })}
            </TableCell>
          </TableRow>
        ) : (
          combinedOfferings
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((code) => (
              <TableRow key={SOC(code)}>
                <TableCell
                  align="center"
                  sx={{ fontWeight: 700 }}
                >
                  {SOC(code)}
                </TableCell>
                {group.groupID ? (
                  <TableCell align="center">
                    {groupOfferings.includes(code as GroupOfferingCode)
                      ? "Group"
                      : "Client"}
                  </TableCell>
                ) : null}
              </TableRow>
            ))
        )}
      </>
    );
  };

  const count = () => {
    let num = 0;
    if (group.groupProductOfferings) {
      const groupMatchingCodes = group.groupProductOfferings.filter(
        (code) =>
          code.CustomGroupOfferingID &&
          code.ClientProductOfferingID === row.clientProductOfferingID
      );
      num += groupMatchingCodes.length;
    }
    if (client.clientProductOfferings) {
      const clientMatchingCodes = client.clientProductOfferings.filter(
        (code) => code.ClientProductOfferingID === row.clientProductOfferingID
      );
      num += clientMatchingCodes.length;
    }
    return num;
  };

  const saveSOC = async () => {
    if (
      !group.groupID &&
      clientOfferings.filter(
        (SOC) => SOC.CustomClientProductOfferingID === code
      ).length === 0
    ) {
      await createSOC({
        ClientProductOfferingID: row.clientProductOfferingID as number,
        CustomClientProductOfferingID: code,
      });
    } else if (
      group.groupID &&
      groupOfferings.filter((SOC) => SOC.CustomGroupOfferingID === code)
        .length === 0
    ) {
      await assignPOs({
        groupID: group.groupID,
        productOfferings: [
          {
            ClientProductOfferingID:
              row.clientProductOfferingID?.toString() ?? "",
            CustomProductOfferingID: code,
          },
        ],
      });
    } else {
      dispatch(
        setSnackBarOpen({
          open: true,
          severity: "error",
          title: "clientManagement.assignPO.errorTitle",
          subTitle: "clientManagement.offeringCodeDialog.SOCExists",
        })
      );
    }
    setCode("");
  };

  return (
    <Box>
      <Button
        sx={{ minWidth: "30px", textDecoration: "underline" }}
        onClick={() => setOpen(true)}
      >
        {count()}
      </Button>
      <Dialog
        onClose={() => {
          setOpen(false);
          setPage(0);
          setCode("");
        }}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Card sx={{ background: "#F5F6FF" }}>
          <Grid sx={{ padding: 4 }}>
            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle
                sx={{ display: "inline", justifyContent: "flex-start" }}
              >
                {row.clientProductOfferingID}
              </DialogTitle>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                  setPage(0);
                  setCode("");
                }}
              />
            </Grid>
            <TableContainer>
              <Table>
                <TableHead
                  sx={{
                    background: "#DCE6F5",
                    color: "#242459",
                    textAlign: "center",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "normal",
                  }}
                >
                  <TableRow>
                    <TableCell align="center">
                      {group.groupID
                        ? formatMessage({
                            id: "clientManagement.offeringCodeDialog.code",
                          })
                        : formatMessage({
                            id: "clientManagement.offeringCodeDialog.clientCode",
                          })}
                    </TableCell>
                    {group.groupID ? (
                      <TableCell align="center">
                        {formatMessage({
                          id: "clientManagement.offeringCodeDialog.level",
                        })}
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <SOCTable />
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      count={count()}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={(
                        event: React.MouseEvent<HTMLButtonElement> | null,
                        newPage: number
                      ) => setPage(newPage)}
                    />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {!featureControl ? (
              <>
                <TextField
                  value={code}
                  fullWidth
                  label={formatMessage({
                    id: "clientManagement.offeringCodeDialog.enterCode",
                  })}
                  onChange={(e) => {
                    setCode(e.target.value);
                  }}
                />
                <Button
                  sx={{ marginTop: 2 }}
                  fullWidth
                  variant="contained"
                  onClick={async () => {
                    if (group.groupID) {
                      setOpen2(true);
                    } else {
                      saveSOC();
                    }
                  }}
                >
                  {!group.groupID
                    ? `${formatMessage({
                        id: "clientManagement.offeringCodeDialog.addNewClientOC",
                      })}`
                    : `${formatMessage({
                        id: "clientManagement.offeringCodeDialog.addNewGroupOC",
                      })}`}
                </Button>
              </>
            ) : null}
          </Grid>
        </Card>
        {group.groupID ? (
          <Dialog
            open={open2}
            onClose={() => {
              setOpen2(false);
              setPage(0);
              setCode("");
            }}
          >
            <Paper sx={{ padding: 2, background: "#F5F6FF" }}>
              <Grid sx={{ maxWidth: 450 }}>
                <DialogTitle>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "left",
                      marginY: 2,
                    }}
                  >
                    {formatMessage({
                      id: "clientManagement.planView.confirmChanges",
                    })}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, marginY: 2, textAlign: "left" }}
                  >
                    {formatMessage({
                      id: "clientManagement.planView.SOCode",
                    })}
                    {` ${row.clientProductOfferingID}`}.
                  </Typography>
                  <Typography sx={{ fontSize: 16, textAlign: "left" }}>
                    {`${row.clientProductOfferingID} `}
                    {formatMessage({
                      id: "clientManagement.planView.activated",
                    })}
                    {` ${group.groupName}. `}
                    {formatMessage({
                      id: "clientManagement.planView.confirmChangesText",
                    })}
                  </Typography>
                </DialogTitle>
              </Grid>
              <Grid>
                <DialogActions>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setOpen2(false);
                      setPage(0);
                      setCode("");
                    }}
                  >
                    {formatMessage({ id: "clientManagement.newGroup.cancel" })}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      saveSOC();
                      setOpen2(false);
                    }}
                  >
                    {formatMessage({
                      id: "clientManagement.newGroup.saveChanges",
                    })}
                  </Button>
                </DialogActions>
              </Grid>
            </Paper>
          </Dialog>
        ) : null}
      </Dialog>
    </Box>
  );
};

export default OfferingCodeDialog;
