import { rest } from "msw";

import { allBenefits, allProducts } from "../common/resources/benefits.mock";
import { allReportTypes } from "../common/resources/reporting.mock";

export const BENEFITS_URL = process.env.REACT_APP_BENEFITS_URL || "";

const basePath = (path: string) => {
  return `${BENEFITS_URL}${path}`;
};

export const fetchBenefitsMocks = rest.get(
  basePath("/benefits/api/benefit"),
  (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: allBenefits,
      })
    );
  }
);

export const fetchProductsMocks = rest.get(
  basePath("/benefits/api/product"),
  (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: allProducts,
      })
    );
  }
);

export const fetchReportTypes = rest.get(
  basePath("/reporting/api/types"),
  (_req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: allReportTypes,
      })
    );
  }
);

export const handlers = [
  fetchBenefitsMocks,
  fetchProductsMocks,
  fetchReportTypes,
];
