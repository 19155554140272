import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import ErrorMessage from "./ErrorMessage";
import { withRouterError } from "./withRouterError";

export type PageErrorProps = {
  error?: Error;
};
export const PageError: React.FC<PageErrorProps> = ({ error }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Card>
        <CardHeader
          title={formatMessage({ id: "systemError.header" })}
          titleTypographyProps={{ component: "h1", variant: "subtitle1" }}
        />
        <CardContent>
          <Stack spacing={4}>
            <ErrorMessage error={error} />
            <Typography>{formatMessage({ id: "systemError.body" })}</Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ alignSelf: "center" }}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              {formatMessage({ id: "systemError.button.return" })}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default withRouterError(PageError);
