export enum Status {
  keep = "keep",
  delete = "delete",
  doNothing = "",
}

export type MemberTemplate = {
  memberID: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  emailAddress?: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: number;
  clientMemberID?: string;
  active?: boolean;
  status?: Status;
};

export type MemberMergeBody = {
  memberID: number;
};

export type MergeMember = {
  keepMember?: number;
  deleteMembers: MemberMergeBody[];
};

export const FILTERED_FIELDS = ["status", "active", "memberID"];
