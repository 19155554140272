import { LanguageKeys } from "../language";
import { getYYYYMMDDFormat } from "../utils/getCurrentTimeFormat";

export type ClientTemplate = {
  clientID: string;
  clientName: string;
  startDate?: string | null;
  endDate?: string | null;
  active?: boolean;
  TotalGroups: number;
  TotalPlans: number;
  PrimaryMemberCount: number;
  OnlyMemberIdRequired: boolean;
  GenerateUtilizationReports: boolean;
  clientProductOfferings?: ClientOfferingCode[] | [];
};

export type Group = {
  startDate: string | Date;
  endDate: string | Date | null;
  groupID?: string;
  groupName: string;
  isDemo: boolean;
  clientGroupID: string;
  groupEmailFul: boolean;
  groupHardCopyFul: boolean;
  payerID?: string | null;
  clientID: string;
  primaryMemberCount?: number;
  totalPlans?: number;
  active?: boolean;
  clientName?: string;
  groupProductOfferings?: GroupOfferingCode[] | [];
};

const CreateDefaultGroup = (clientId: string): Group => {
  return {
    groupName: "",
    clientGroupID: "",
    payerID: "",
    clientID: clientId,
    startDate: Date(),
    endDate: null,
    isDemo: false,
    groupEmailFul: true,
    groupHardCopyFul: false,
  };
};

const groupForm = (client: ClientTemplate, values: Group) => {
  return {
    clientID: client.clientID?.toString(),
    groupID: values.groupID,
    groupName: values.groupName,
    clientGroupID: values.clientGroupID,
    payerID: values.payerID?.toString(),
    startDate: getYYYYMMDDFormat(new Date(values.startDate.toString())),
    endDate: values.endDate
      ? getYYYYMMDDFormat(new Date(values.endDate.toString()))
      : null,
    isDemo: values.isDemo,
    groupEmailFul: values.groupEmailFul,
    groupHardCopyFul: values.groupHardCopyFul,
  };
};

export type GridColumnVisibilityModel = {
  [field: string]: boolean;
};

export type VCAResponse = {
  IsSuccess: boolean;
  data: {
    Data: any[];
  };
  Errors: string;
};

export type AutoCompleteClientOptions = {
  clientID: string;
  clientName: string;
  isNew?: boolean;
};

export type EnhancedTableProps = {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
};

export type ConsultFees = {
  consultFee: string;
  groupFee: string;
};

export type ClientsResponse = {
  status: string;
  data: ClientTemplate[];
};

export type dataColumns = {
  fieldName: string;
  id: number;
  value: string | null;
  available: boolean;
};

export type Payer = {
  payerID: number;
  name: string;
  description: string;
  NM109PayerID: string | null;
};

export enum PRODUCT_OFFERING_COLUMNS_ID {
  planName = "clientManagement.planView.planName",
  serviceOfferingCode = "clientManagement.planView.serviceOfferingCode",
  generalMedicalConsultFee = "clientManagement.planView.generalMedicalConsultFee",
  generalMedicalGroupFee = "clientManagement.planView.generalMedicalGroupFee",
  internationalConsultFee = "clientManagement.planView.internationalConsultFee",
  internationalGroupFee = "clientManagement.planView.internationalGroupFee",
  psychInitialConsultFee = "clientManagement.planView.psychInitialConsultFee",
  psychInitialGroupFee = "clientManagement.planView.psychInitialGroupFee",
  psychFollowUpConsultFee = "clientManagement.planView.psychFollowUpConsultFee",
  psychFollowUpGroupFee = "clientManagement.planView.psychFollowUpGroupFee",
  vpcInitialConsultFee = "clientManagement.planView.vpcInitialConsultFee",
  vpcFollowUpConsultFee = "clientManagement.planView.vpcFollowUpConsultFee",
  vpcFollowUpGroupFee = "clientManagement.planView.vpcFollowUpGroupFee",
  employeeAssistanceConsultFee = "clientManagement.planView.employeeAssistanceConsultFee",
  employeeAssistanceGroupFee = "clientManagement.planView.employeeAssistanceGroupFee",
  wellnessConsultFee = "clientManagement.planView.wellnessConsultFee",
  wellnessGroupFee = "clientManagement.planView.wellnessGroupFee",
  biometricScreeningConsultFee = "clientManagement.planView.biometricScreeningConsultFee",
  biometricScreeningGroupFee = "clientManagement.planView.biometricScreeningGroupFee",
  PGxConsultFee = "clientManagement.planView.PGxConsultFee",
  PGxGroupFee = "clientManagement.planView.PGxGroupFee",
  PGxFollowUpConsultFee = "clientManagement.planView.PGxFollowUpConsultFee",
  PGxFollowUpGroupFee = "clientManagement.planView.PGxFollowUpGroupFee",
  dermatologyConsultFee = "clientManagement.planView.dermatologyConsultFee",
  dermatologyGroupFee = "clientManagement.planView.dermatologyGroupFee",
  licensedCounselingConsultFee = "clientManagement.planView.licensedCounselingConsultFee",
  licensedCounselingGroupFee = "clientManagement.planView.licensedCounselingGroupFee",
  dermatologyFollowUpConsultFee = "clientManagement.planView.dermatologyFollowUpConsultFee",
  dermatologyFollowUpGroupFee = "clientManagement.planView.dermatologyFollowUpGroupFee",
  tuberculosisConsultFee = "clientManagement.planView.tuberculosisConsultFee",
  tuberculosisGroupFee = "clientManagement.planView.tuberculosisGroupFee",
  vpcInitialGroupFee = "clientManagement.planView.vpcInitialGroupFee",
  cologuardTestingConsultFee = "clientManagement.planView.cologuardTestingConsultFee",
  cologuardTestingGroupFee = "clientManagement.planView.cologuardTestingGroupFee",
  galleriTestingConsultFee = "clientManagement.planView.galleriTestingConsultFee",
  galleriTestingGroupFee = "clientManagement.planView.galleriTestingGroupFee",
}

export const PLAN_VIEW_DIALOG_PRODUCT_OFFERINGS = [
  "generalMedical",
  "international",
  "psychInitial",
  "psychFollowUp",
  "vpcInitial",
  "vpcFollowUp",
  "employeeAssistance",
  "wellness",
  "biometricScreening",
  "PGx",
  "PGxFollowUp",
  "dermatology",
  "licensedCounseling",
  "dermatologyFollowUp",
  "tuberculosis",
  "cologuardTesting",
];

export type ProductOfferingColumnsId = keyof typeof PRODUCT_OFFERING_COLUMNS_ID;

export type ProductOffering = {
  clientProductOfferingID: number | null;
  clientID: number | null;
  planName: string | null;
  pepm: string;
  couponId: number | null;
  setToDependent: boolean;
  groupCount?: number;
  dataColumns: dataColumns[];
};

export type NewProductOffering = {
  ClientID: string;
  BillingFee: string;
  CouponID: number | null;
  SetToDependent: boolean;
  StartDate: Date;
  ConsultationFees: object[];
};

export type ProductOfferingResponse = {
  status: string;
  data: ProductOffering[];
};

export type SelectedProductOfferings = {
  ClientProductOfferingID: string;
  CustomProductOfferingID: string;
};

export type NewOfferingInputs = Pick<ProductOffering, "planName" | "pepm"> 
& {
  couponId: number | null;
  setToDependent: boolean;
};

export type CreateClientInput = Pick<
  ClientTemplate,
  "startDate" | "endDate" | "active"
> & {
  name: string;
  id?: ClientTemplate["clientID"];
  onlyMemberIdRequired?: ClientTemplate["OnlyMemberIdRequired"];
  generateUtilizationReports?: ClientTemplate["GenerateUtilizationReports"];
};

export type CreateClientData = Pick<
  ClientTemplate,
  "clientID" | "clientName" | "startDate" | "endDate" | "active"
> & {
  Active: ClientTemplate["active"];
};

export type VCResponse = {
  status: string;
  data: {
    IsSuccess: boolean;
    Data: CreateClientData;
    Errors: string[];
  };
};

export type ConsultationType = Pick<
  SelectedConsultInputs,
  "consultationTypeID" | "description"
>;

type SelectedConsultInputs = {
  consultationTypeID: number;
  description: string;
  memberFee: string;
  totalFee: string;
  feeAfterDeductible: string;
};

type GetAllConsultationsData = {
  consultationTypeID: number;
  IsFollowUpType: boolean;
  ConsultationTypes: ConsultationType[];
};

type GetAllConsultationsResponse = {
  status: string;
  data: {
    IsSuccess: boolean;
    Data: GetAllConsultationsData[];
    Errors: string[];
  };
};

type SnackBarTemplate = {
  open: boolean;
  severity: "error" | "success" | "info" | "warning";
  title: LanguageKeys | null;
  subTitle: LanguageKeys | null;
};

type GroupOfferingCode = {
  ClientProductOfferingID: number;
  CustomGroupOfferingID: string;
  AllowDependentManagement: boolean;
};

type ClientOfferingCode = {
  ClientProductOfferingID: number;
  CustomClientProductOfferingID: string;
};

export type Coupon = {
  CouponID: number;
  Name: string;
  NumberUses: number;
  PercentageOff: number;
}

export type {
  ClientOfferingCode,
  GetAllConsultationsData,
  GetAllConsultationsResponse,
  GroupOfferingCode,
  SelectedConsultInputs,
  SnackBarTemplate,
};

export { CreateDefaultGroup, groupForm };
