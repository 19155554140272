import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";
import {
  ClientTemplate,
  Group,
} from "../../common/resources/clientManagement.types";
import { ReportTypeResponse } from "../../common/resources/reporting.types";
import { reportApi } from "./report.service";

export enum ReportTypeStatus {
  idle = "idle",
  loading = "loading",
  success = "success",
  failed = "failed",
}

export type ReportForm = {
  reportType: ReportTypeResponse | null;
  clientName: ClientTemplate | null;
  groupName: Group | null;
  month: string | null;
};

export type ReportState = {
  reportTypes: ReportTypeResponse[];
  reportTypesStatus: ReportTypeStatus;
  reportForm: ReportForm;
};

export const reportSlice: Slice<ReportState> = createSlice({
  name: "reportState",
  initialState: {
    reportTypes: [],
    reportTypesStatus: ReportTypeStatus.idle,
    reportForm: {
      reportType: null,
      clientName: null,
      groupName: null,
      month: null,
    },
  } as ReportState,
  reducers: {
    setReportTypes: (
      state,
      action: PayloadAction<{ reportTypes: ReportTypeResponse[] }>
    ) => {
      state.reportTypes = action.payload.reportTypes;
    },
    setReportForm: (
      state,
      action: PayloadAction<{ reportForm: ReportForm }>
    ) => {
      state.reportForm = action.payload.reportForm;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      reportApi.endpoints.fetchReportTypes.matchPending,
      (state) => {
        state.reportTypesStatus = ReportTypeStatus.loading;
      }
    );
    builder.addMatcher(
      reportApi.endpoints.fetchReportTypes.matchFulfilled,
      (state, action) => {
        const reportTypes = action.payload.data as ReportTypeResponse[];
        state.reportTypesStatus = ReportTypeStatus.success;
        state.reportTypes = reportTypes;
      }
    );
    builder.addMatcher(
      reportApi.endpoints.fetchReportTypes.matchRejected,
      (state) => {
        state.reportTypesStatus = ReportTypeStatus.success; // TO-DO: Change to implement real endpooint call
      }
    );
  },
});

export const selectReportTypes = (state: RootState): ReportTypeResponse[] =>
  state.reportState.reportTypes;

export const selectReportForm = (state: RootState): ReportForm | null =>
  state.reportState.reportForm;

export const selectReportTypesStatus = (state: RootState): ReportTypeStatus =>
  state.reportState.reportTypesStatus;

export const { setReportTypes, setReportForm } = reportSlice.actions;

export default reportSlice.reducer;
