import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FormikProps } from "formik";
import React, { ChangeEvent, useState } from "react";
import { useIntl } from "react-intl";

import { AccessType, IFormData } from "../../common/resources/bulkOrders.types";
import { Login } from "../Login/Login";

type ProgramConfigurationProps = {};

const ProgramConfiguration: React.FC<
  ProgramConfigurationProps & { formik: FormikProps<IFormData> }
> = ({ formik }) => {
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState<string | null>(null);
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false);
  const today = new Date();
  const yesterday = new Date(today.setHours(0, 0, 0, 0) - 86400000);

  const handleClick = (
    workflow: string,
    opt_out: boolean,
    support_individual_interactions: boolean
  ) => {
    formik.setFieldValue("workflow", workflow);
    formik.setFieldValue("opt_out", opt_out);
    formik.setFieldValue(
      "support_individual_interactions",
      support_individual_interactions
    );
  };

  const handleProviderProgramSelect = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string | null
  ) => {
    setSelected(value);
    if (value === null) {
      formik.setFieldValue("workflow", "");
    }
  };

  const handleMemberProgramSelect = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string | null
  ) => {
    setSelected(value);
    if (value === null) {
      formik.setFieldValue("workflow", "");
    }
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSwitchChecked(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("expiration", yesterday);
    } else if (!event.target.checked) {
      formik.setFieldValue("expiration", "");
    }
  };

  return (
    <Box
      m={2}
      mt={4}
    >
      <Typography variant="subtitle1">
        {formatMessage({ id: "bulkOrders.programConfiguration.title" })}
      </Typography>
      <Typography
        variant="body1"
        mb={4}
      >
        {formatMessage({ id: "bulkOrders.programConfiguration.subtitle" })}
      </Typography>

      <Typography
        variant="body1"
        fontWeight={500}
      >
        {formatMessage({
          id: "bulkOrders.programConfiguration.programType.title",
        })}
      </Typography>
      <Typography variant="caption">
        {formatMessage({
          id: "bulkOrders.programConfiguration.programType.provider",
        })}
      </Typography>
      <Box mb={2}>
        <ToggleButtonGroup
          value={selected}
          exclusive
          onChange={handleProviderProgramSelect}
          aria-label="select program type"
        >
          <ToggleButton
            value="bulk opt in"
            aria-label="bulk opt in"
            onClick={() => handleClick(AccessType.provider, false, false)}
          >
            {formatMessage({
              id: "bulkOrders.programConfiguration.button.provider.bulkOptIn",
            })}
          </ToggleButton>
          <ToggleButton
            value="bulk opt out"
            aria-label="bulk opt out"
            onClick={() => handleClick(AccessType.provider, true, false)}
          >
            {formatMessage({
              id: "bulkOrders.programConfiguration.button.provider.bulkOptOut",
            })}
          </ToggleButton>
          <ToggleButton
            value="single opt in"
            aria-label="single opt in"
            onClick={() => handleClick(AccessType.provider, false, true)}
          >
            {formatMessage({
              id: "bulkOrders.programConfiguration.button.provider.optIn",
            })}
          </ToggleButton>
          <ToggleButton
            value="single opt out"
            aria-label="single opt out"
            onClick={() => handleClick(AccessType.provider, true, true)}
          >
            {formatMessage({
              id: "bulkOrders.programConfiguration.button.provider.optOut",
            })}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Typography variant="caption">
        {formatMessage({
          id: "bulkOrders.programConfiguration.programType.member",
        })}
      </Typography>
      <Box>
        <ToggleButtonGroup
          value={selected}
          onChange={handleMemberProgramSelect}
          exclusive
          aria-label="select member program type"
        >
          <ToggleButton
            value="member opt in"
            aria-label="member opt in"
            onClick={() => handleClick(AccessType.patient, false, false)}
          >
            {formatMessage({
              id: "bulkOrders.programConfiguration.button.member.optIn",
            })}
          </ToggleButton>
          <ToggleButton
            value="member opt out"
            aria-label="member opt out"
            onClick={() => handleClick(AccessType.patient, true, false)}
          >
            {formatMessage({
              id: "bulkOrders.programConfiguration.button.member.optOut",
            })}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Typography
        variant="body1"
        fontWeight={500}
      >
        {formatMessage({
          id: "bulkOrders.programConfiguration.programDetails",
        })}
      </Typography>
      <Stack
        mt={4}
        maxWidth="275px"
        spacing={4}
      >
        <TextField
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.id}
          error={Boolean(formik.errors.id) && formik.touched.id}
          helperText={formik.touched.id && formik.errors.id}
          id="id"
          label={formatMessage({ id: "ui.label.programID" })}
          placeholder="Program ID"
        />
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={4}
          minWidth={"800px"}
        >
          <DatePicker
            disablePast
            disabled={isSwitchChecked}
            label="Program End Date"
            format="MM/dd/yyyy"
            slotProps={{
              textField: {
                error: false,
              },
            }}
            views={["month", "year", "day"]}
            onChange={(value) => formik.setFieldValue("expiration", value)}
          />
          <FormControlLabel
            value="top"
            control={
              <Switch
                name="expiration"
                color="primary"
                checked={isSwitchChecked}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={
              <Typography
                variant="caption"
                component="p"
              >
                {formatMessage({ id: "ui.switch.label" })}
              </Typography>
            }
            labelPlacement="top"
          />
        </Stack>
      </Stack>
    </Box>
  );
};

export default withAuthenticationRequired(ProgramConfiguration, {
  onRedirecting: () => <Login />,
});
