import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";

import { theme } from "../../theme/theme";

export type BenefitTitleProps = {
  title: string;
};

export const BenefitTitle: React.FC<BenefitTitleProps> = ({ title }) => {
  return (
    <Grid2
      container
      sx={{ width: "95%" }}
    >
      <Grid2
        sm={4}
        sx={{ textAlign: "start" }}
      >
        <div style={{ display: "flex" }}>
          <ArrowBackIosIcon htmlColor={theme.palette.benefits.main} />
          <Typography sx={{ color: "benefits.main" }}>Back</Typography>
        </div>
      </Grid2>
      <Grid2 sm={6}>
        <Typography
          variant="benefitsHeader"
          sx={{
            typography: "header4",
            width: "100%",
            verticalAlign: "center",
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Grid2>
    </Grid2>
  );
};
