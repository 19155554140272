import { Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  debounce,
  FormControl,
  InputAdornment,
  Link,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../app/hooks";
import CustomSnackbar from "../../common/components/CustomSnackBar/CustomSnackBar";
import { ProgramRow } from "../../common/resources/bulkOrders.types";
import { theme } from "../../common/theme/theme";
import {
  formatDate,
  formatExpirationDisplayDate,
} from "../../common/utils/formatDate";
import {
  selectSnackBar,
  setSelectedProgram,
  setSnackBar,
} from "../../slices/bulkOrders/bulkOrders";
import {
  bulkOrdersApi,
  useLazyFetchAllProgramsQuery,
} from "../../slices/bulkOrders/bulkOrders.service";
import ProgramCreationModal from "../ProgramCreationModal/ProgramCreationModal";

type props = {
  nav: (value: string) => void;
};

export const ProgramTable: React.FC<props> = ({ nav }) => {
  const [openCreateProgram, setOpenCreateProgram] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const snackBar = useAppSelector(selectSnackBar);

  const checkProgramExpiration = (expiration: string) => {
    const programEndDate = new Date(
      expiration.replace(/-/g, "/").replace(/T.+/, "")
    );
    const currentUserDate = new Date();

    if (programEndDate.getTime() < currentUserDate.getTime()) {
      return true;
    }

    return false;
  };

  const [programState, setProgramState] = useState<{
    page: number;
    per_page: number;
    search?: string;
  }>({
    page: 1,
    per_page: 10,
    search: "",
  });

  const [getPrograms, { data: programData, isFetching, isLoading }] =
    useLazyFetchAllProgramsQuery();

  useEffect(() => {
    getPrograms(programState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programState]);

  const columnInformation = () => {
    const COLUMN_LIST: GridColDef[] = [
      {
        field: "program_sponsor",
        headerName: formatMessage({ id: "bulkOrders.field.programSponsor" }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        flex: 1,
        align: "center",
        headerAlign: "center",
      },
      {
        field: "program_type",
        headerName: formatMessage({ id: "bulkOrders.field.programType" }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        display: "flex",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (value: { row: ProgramRow }) => {
          return (
            <Typography variant="body2">
              {value.row.workflow}{" "}
              {value.row.support_individual_interactions ||
              value.row.workflow === "PATIENT"
                ? "Single"
                : "Bulk"}{" "}
              {value.row.opt_out ? "Opt Out" : "Opt In"}
            </Typography>
          );
        },
      },
      {
        field: "total_members",
        headerName: formatMessage({ id: "bulkOrders.field.totalMembers" }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        display: "flex",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (value: any) => {
          const programExpired = checkProgramExpiration(value.row.expiration);
          return (
            <Typography variant="body2">
              {programExpired
                ? value.row.total_members
                : `${value.row.total_opted_in}/${value.row.total_members}`}
            </Typography>
          );
        },
      },
      {
        field: "orders_sent",
        headerName: formatMessage({ id: "bulkOrders.field.ordersSent" }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        display: "flex",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (value: any) => {
          const ordersSent = value.row.orders_sent ?? 0;
          return (
            <Typography variant="body2">
              {ordersSent} / {value.row.total_opted_in}
            </Typography>
          );
        },
      },
      {
        field: "created_at",
        headerName: formatMessage({ id: "bulkOrders.field.createdDate" }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        display: "flex",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (value: any) => {
          return (
            <Typography variant="body2">
              {formatDate(value.row.created_at)}
            </Typography>
          );
        },
      },
      {
        field: "expiration",
        headerName: formatMessage({ id: "bulkOrders.field.endDate" }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        display: "flex",
        flex: 1,
        align: "center",
        headerAlign: "center",
        renderCell: (value: any) => {
          return (
            <Typography variant="body2">
              {formatExpirationDisplayDate(value.row.expiration)}
            </Typography>
          );
        },
      },
      {
        field: "send_outreach",
        headerName: formatMessage({ id: "bulkOrders.field.outreachRequired" }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        display: "flex",
        align: "center",
        flex: 1,
        headerAlign: "center",
        renderCell: (value) => {
          return (
            <Chip
              label={
                value.row.send_outreach
                  ? formatMessage({ id: "bulkOrders.field.yes" })
                  : formatMessage({ id: "bulkOrders.field.no" })
              }
              sx={{
                backgroundColor: value.row.send_outreach
                  ? theme.palette.info.light
                  : "",
              }}
            />
          );
        },
      },
      {
        field: "patient_negative_results_outreach",
        headerName: formatMessage({
          id: "bulkOrders.cellHeader.negativeResults",
        }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        display: "flex",
        align: "center",
        flex: 1,
        headerAlign: "center",
        renderCell: (value) => {
          return (
            <Chip
              label={
                value.row.patient_negative_results_outreach
                  ? formatMessage({ id: "bulkOrders.field.yes" })
                  : formatMessage({ id: "bulkOrders.field.no" })
              }
              sx={{
                backgroundColor: value.row.patient_negative_results_outreach
                  ? theme.palette.info.light
                  : "",
              }}
            />
          );
        },
      },
      {
        field: "results_outreach",
        headerName: formatMessage({ id: "bulkOrders.field.resultsSharing" }),
        disableColumnMenu: true,
        sortable: false,
        headerClassName: "header_cell",
        align: "center",
        display: "flex",
        flex: 1,
        headerAlign: "center",
        renderCell: (value) => {
          return (
            <Chip
              label={
                value.row.results_outreach
                  ? formatMessage({ id: "bulkOrders.field.yes" })
                  : formatMessage({ id: "bulkOrders.field.no" })
              }
              sx={{
                backgroundColor: value.row.results_outreach
                  ? theme.palette.info.light
                  : "",
              }}
            />
          );
        },
      },
    ];
    return COLUMN_LIST;
  };

  const goToProgramDetails = async (value: ProgramRow) => {
    await dispatch(
      bulkOrdersApi.util.invalidateTags(["OrderGroups", "States"])
    );
    dispatch(setSelectedProgram(value));
    nav(value.program_id);
  };

  const getTotalPages = () => {
    if (programData?.total_count) {
      return Math.ceil(programData?.total_count / programState.per_page);
    }
  };

  const CustomPagination: React.FC = () => {
    const pageSizeOptions = [5, 10, 15, 20];
    const totalPages = getTotalPages();
    const currentAmount =
      programState.page === 1
        ? 1
        : (programState.page - 1) * programState.per_page;

    const getCurrentAmountOnPage = () => {
      if (programState.page === 1) {
        return programState.per_page;
      } else if (programState.page === totalPages) {
        return programData?.total_count;
      }
      return programState.page * programState.per_page;
    };

    const amountOnPage = getCurrentAmountOnPage();

    return (
      <Stack
        direction="row"
        spacing={2}
      >
        <FormControl
          sx={{ m: 1, minWidth: 60, textAlign: "center" }}
          size="small"
        >
          <Select
            id="select-page-rows"
            variant="standard"
            value={programState.per_page}
            label="Age"
            onChange={(e) => {
              const newValue = Number(e.target.value);
              if (newValue !== programState.per_page) {
                setProgramState({
                  page: 1,
                  per_page: newValue,
                });
              }
            }}
          >
            {pageSizeOptions.map((pageSize) => (
              <MenuItem
                key={pageSize}
                value={pageSize}
              >
                {pageSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {currentAmount} - {amountOnPage} of {programData?.total_count}
        </Box>
        <Pagination
          color="primary"
          count={totalPages}
          page={programState.page}
          onChange={(event, value) => {
            setProgramState({
              ...programState,
              page: value,
            });
          }}
        />
      </Stack>
    );
  };

  const columns = useMemo(() => {
    const programIDColumn: GridColDef = {
      field: "program_id",
      headerName: formatMessage({ id: "bulkOrders.field.programID" }),
      disableColumnMenu: true,
      sortable: false,
      display: "flex",
      flex: 1,
      cellClassName: "programID_cell",
      headerClassName: "header_cell programID_cell",
      align: "center",
      headerAlign: "center",
      renderCell: (value: any) => {
        const programExpired = checkProgramExpiration(value.row.expiration);
        return (
          <Typography
            onClick={() => programExpired && goToProgramDetails(value.row)}
            variant="body2"
            sx={{
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {programExpired ? (
              <Link sx={{ cursor: "pointer" }}>
                [{value.row.program_id.toUpperCase()}]
              </Link>
            ) : (
              `[${value.row.program_id.toUpperCase()}]`
            )}
          </Typography>
        );
      },
    };
    return [programIDColumn, ...columnInformation()];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnInformation]);

  const handleSearch = debounce(
    (event) =>
      setProgramState({
        ...programState,
        page: 1,
        search: event.target.value,
      }),
    400
  );

  return (
    <Box>
      <ProgramCreationModal
        isOpen={openCreateProgram}
        setModalOpen={setOpenCreateProgram}
      />
      <Stack
        spacing={2}
        sx={{
          display: "flex",
          width: "100%",
        }}
      >
        <CustomSnackbar
          open={Boolean(snackBar.open)}
          autoHideDuration={5000}
          onClose={() => dispatch(setSnackBar({ ...snackBar, open: false }))}
          title={snackBar.title ? formatMessage({ id: snackBar.title }) : ""}
          subTitle={
            snackBar.subTitle ? formatMessage({ id: snackBar.subTitle }) : ""
          }
          severity={snackBar.severity}
        />
        <Card>
          <Stack spacing={2}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h3"
                  sx={{ fontSize: 26 }}
                >
                  {formatMessage({ id: "bulkOrders.header" })}
                </Typography>
                <Button
                  sx={{ width: 180, height: 36, margin: 1 }}
                  variant="contained"
                  onClick={() => setOpenCreateProgram(true)}
                >
                  {formatMessage({ id: "bulkOrders.button.create" })}
                </Button>
              </Box>
              <TextField
                sx={{ width: 800, marginY: 1 }}
                placeholder={formatMessage({
                  id: "bulkOrders.search.placeholder",
                })}
                onChange={handleSearch}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              <DataGrid
                autoHeight
                getRowId={(row) => row.program_id}
                slots={{
                  pagination: CustomPagination,
                }}
                sx={{
                  "& .programID_cell": {
                    borderRight: `2px solid #d4d4d4`,
                  },
                  "& .header_cell": {
                    color: theme.palette.grey[800],
                  },
                  "& .MuiDataGrid-cell": {
                    color: theme.palette.grey[800],
                  },
                  ".MuiDataGrid-iconButtonContainer": {
                    visibility: "visible",
                  },
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                    {
                      outline: "none",
                    },
                  marginY: 2,
                }}
                columns={columns}
                loading={isLoading || isFetching}
                paginationMode="server"
                rowCount={programData?.orders.length ?? 0}
                rows={programData?.orders ?? []}
                disableRowSelectionOnClick
              />
            </CardContent>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};
