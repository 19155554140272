const healthScreening = {
  description: "completion time: <b>3-5 minutes<b>",
  subjectType: ["Patient"],
  meta: {
    lastUpdated: "2022-10-31T15:09:18.298851Z",
    createdAt: "2022-08-23T04:18:13.321786Z",
    versionId: "1658839",
  },
  publisher: "Recuro Health",
  purpose:
    "This section covers information that can help us better <strong> understand your body’s responses </strong> to certain medications and <strong> predict your future risk </strong> for certain hereditary conditions. <br> <br> Help us uniquely understand you so we can <strong> tailor your care. </strong>",
  name: "Health Screening",
  item: [
    {
      id: "2",
      item: [
        {
          id: "2.1",
          type: "input",
          linkId: "2.1",
          required: true,
          enableWhen: [
            {
              answer: {
                string: "Another option not listed:",
              },
              operator: "=",
              question: "2",
            },
          ],
        },
      ],
      text: "<span>Select your race/origin</span>",
      type: "open-choice",
      linkId: "2",
      extension: [
        {
          url: "http://hl7.org/fhir/StructureDefinition/questionnaire-instruction",
          valueString:
            "<strong>Why do we ask this?</strong>, <br> <br> This information helps us better understand your risk for certain conditions, tailor your recommendations, and personalize your care management.",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Ashkenazi Jewish",
          },
        },
        {
          value: {
            string: "Black/African American",
          },
        },
        {
          value: {
            string: "Central Asian",
            Reference: {
              display: "(e.g., Afghani, Armenian, Mongolian)",
            },
          },
        },
        {
          value: {
            string: "East Asian",
            Reference: {
              display: "(e.g., Chinese, Japanese, Korean, Taiwanese)",
            },
          },
        },
        {
          value: {
            string: "Hispanic/Latinx",
          },
        },
        {
          value: {
            string: "Middle Eastern/North African",
          },
        },
        {
          value: {
            string: "Native-American or Alaska Native",
          },
        },
        {
          value: {
            string: "Native Hawaiian or Pacific Islander",
          },
        },
        {
          value: {
            string: "South Asian",
            Reference: {
              display:
                "(e.g., Bangladeshi, Bhutanese, Indian, Pakistani, Nepalese, Sri Lankan)",
            },
          },
        },
        {
          value: {
            string: "Southeast Asian",
            Reference: {
              display:
                "(e.g., Burmese, Cambodian, Filipino, Laotian, Indonesian, Malaysian, Singaporean, Thai, Vietnamese)",
            },
          },
        },
        {
          value: {
            string: "White",
          },
        },
        {
          value: {
            string: "Another option not listed:",
          },
        },
      ],
    },
    {
      id: "5",
      text: "<span>Have you experienced nausea, dizziness, skin reactions, or pain from a medication in the past year, and are still taking this medication?</span>",
      type: "choice",
      linkId: "5",
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
      ],
    },
    {
      id: "7",
      text: "<span>In the past year, have you stopped taking a medication or refused to refill a prescription because it wasn’t working for you, and still have not found a good replacement? </span>",
      type: "choice",
      linkId: "7",
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
      ],
    },
    {
      id: "8",
      text: '<span>Have you been advised by a provider to regularly check vitamin D levels or take vitamin D supplements? <p class="small">(e.g., due to osteoporosis, kidney, liver, or gastrointestinal conditions that decrease Vitamin D absorption) </p> </span>',
      type: "choice",
      linkId: "8",
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
      ],
    },
    {
      id: "11",
      item: [
        {
          id: "11.1",
          type: "input",
          linkId: "11.1",
          required: true,
          enableWhen: [
            {
              answer: {
                string: "Yes",
              },
              operator: "=",
              question: "11",
            },
          ],
        },
      ],
      text: "<span>Were you hospitalized in the last 6 months?</span>",
      type: "choice",
      linkId: "11",
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
            Reference: {
              display: "please indicate why:",
            },
          },
        },
        {
          value: {
            string: "No",
          },
        },
      ],
    },
    {
      id: "13",
      text: "<span>Have you or a parent, sibling, child, grandparent, aunt, or uncle been clinically diagnosed with cancer?</span>",
      type: "choice",
      linkId: "13",
      enables: [
        {
          question: "14",
          withAnswer: "Yes",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
    },
    {
      linkId: "14",
      enables: [
        {
          question: "15",
          withAnswer: "Breast or Ovarian cancer",
        },
        {
          question: "17",
          withAnswer: "Colorectal, Endometrial cancer or Lynch syndrome",
        },
      ],
      item: [
        {
          id: "14.1",
          type: "input",
          linkId: "14.1",
          required: true,
          enableWhen: [
            {
              answer: {
                string: "Other (please specify):",
              },
              operator: "=",
              question: "14",
            },
          ],
        },
      ],
      type: "open-choice",
      enableWhen: [
        {
          answer: {
            string: "Yes",
          },
          operator: "=",
          question: "13",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Breast or Ovarian cancer",
          },
        },
        {
          value: {
            string: "Colorectal, Endometrial cancer or Lynch syndrome",
          },
        },
        {
          value: {
            string: "Gastric, Pancreatic or Esophageal cancer",
          },
        },
        {
          value: {
            string: "Thyroid cancer ",
          },
        },
        {
          value: {
            string: "Prostate cancer ",
          },
        },
        {
          value: {
            string: "Lung cancer ",
          },
        },
        {
          value: {
            string: "Cervical or Testicular cancer",
          },
        },
        {
          value: {
            string: "Skin cancer",
          },
        },
        {
          value: {
            string: "Bone cancer ",
          },
        },
        {
          value: {
            string: "Brain cancer",
          },
        },
        {
          value: {
            string: "Blood cancer",
            Reference: {
              display: "(e.g., Leukemia, Lymphoma)",
            },
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
        {
          value: {
            string: "Other (please specify):",
          },
        },
      ],
      id: "14",
      required: true,
      text: "<span>Which type of cancer were you or your parent, sibling, child, grandparent, aunt, or uncle diagnosed with?</span>",
    },
    {
      id: "15",
      text: "<span>Are you aware of having an elevated risk of breast cancer, or have you ever been diagnosed with breast cancer, and are following preventive screening guidelines recommended by your doctor (e.g., regular mammograms or breast exams)?</span>",
      type: "choice",
      linkId: "15",
      enables: [
        {
          question: "16",
          withAnswer: "No",
        },
      ],
      required: true,
      enableWhen: [
        {
          answer: {
            string: "Breast or Ovarian cancer",
          },
          operator: "=",
          question: "14",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
    },
    {
      id: "16",
      text: "<span>Did your biological mother, aunt, or sibling have breast or ovarian cancer that was detected at age 50 or younger?</span>",
      type: "choice",
      linkId: "16",
      required: true,
      enableWhen: [
        {
          answer: {
            string: "No",
          },
          operator: "=",
          question: "15",
        },
        {
          answer: {
            string: "Unsure",
          },
          operator: "=",
          question: "15",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
      enableBehavior: "any",
    },
    {
      id: "17",
      text: "<span>Are you aware of having an elevated risk of colorectal cancer, or have you ever been diagnosed with colorectal cancer, and are following preventive screening guidelines recommended by your doctor (e.g., regular colonoscopies or biopsies)?</span>",
      type: "choice",
      linkId: "17",
      enables: [
        {
          question: "18",
          withAnswer: "No",
        },
        {
          question: "18",
          withAnswer: "Unsure",
        },
        {
          question: "19",
          withAnswer: "No",
        },
        {
          question: "19",
          withAnswer: "Unsure",
        },
        {
          question: "20",
          withAnswer: "No",
        },
        {
          question: "20",
          withAnswer: "Unsure",
        },
      ],
      required: true,
      enableWhen: [
        {
          answer: {
            string: "Colorectal, Endometrial cancer or Lynch syndrome",
          },
          operator: "=",
          question: "14",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
    },
    {
      id: "18",
      text: "<span>Did your parent, sibling, child, grandparent, aunt, or uncle have multi-organ cancer (e.g., prostate or bile-duct cancer) or colon cancer that was detected at age 40 or younger?</span>",
      type: "choice",
      linkId: "18",
      required: true,
      enableWhen: [
        {
          answer: {
            string: "No",
          },
          operator: "=",
          question: "17",
        },
        {
          answer: {
            string: "Unsure",
          },
          operator: "=",
          question: "17",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
      enableBehavior: "any",
    },
    {
      id: "19",
      text: "<span>Have you had any polyps detected on a colonoscopy or abnormal projections detected on a colon biopsy?</span>",
      type: "choice",
      linkId: "19",
      required: true,
      enableWhen: [
        {
          answer: {
            string: "No",
          },
          operator: "=",
          question: "17",
        },
        {
          answer: {
            string: "Unsure",
          },
          operator: "=",
          question: "17",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
      ],
      enableBehavior: "any",
    },
    {
      id: "20",
      text: "<span>Do you have an inflammatory bowel disease like Crohn’s disease or Ulcerative colitis?</span>",
      type: "choice",
      linkId: "20",
      required: true,
      enableWhen: [
        {
          answer: {
            string: "No",
          },
          operator: "=",
          question: "17",
        },
        {
          answer: {
            string: "Unsure",
          },
          operator: "=",
          question: "17",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
      ],
      enableBehavior: "any",
    },
    {
      id: "21",
      text: "<span>Do you or your parents, siblings or children have high cholesterol or have been diagnosed with a genetic condition that causes high cholesterol (Familial Hypercholesterolemia, FH)?</span>",
      type: "choice",
      linkId: "21",
      enables: [
        {
          question: "22",
          withAnswer: "Yes",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
    },
    {
      id: "22",
      text: "<span>Are you currently being treated for high cholesterol levels by a doctor?</span>",
      type: "choice",
      linkId: "22",
      enables: [
        {
          question: "23",
          withAnswer: "No",
        },
        {
          question: "23",
          withAnswer: "Unsure",
        },
        {
          question: "24",
          withAnswer: "No",
        },
        {
          question: "24",
          withAnswer: "Unsure",
        },
        {
          question: "25",
          withAnswer: "No",
        },
        {
          question: "25",
          withAnswer: "Unsure",
        },
      ],
      required: true,
      enableWhen: [
        {
          answer: {
            string: "Yes",
          },
          operator: "=",
          question: "21",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
    },
    {
      id: "23",
      text: "<span>Are you of French-Canadian or Dutch-Afrikaans ancestry?</span>",
      type: "choice",
      linkId: "23",
      required: true,
      enableWhen: [
        {
          answer: {
            string: "No",
          },
          operator: "=",
          question: "22",
        },
        {
          answer: {
            string: "Unsure",
          },
          operator: "=",
          question: "22",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
      enableBehavior: "any",
    },
    {
      id: "24",
      text: "<span>Did any of your family members with high cholesterol levels have multiple painless swellings around elbows, ankles, and toes?</span>",
      type: "choice",
      linkId: "24",
      required: true,
      enableWhen: [
        {
          answer: {
            string: "No",
          },
          operator: "=",
          question: "22",
        },
        {
          answer: {
            string: "Unsure",
          },
          operator: "=",
          question: "22",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
      enableBehavior: "any",
    },
    {
      id: "25",
      text: "<span>Did any of your family members with high cholesterol levels suffer from a sudden cardiac event (e.g., heart attack, angina, stroke) resulting in early death (55 or younger)?</span>",
      type: "choice",
      linkId: "25",
      required: true,
      enableWhen: [
        {
          answer: {
            string: "No",
          },
          operator: "=",
          question: "22",
        },
        {
          answer: {
            string: "Unsure",
          },
          operator: "=",
          question: "22",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Unsure",
          },
        },
      ],
      enableBehavior: "any",
    },
  ],
  resourceType: "Questionnaire",
  title: "Health Screening",
  extension: [
    {
      url: "http://hl7.org/fhir/StructureDefinition/questionnaire-instruction",
      valueString:
        "Click <a href=\"/instructions\" style='text-decoration: none;'><strong>here</strong></a> for more information on at-home testing with Recuro.",
    },
  ],
  status: "active",
  id: "patient-health-screening-questionnaire",
};

const emotionalWellness = {
  description: "completion time: <b>3-5 minutes<b>",
  subjectType: ["Patient"],
  meta: {
    lastUpdated: "2022-10-28T20:23:51.482450Z",
    createdAt: "2022-05-27T17:01:06.689015Z",
    versionId: "1656706",
  },
  publisher: "Recuro Health",
  purpose:
    "<span>This section helps us understand your <strong>emotional wellness</strong> and overall state of mind. <br> <br> <br> Your responses will enable our providers to <strong>understand you</strong> holistically and make suggestions to <strong>achieve the best total health outcomes.</strong></span>",
  name: "Emotional Wellness",
  item: [
    {
      id: "1",
      text: "Little interest or pleasure in doing things",
      type: "choice",
      linkId: "1",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "2",
      text: "Feeling down, depressed, or hopeless",
      type: "choice",
      linkId: "2",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "3",
      text: "Trouble falling or staying asleep, or sleeping too much",
      type: "choice",
      linkId: "3",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "4",
      text: "Feeling tired or having little energy",
      type: "choice",
      linkId: "4",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "5",
      text: "Poor appetite or overeating",
      type: "choice",
      linkId: "5",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "6",
      text: "Feeling bad about yourself – or that you are a failure or have let yourself or your family down",
      type: "choice",
      linkId: "6",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "7",
      text: "Trouble concentrating on things, such as reading the newspaper or watching television",
      type: "choice",
      linkId: "7",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "8",
      text: "Moving or speaking so slowly that other people could have noticed? Or the opposite – being so fidgety or restless that you have been moving around a lot more than usual",
      type: "choice",
      linkId: "8",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "9",
      text: "Thoughts that you would be better off dead or of hurting yourself in some way",
      type: "choice",
      linkId: "9",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      enables: [
        {
          question: "9.1",
          withAnswer: "Several days",
        },
        {
          question: "9.1",
          withAnswer: "More than half the days",
        },
        {
          question: "9.1",
          withAnswer: "Nearly every day",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "9.1",
      text: "How difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?",
      type: "choice",
      linkId: "9.1",
      required: true,
      enableWhen: [
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "1",
        },
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "2",
        },
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "3",
        },
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "4",
        },
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "5",
        },
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "6",
        },
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "7",
        },
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "8",
        },
        {
          answer: {
            string: "Not at all",
          },
          operator: "!=",
          question: "9",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Not difficult at all",
          },
        },
        {
          value: {
            string: "Somewhat difficult",
          },
        },
        {
          value: {
            string: "Very difficult",
          },
        },
        {
          value: {
            string: "Extremely difficult",
          },
        },
      ],
      enableBehavior: "not-all",
    },
    {
      id: "10",
      text: "Feeling nervous, anxious, or on edge",
      type: "choice",
      linkId: "10",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "11",
      text: "Not being able to stop or control worrying",
      type: "choice",
      linkId: "11",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "12",
      text: "Worrying too much about different things",
      type: "choice",
      linkId: "12",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "13",
      text: "Trouble relaxing",
      type: "choice",
      linkId: "13",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "14",
      text: "Being so restless that it is hard to sit still",
      type: "choice",
      linkId: "14",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "15",
      text: "Becoming easily annoyed or irritable",
      type: "choice",
      linkId: "15",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
    {
      id: "16",
      text: "Feeling afraid, as if something awful might happen",
      type: "choice",
      linkId: "16",
      prefix:
        "Over the <strong>last two weeks</strong>, how often have you been bothered <strong>by the following problems?</strong>",
      required: true,
      answerOption: [
        {
          value: {
            string: "Not at all",
          },
        },
        {
          value: {
            string: "Several days",
          },
        },
        {
          value: {
            string: "More than half the days",
          },
        },
        {
          value: {
            string: "Nearly every day",
          },
        },
      ],
    },
  ],
  resourceType: "Questionnaire",
  title: "Emotional Wellness",
  status: "active",
  id: "patient-emotional-wellness-questionnaire",
};

const lifestyle = {
  description: "completion time: <b>2-4 minutes<b>",
  subjectType: ["Patient"],
  meta: {
    lastUpdated: "2022-10-28T20:23:51.464464Z",
    createdAt: "2022-05-27T17:01:06.703666Z",
    versionId: "1656704",
  },
  publisher: "Recuro Health",
  purpose:
    "<span>This section helps us understand your <strong>lifestyle</strong> and <strong>habits</strong>. <br> <br>  <br>  Our providers can use this information to <strong>make suggestions</strong> that help you <strong>achieve your health goals.</strong></span>",
  name: "Lifestyle",
  item: [
    {
      id: "1",
      text: '<span>How many times in the past 12 months have you had 4 or more drinks in a day?</span> <p class="small">One drink is 12 ounces of beer, 5 ounces of wine, or 1.5 ounces of 80-proof spirits.</p>',
      type: "choice",
      linkId: "1",
      required: true,
      answerOption: [
        {
          value: {
            string: "Never",
          },
        },
        {
          value: {
            string: "Once or Twice",
          },
        },
        {
          value: {
            string: "Monthly",
          },
        },
        {
          value: {
            string: "Weekly",
          },
        },
        {
          value: {
            string: "Daily or Almost Daily",
          },
        },
      ],
    },
    {
      id: "2",
      text: "<span>How many times in the past 12 months have you used tobacco products (like cigarettes, cigars, snuff, chew, electronic cigarettes)?</span>",
      type: "choice",
      linkId: "2",
      required: true,
      answerOption: [
        {
          value: {
            string: "Never",
          },
        },
        {
          value: {
            string: "Once or Twice",
          },
        },
        {
          value: {
            string: "Monthly",
          },
        },
        {
          value: {
            string: "Weekly",
          },
        },
        {
          value: {
            string: "Daily or Almost Daily",
          },
        },
      ],
    },
    {
      id: "3",
      text: "<span>Over the past 7 days, how many servings of fruits or vegetables did you eat each day on average?</span>",
      type: "choice",
      linkId: "3",
      required: true,
      answerOption: [
        {
          value: {
            string: "0 servings",
          },
        },
        {
          value: {
            string: "1 serving",
          },
        },
        {
          value: {
            string: "2 servings",
          },
        },
        {
          value: {
            string: "3 or more servings",
          },
        },
      ],
    },
    {
      id: "4",
      text: "<span>In the past week, on how many days have you done a total of 30 minutes or more of physical activity, which was enough to raise your breathing rate?</span>",
      type: "choice_slider",
      linkId: "4",
      required: true,
      answerOption: [
        {
          value: {
            string: "0",
          },
        },
        {
          value: {
            string: "1",
          },
        },
        {
          value: {
            string: "2",
          },
        },
        {
          value: {
            string: "3",
          },
        },
        {
          value: {
            string: "4",
          },
        },
        {
          value: {
            string: "5",
          },
        },
        {
          value: {
            string: "6",
          },
        },
        {
          value: {
            string: "7",
          },
        },
      ],
    },
    {
      id: "5",
      text: "<span> Each night, how many hours of sleep do you usually get?</span>",
      type: "choice",
      linkId: "5",
      required: true,
      answerOption: [
        {
          value: {
            string: "5 hours or fewer",
          },
        },
        {
          value: {
            string: "6-8 hours",
          },
        },
        {
          value: {
            string: "9 hours or more",
          },
        },
      ],
    },
    {
      id: "6",
      text: "<span>Do you engage in high-risk sexual activity (such as having unprotected sex with multiple partners)?</span>",
      type: "choice",
      linkId: "6",
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
        {
          value: {
            string: "Prefer not to answer",
          },
        },
      ],
    },
  ],
  resourceType: "Questionnaire",
  title: "Lifestyle",
  status: "active",
  id: "patient-lifestyle-questionnaire",
};

const engagement = {
  description: "completion time: <b>2-4 minutes<b>",
  subjectType: ["Patient"],
  meta: {
    lastUpdated: "2022-10-28T20:23:51.451366Z",
    createdAt: "2022-05-27T17:01:06.719593Z",
    versionId: "1656702",
  },
  publisher: "Recuro Health",
  purpose:
    "<span>In this section you can tell us <strong>how you prefer to interact</strong> with Recuro and your doctors. <br> <br> <br> We use this information to <strong>put the power in your hands</strong> to use our services in a way that meets your unique needs.</span>",
  name: "Engagement Preferences",
  item: [
    {
      id: "1",
      text: "<span>Do you speak and read English at a fluent level?</span>",
      type: "choice",
      linkId: "1",
      enables: [
        {
          question: "2",
          withAnswer: "No",
        },
      ],
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
      ],
    },
    {
      id: "2",
      item: [
        {
          id: "2.1",
          type: "input",
          linkId: "2.1",
          required: true,
          enableWhen: [
            {
              answer: {
                string: "Another option not listed (please specify)",
              },
              operator: "=",
              question: "2",
            },
          ],
        },
      ],
      text: "<span>Which is your preferred language?</span>",
      type: "choice",
      linkId: "2",
      required: false,
      enableWhen: [
        {
          answer: {
            string: "No",
          },
          operator: "=",
          question: "1",
        },
      ],
      answerOption: [
        {
          value: {
            string: "Spanish",
          },
        },
        {
          value: {
            string: "Mandarin Chinese",
          },
        },
        {
          value: {
            string: "Cantonese",
          },
        },
        {
          value: {
            string: "French",
          },
        },
        {
          value: {
            string: "Tagalog",
          },
        },
        {
          value: {
            string: "Vietnamese",
          },
        },
        {
          value: {
            string: "Another option not listed (please specify)",
          },
        },
      ],
    },
    {
      id: "3",
      text: "<span>Do you have a Primary Care Physician who you see on a regular basis (e.g., for an annual physical)?</span>",
      type: "choice",
      linkId: "3",
      required: true,
      answerOption: [
        {
          value: {
            string: "Yes",
          },
        },
        {
          value: {
            string: "No",
          },
        },
      ],
    },
    {
      id: "4",
      text: "<span>How would you describe your overall health?</span>",
      type: "choice",
      linkId: "4",
      required: true,
      answerOption: [
        {
          value: {
            string: "Excellent, I feel very healthy and energetic",
          },
        },
        {
          value: {
            string:
              "Good overall, though I could be healthier and have more energy",
          },
        },
        {
          value: {
            string: "Fair, I sometimes feel healthy and have some energy",
          },
        },
        {
          value: {
            string: "Poor, I don’t feel healthy and energetic",
          },
        },
      ],
    },
    {
      id: "5",
      text: '<p class="small">How strongly do you agree with the following statement:</p><span>I always know where to go for the care I need and find it straightforward to navigate the healthcare system</span>',
      type: "choice",
      linkId: "5",
      required: true,
      answerOption: [
        {
          value: {
            string: "Strongly disagree",
          },
        },
        {
          value: {
            string: "Somewhat disagree",
          },
        },
        {
          value: {
            string: "Somewhat agree",
          },
        },
        {
          value: {
            string: "Strongly agree",
          },
        },
      ],
    },
    {
      id: "6",
      text: '<p class="small">How strongly do you agree with the following statement:</p> <span>I find it easy to understand and follow the care plans and instructions from doctors</span>',
      type: "choice",
      linkId: "6",
      required: true,
      answerOption: [
        {
          value: {
            string: "Strongly disagree",
          },
        },
        {
          value: {
            string: "Somewhat disagree",
          },
        },
        {
          value: {
            string: "Somewhat agree",
          },
        },
        {
          value: {
            string: "Strongly agree",
          },
        },
      ],
    },
    {
      id: "7",
      text: "<span>Which do you prefer:</span>",
      type: "choice",
      linkId: "7",
      required: true,
      answerOption: [
        {
          value: {
            string:
              "Where medically appropriate, always seeing my doctor online because it is more convenient, I get seen more quickly, and the care is high quality",
          },
        },
        {
          value: {
            string:
              "Going to the doctor’s office in person, even if it’s out of the way and I have to wait longer",
          },
        },
      ],
    },
    {
      id: "8",
      text: "<span>Which do you prefer:</span>",
      type: "choice",
      linkId: "8",
      required: true,
      answerOption: [
        {
          value: {
            string:
              "Being able to get medication for basic ailments just by messaging with a doctor",
          },
        },
        {
          value: {
            string:
              "Having a phone call with a doctor, even for basic ailments",
          },
        },
        {
          value: {
            string:
              "Having a video chat with a doctor, even for basic ailments",
          },
        },
      ],
    },
    {
      id: "9",
      text: "<span>Which do you prefer:</span>",
      type: "choice",
      linkId: "9",
      required: true,
      answerOption: [
        {
          value: {
            string:
              "Always seeing the same doctor, even if it means waiting a few extra days for an appointment",
          },
        },
        {
          value: {
            string: "Seeing whichever doctor is available fastest",
          },
        },
      ],
    },
    {
      id: "10",
      text: "<span> If you didn’t use a telemedicine visit (speaking with a doctor via message, call, or video chat) for your urgent care needs such as a fever, where would you go for care?</span>",
      type: "choice",
      linkId: "10",
      required: true,
      answerOption: [
        {
          value: {
            string: "Emergency department",
          },
        },
        {
          value: {
            string: "Hospital",
          },
        },
        {
          value: {
            string: "Urgent care clinic",
          },
        },
        {
          value: {
            string: "Retail clinic (e.g., in a CVS or Walmart)",
          },
        },
        {
          value: {
            string:
              "I have a doctor’s office where I can be seen the same day for urgent care needs",
          },
        },
        {
          value: {
            string: "I wouldn’t get any care if I couldn’t use telemedicine",
          },
        },
      ],
    },
  ],
  resourceType: "Questionnaire",
  title: "Engagement Preferences",
  status: "active",
  id: "patient-engagement-preferences-questionnaire",
};

const defaultQuestionnaires: any = {
  "patient-health-screening-questionnaire": healthScreening,
  "patient-emotional-wellness-questionnaire": emotionalWellness,
  "patient-lifestyle-questionnaire": lifestyle,
  "patient-engagement-preferences-questionnaire": engagement,
};

export {
  defaultQuestionnaires,
  emotionalWellness,
  engagement,
  healthScreening,
  lifestyle,
};
