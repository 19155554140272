import { DataGridWrapper } from "../../common/components/DataGrid/DataGrid";
import { useFetchOrderReportQuery } from "../../slices/exactScience/exactScience.service";

const StateReportCols = [
  {
    field: "state",
    headerName: "State",
    sortable: true,
    minWidth: 150,
  },
  {
    field: "count",
    headerName: "Outstanding Order Count",
    sortable: true,
    minWidth: 150,
  },
];

export const StateOutstandingReport: React.FC = () => {
  const { data: stateReport, isFetching: stateReportLoading } =
    useFetchOrderReportQuery("state");
  return (
    <DataGridWrapper
      pageSize={10}
      pageNumber={0}
      sortFieldName="count"
      sortDirection="desc"
      loading={stateReportLoading}
      columns={StateReportCols}
      rows={stateReport || []}
    />
  );
};
