import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../app/store";

export type UserState = {
  accessToken?: string;
};

const initialState: UserState = {
  accessToken: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
  },
});

export const selectToken = (state: RootState) => state.user.accessToken;

export const { login } = userSlice.actions;
export default userSlice.reducer;
