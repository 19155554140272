import { useAuth0 } from "@auth0/auth0-react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";

import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";

export const NoAccessDisplay: React.FC = () => {
  const auth0 = useAuth0();
  if (auth0.isLoading) {
    return <LoadingIndicator isLoading={auth0.isLoading} />;
  }
  return (
    <Card>
      <CardHeader title="You do not have access to this feature"></CardHeader>
      <CardContent>
        <Typography component="p">
          Contact your team administrator or lead to gain access
        </Typography>
      </CardContent>
    </Card>
  );
};
