import { ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { FormikProps } from "formik";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { IFormData } from "../../common/resources/bulkOrders.types";
import { setSnackBar } from "../../slices/bulkOrders/bulkOrders";

const ProgramNavigation: React.FC<{
  handleStepChange: (step: number) => void;
  activeStep: number;
  formik: FormikProps<IFormData>;
  verify: boolean;
  cancelCreation: () => void;
}> = ({ handleStepChange, activeStep, formik, verify, cancelCreation }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const checkStep = () => {
    if (activeStep === 2) {
      if (Object.keys(formik.errors).length === 0) {
        formik.handleSubmit();
      } else {
        dispatch(
          setSnackBar({
            open: true,
            severity: "error",
            title: "ui.snackbar.error",
            subTitle: "bulkOrders.programCreation.formikError",
          })
        );
      }
    }
    if (activeStep < 2) {
      handleStepChange(activeStep + 1);
    }
  };

  return (
    <Grid
      container
      sx={{ justifyContent: "flex-end", display: "flex", margin: 2 }}
    >
      <Box sx={{ marginRight: 5 }}>
        {activeStep === 0 ? (
          <Button
            sx={{ width: 150, height: 45 }}
            variant="text"
            onClick={cancelCreation}
          >
            {formatMessage({ id: "ui.button.cancel" })}
          </Button>
        ) : null}
        {activeStep > 0 ? (
          <Button
            sx={{ width: 150, height: 45 }}
            variant="text"
            onClick={() => {
              if (activeStep > 0) {
                handleStepChange(activeStep - 1);
              }
            }}
          >
            {formatMessage({ id: "bulkOrders.programCreation.back" })}
          </Button>
        ) : null}
        <Button
          sx={{ width: 150, height: 45 }}
          disabled={!formik.isValid || (activeStep === 2 && !verify)}
          variant="contained"
          endIcon={<ArrowForwardIos />}
          onClick={() => {
            checkStep();
          }}
        >
          {activeStep === 2
            ? `${formatMessage({
                id: "bulkOrders.programCreation.createProgram",
              })}`
            : `${formatMessage({ id: "bulkOrders.programCreation.next" })}`}
        </Button>
      </Box>
    </Grid>
  );
};

export default ProgramNavigation;
